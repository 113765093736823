import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import { socket } from 'service/socket';
import useConversations from 'hooks/useDPXConversations';
import { Edit, ExternalLink } from 'react-feather';
import { useHistory } from 'react-router';
import { CapitalizeNames } from 'utils/capitalize';
import { useTranslation } from 'react-i18next';
import { Rating } from '@material-ui/lab';
import i18next from 'utils/i18next';
import moment from 'moment';
import ReminderModal from 'components/ReminderModal';
// import { isAddonAvailableMultiStore } from 'utils/availableAddOn';
import Menu from 'components/CustomizedMenu';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  IconButton,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';

import {
  Clipboard as TestIcon,
  Search,
  BarChart as BarChartIcon,
  Send as WhatsAppIcon,
  Database as DatabaseIcon,
  Smile as RoleIcon,
  Coffee as ControlIcon,
  PieChart as PieChartIcon,
  Mail as TemplateIcon,
  Airplay as SourceIcon,
  Aperture as MakeIcon,
  Home as StoreIcon,
  Truck as VehicleIcon,
  AlertCircle as StatusIcon,
  User as UserIcon,
  Calendar as CalendarIcon,
  Settings,
  UploadCloud as LoadIcon,
  Clock as TaskIcon,
  Mic as RecordingIcon,
  FileText as ClosureIcon,
  Cloud as TemperaturesIcon
} from 'react-feather';
import Logo from 'components/MinLogo';
import useAuth from 'hooks/useAuth';
import NavItem from './NavItem';
import Repeater from './Repeater';
import useReminder from 'hooks/useReminder';
import {
  isAdmin,
  isRockstar,
  isSuper,
  isUser,
  isDigitalMkt,
  isGeneralManager,
  isSalesManager,
  isRH,
  isAdminBDC,
  isUserBDC,
  isCarokouUser
} from 'utils/AuthRoles';
import { Message } from '@material-ui/icons';

const roles = {
  generalManager: '641a0aceb9338d2e06161327',
  rockstar: '6144d4548fb0f00000000000',
  superAdmin: '641a0aeab9338d2e0616132c',
  admin: '6459145dff30096ffd4f0118',
  adminBDC: '641a0afdb9338d2e06161340',
  user: '641f3ca3e9f658304da5e7b9',
  marketing: '641a0b0eb9338d2e0616134a',
  receptionist: '641a0bbdb9338d2e06161389',
  digitalMarketing: '641a0c4eb9338d2e061613af',
  userBDC: '641a0c6fb9338d2e061613ca',
  userService: '641a0c7cb9338d2e061613d3',
  adminService: '641a0c90b9338d2e061613e8',
  salesManager: '641a0ca2b9338d2e061613ed',
  rh: '641a0cc7b9338d2e06161416',
  carokou: '641a0d38b9338d2e06161442',
  receptionistCall: '641a0cd8b9338d2e0616141b',
  carokouUser: '641f3ca3e9f658304da5e7b9'
};

function renderNavItems({ items, pathname, depth = 0, user }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, user }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, user }) {
  const key = item.title + depth + Math.random();

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          user
        })}
      </NavItem>
    );
  } else {
    switch (item.href) {
      case '/conversations':
        if (user && user.role) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      case '/management/recordings':
        if (user && user.role) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      case '/management/lists':
        if (user && user.stores && user.role) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      case '/management/showroom-leads':
        if (
          user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isSuper(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            ((isUser(user.role._id) || isAdmin(user.role._id)) &&
              user.leadType &&
              (user.leadType === 'all' || user.leadType === 'showroom')))
        ) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      case '/management/leads':
        if (
          user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isSuper(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            ((isUser(user.role._id) || isAdmin(user.role._id)) &&
              user.leadType &&
              (user.leadType === 'all' || user.leadType === 'lead')))
        ) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      case '/management/campaigns':
        if (user && user.stores && user.role) {
          acc.push(
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
        break;
      default:
        acc.push(
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={item.title}
          />
        );
        break;
    }
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  iconMenu: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark
    },
    width: 30,
    height: 30,
    padding: 7.5
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.grey[500],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  select: {
    paddingTop: '1em',
    paddingBottom: '0.5em'
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  primaryColor: {
    color: theme.palette.primary.main
  }
}));

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getConversation } = useConversations();
  const { t } = useTranslation();
  const history = useHistory();
  const { getRemindersByUser, reminders } = useReminder();
  const loc = useLocation();
  const [intervalC, setIntervalC] = useState();
  const [currentReminder, setCurrentReminder] = useState({});
  const [open, setOpen] = useState(false);

  const handleDisableSnack = key => closeSnackbar(key);

  useEffect(() => {
    const keys = [];
    for (var k in socket && socket._callbacks) keys.push(k);
    keys.map(item => {
      if (socket && socket._callbacks[item].length > 1)
        socket._callbacks[item].push(socket && socket._callbacks[item][0]);
      return false;
    });
  }, [user]);

  useEffect(() => {
    if (
      socket &&
      user &&
      user.role &&
      (isUser(user.role._id) || isUserBDC(user.role._id))
    ) {
      socket.emit(
        'leaveNotificationsRoom',
        `conversations-alert-user-${user._id}`
      );
      socket.emit(
        'joinNotificationsRoom',
        `conversations-alert-user-${user._id}`
      );
    }

    if (socket && user && user.stores && user.role && isAdmin(user.role._id)) {
      user.stores.map(store => {
        socket.emit(
          'leaveNotificationsRoom',
          `notifications-${store.twilioNumber}-${store.fbPage}`
        );
        socket.emit(
          'joinNotificationsRoom',
          `notifications-${store.twilioNumber}-${store.fbPage}`
        );

        return false;
      });
    }

    if (
      socket &&
      user &&
      user.stores &&
      user.role &&
      isAdminBDC(user.role._id)
    ) {
      user.stores.map(store => {
        socket.emit(
          'leaveNotificationsRoom',
          `notifications-${store.BDCNumber}`
        );
        socket.emit(
          'joinNotificationsRoom',
          `notifications-${store.BDCNumber}`
        );
        socket.emit('leaveRoom', `notification-conversations-${store._id}-bdc`);
        socket.emit(
          'connectRoom',
          `notification-conversations-${store._id}-bdc`
        );

        return false;
      });
    }

    if (user && user.stores && user.role && isAdmin(user.role._id)) {
      user.stores.map(store => {
        if (
          socket &&
          user.carType &&
          (user.carType === 'nuevo' || user.carType === 'ambos')
        ) {
          socket.emit(
            'leaveRoom',
            `notification-conversations-${store._id}-nuevos`
          );
          socket.emit(
            'connectRoom',
            `notification-conversations-${store._id}-nuevos`
          );
          socket.emit('leaveRoom', `${store._id}-room-nuevos`);
          socket.emit('connectRoom', `${store._id}-room-nuevos`);
        }

        if (
          socket &&
          user?.carType &&
          (user.carType === 'seminuevo' || user.carType === 'ambos')
        ) {
          socket.emit(
            'leaveRoom',
            `notification-conversations-${store._id}-seminuevos`
          );
          socket.emit(
            'connectRoom',
            `notification-conversations-${store._id}-seminuevos`
          );
          socket.emit('leaveRoom', `${store._id}-room-seminuevos`);
          socket.emit('connectRoom', `${store._id}-room-seminuevos`);
        }

        return false;
      });
    }

    if (socket && user && user.role && isRockstar(user.role._id)) {
      socket.emit('leaveRoom', `rockstar-isRead-room`);
      socket.emit('connectRoom', `rockstar-isRead-room`);
    }

    if (socket && user && user.role && isSuper(user.role._id)) {
      socket.emit('leaveRoom', `${user.group._id}-room`);
      socket.emit('connectRoom', `${user.group._id}-room`);
    }

    if (user && user._id && isUser(user.role._id)) {
      getRemindersByUser(user._id);
    }
    //eslint-disable-next-line
  }, [user]);

  const myTimer = () => {
    if (!reminders) return false;
    reminders.map((reminder, index) => {
      if (
        moment(new Date()).isAfter(reminder.date) &&
        user &&
        reminder.user &&
        reminder.user._id === user._id &&
        reminder.lead &&
        reminder.lead.name
      ) {
        enqueueSnackbar(
          t('SnackBar.ReminderTask') +
            ' ' +
            CapitalizeNames(reminder.lead.name),
          {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'warning',
            persist: true,
            preventDuplicate: true,
            key: reminder._id,
            action: (
              <IconButton
                onClick={() => {
                  setCurrentReminder(reminder);
                  setOpen(true);
                }}
              >
                <ExternalLink />
              </IconButton>
            )
          }
        );
      }
      return false;
    });
  };

  useEffect(() => {
    return () => {
      if (socket) socket._callbacks = [];
    };
  }, []);

  useEffect(() => {
    if (reminders) {
      clearInterval(intervalC);
      setIntervalC(setInterval(myTimer, 5000));
    }
    //eslint-disable-next-line
  }, [reminders]);

  useEffect(() => {
    if (!socket) return;
    socket.on('notificationsAlert', data => {
      enqueueSnackbar(
        t('SnackBar.IncomingMessage') + ' ' + CapitalizeNames(data.channel),
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          variant: data.channel === 'whatsapp' ? 'success' : 'info',
          action: (
            <IconButton
              onClick={() => {
                getConversation(data._id);
                history.push('/conversations');
              }}
            >
              <ExternalLink />
            </IconButton>
          )
        }
      );
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let language;
    if (localStorage.getItem('i18nextLng')) {
      language = localStorage.getItem('i18nextLng');
    } else {
      language = 'en';
    }
    i18next.changeLanguage(language);
  }, []);

  const sectionsUserCarokou = [
    {
      subheader: t('Navbar.Dashboard'),
      items: [
        {
          title: t('Navbar.Principal'),
          icon: PieChartIcon,
          href: '/reports/dashboard'
        },
        {
          title: t('Navbar.Appointments'),
          icon: PieChartIcon,
          href: '/reports/appointments'
        },
        {
          title: t('Navbar.Visits'),
          icon: PieChartIcon,
          href: '/reports/visits'
        }
      ]
    },
    {
      subheader: t('Navbar.Main'),
      items: [
        {
          title: t('Navbar.Leads'),
          icon: DatabaseIcon,
          href: '/management/leads'
        },
        {
          title: t('Navbar.LeadsBusiness'),
          icon: DatabaseIcon,
          href: '/management/leadBusiness'
        },
        {
          title: t('Navbar.Conversations'),
          icon: Message,
          href: '/conversations'
        },
        {
          title: t('Navbar.CalendarTasks'),
          icon: TaskIcon,
          href: '/tasks'
        },
        {
          title: t('Navbar.Appointments'),
          icon: CalendarIcon,
          href: '/calendar'
        }
      ]
    },
    {
      subheader: t('Navbar.ChartsH'),
      href: '/extra/highcharts',
      icon: BarChartIcon,
      items: [
        {
          title: t('Navbar.StatusReport'),
          icon: StatusIcon,
          href: '/extra/highcharts/status-report'
        },
        {
          title: t('Navbar.ModelsByAgents'),
          icon: TemperaturesIcon,
          href: '/extra/highcharts/agentsTable'
        }
      ]
    }
  ];

  const sectionsRockstarX = [
    {
      subheader: t('Navbar.Dashboard'),
      items: [
        {
          title: t('Navbar.Principal'),
          icon: PieChartIcon,
          href: '/reports/dashboard'
        },
        {
          title: t('Navbar.Appointments'),
          icon: PieChartIcon,
          href: '/reports/appointments'
        },
        {
          title: t('Navbar.Visits'),
          icon: PieChartIcon,
          href: '/reports/visits'
        }
      ]
    },
    {
      subheader: t('Navbar.Main'),
      items: [
        {
          title: t('Navbar.Leads'),
          icon: DatabaseIcon,
          href: '/management/leads'
        },
        {
          title: t('Navbar.LeadsBusiness'),
          icon: DatabaseIcon,
          href: '/management/leadBusiness'
        },
        {
          title: t('Navbar.Conversations'),
          icon: Message,
          href: '/conversations'
        },
        {
          title: t('Navbar.Recordings'),
          icon: RecordingIcon,
          href: '/management/recordings'
        },
        {
          title: t('Navbar.Closures'),
          href: '/management/closures',
          icon: ClosureIcon
        },
        {
          title: t('Navbar.Quotations'),
          href: '/management/quotations',
          icon: ClosureIcon
        },
        {
          title: t('Navbar.CalendarTasks'),
          icon: TaskIcon,
          href: '/tasks'
        },
        {
          title: t('Navbar.Tasks'),
          icon: TaskIcon,
          href: '/management/tasks'
        },
        {
          title: t('Navbar.Appointments'),
          icon: CalendarIcon,
          href: '/calendar'
        }
      ]
    },
    {
      subheader: t('Navbar.ChartsH'),
      href: '/extra/highcharts',
      icon: BarChartIcon,
      items: [
        {
          title: t('Navbar.StatusReport'),
          icon: StatusIcon,
          href: '/extra/highcharts/status-report'
        },
        {
          title: t('Navbar.ModelsByAgents'),
          icon: TemperaturesIcon,
          href: '/extra/highcharts/agentsTable'
        }
      ]
    },
    {
      subheader: t('Navbar.Control'),
      icon: ControlIcon,
      href:
        loc.pathname === '/management/leads' ||
        loc.pathname === '/management/showroom-leads'
          ? '/none'
          : '/management',

      items: [
        {
          title: t('Navbar.Roles'),
          href: '/management/roles',
          icon: RoleIcon
        },
        {
          title: t('Navbar.Wsp'),
          href: '/management/templates-wsp',
          icon: WhatsAppIcon
        },
        {
          title: t('Navbar.Makes'),
          href: '/management/makes',
          icon: MakeIcon
        },
        {
          title: t('Navbar.Stores'),
          href: '/management/stores',
          icon: StoreIcon
        },
        {
          title: t('Navbar.Sources'),
          href: '/management/sources',
          icon: SourceIcon
        },
        {
          title: t('Navbar.Vehicles'),
          href: '/management/vehicles',
          icon: VehicleIcon
        },
        {
          title: t('Navbar.Status'),
          href: '/management/status',
          icon: StatusIcon
        },
        {
          title: t('Navbar.Users'),
          href: '/management/users',
          icon: UserIcon
        },
        {
          title: t('Navbar.LeadFinder'),
          href: '/leadFinder',
          icon: Search
        },
        {
          title: t('Navbar.LoadLeads'),
          href: '/management/loadLeads',
          icon: LoadIcon
        },
        {
          title: t('Navbar.Recordings'),
          href: '/management/storesRecordings',
          icon: RecordingIcon
        },
        {
          title: t('RecordingTests.RecordingTests'),
          href: '/management/recording-tests',
          icon: TestIcon
        },
        {
          title: t('Navbar.AdCampaigns'),
          href: '/management/adCampaigns',
          icon: TemplateIcon
        },
        {
          title: t('Navbar.Zapier'),
          href: '/management/configs',
          icon: Settings
        }
      ]
    }
  ];

  const sectionsAdmin = [
    {
      subheader: t('Navbar.Dashboard'),
      items: [
        {
          title: t('Navbar.Principal'),
          icon: PieChartIcon,
          href: '/reports/dashboard'
        },
        {
          title: t('Navbar.Appointments'),
          icon: PieChartIcon,
          href: '/reports/appointments'
        },
        {
          title: t('Navbar.Visits'),
          icon: PieChartIcon,
          href: '/reports/visits'
        }
      ]
    },
    {
      subheader: t('Navbar.Main'),
      items: [
        {
          title: t('Navbar.Leads'),
          icon: DatabaseIcon,
          href: '/management/leads'
        },
        {
          title: t('Navbar.LeadsBusiness'),
          icon: DatabaseIcon,
          href: '/management/leadBusiness'
        },
        {
          title: t('Navbar.Conversations'),
          icon: Message,
          href: '/conversations'
        },
        {
          title: t('Navbar.Recordings'),
          icon: RecordingIcon,
          href: '/management/recordings'
        },
        {
          title: t('Navbar.Closures'),
          href: '/management/closures',
          icon: ClosureIcon
        },
        {
          title: t('Navbar.Quotations'),
          href: '/management/quotations',
          icon: ClosureIcon
        },
        {
          title: t('Navbar.CalendarTasks'),
          icon: TaskIcon,
          href: '/tasks'
        },
        {
          title: t('Navbar.Tasks'),
          icon: TaskIcon,
          href: '/management/tasks'
        },
        {
          title: t('Navbar.Appointments'),
          icon: CalendarIcon,
          href: '/calendar'
        }
      ]
    },
    {
      subheader: t('Navbar.ChartsH'),
      href: '/extra/highcharts',
      icon: BarChartIcon,
      items: [
        {
          title: t('Navbar.StatusReport'),
          icon: StatusIcon,
          href: '/extra/highcharts/status-report'
        },
        {
          title: t('Navbar.ModelsByAgents'),
          icon: TemperaturesIcon,
          href: '/extra/highcharts/agentsTable'
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <ReminderModal
        open={open}
        setOpen={setOpen}
        reminder={currentReminder}
        disableSnack={handleDisableSnack}
      />
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2} style={{ marginTop: 15 }}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user && user.image ? `${user.image}` : ''}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user && user.name ? CapitalizeNames(user.name) : 'None'}
            </Link>
            {user &&
              user.averageRating !== undefined &&
              user.role &&
              !isRockstar(user.role._id) &&
              !isSuper(user.role._id) &&
              !isRH(user.role._id) && (
                <Box>
                  <Rating
                    value={user.averageRating}
                    precision={0.5}
                    readOnly
                    icon={
                      <StarIcon
                        style={{ width: '20px', height: '20px' }}
                      ></StarIcon>
                    }
                    emptyIcon={
                      <StarOutlineIcon
                        style={{ width: '20px', height: '20px' }}
                      ></StarOutlineIcon>
                    }
                  />
                </Box>
              )}
            {user && user._id && user._id === '5d7a514b5d2c12c7449be042' && (
              <Box>
                <Rating
                  value={5}
                  precision={0.5}
                  readOnly
                  icon={
                    <StarIcon
                      style={{ width: '20px', height: '20px' }}
                    ></StarIcon>
                  }
                  emptyIcon={
                    <StarOutlineIcon
                      style={{ width: '20px', height: '20px' }}
                    ></StarOutlineIcon>
                  }
                />
              </Box>
            )}
            <Typography variant="body2" color="textSecondary">
              {/* {t('Account.YourTier')}: */}
              <span className={classes.primaryColor} variant="body2">
                {user && user.role ? ' ' + CapitalizeNames(user.role.name) : ''}
              </span>
            </Typography>
            {user &&
              user.role &&
              !isRockstar(user.role._id) &&
              !isSuper(user.role._id) &&
              !isRH(user.role._id) && (
                <Typography color="textSecondary" variant="body2">
                  {t('Account.CarType')}:{' '}
                  <span className={classes.primaryColor} variant="body2">
                    {user && user.carType
                      ? ' ' + t(`LeadsBDC.${user.carType}`)
                      : ''}
                  </span>
                </Typography>
              )}
            {user &&
            user.role &&
            (isRockstar(user.role._id) ||
              isSuper(user.role._id) ||
              isRH(user.role._id)) ? (
              <Typography
                pt={4}
                variant="body2"
                style={{ textTransform: 'capitalize' }}
              >
                Global
              </Typography>
            ) : user && user.role && isDigitalMkt(user.role._id) ? (
              <Typography
                pt={4}
                variant="body2"
                style={{ textTransform: 'capitalize' }}
              >
                {CapitalizeNames(user.role.name)}
              </Typography>
            ) : user && user.stores && user.stores.length > 1 ? (
              <Typography
                pt={4}
                variant="body2"
                style={{ textTransform: 'capitalize' }}
              >
                {t('Titles.MultiStores')}
              </Typography>
            ) : (
              user &&
              user.stores &&
              user.stores[0] && (
                <Typography
                  pt={4}
                  variant="body2"
                  style={{ textTransform: 'capitalize' }}
                >
                  {CapitalizeNames(user.stores[0].name)}
                </Typography>
              )
            )}
          </Box>
          {user &&
            user.birthday &&
            moment(user.birthday).format('DD MMMM') ===
              moment().format('DD MMMM') && (
              <Box ml={2} mt={4}>
                <center>
                  <Typography variant="body2" color="textSecondary">
                    {t('Users.Birthday')}
                  </Typography>
                </center>
              </Box>
            )}
          {moment().format('DD MM') === '02 11' && (
            <Box ml={2} mt={4}>
              <center>
                †
                <br />
                ꧁༺ 🌺 💀 🌹 ༻꧂
                <br />
                _____
                <br />
                / ../ ....+ ....\
                <br />
                | ..| ....RIP ...|
                <br />
                | ..| ............. |<br />
                | ..| ..............|
                <br />
                \ .| ..|.. ./\/\//\ .|/
                <br />
                <br />
                <Typography variant="body2" color="textSecondary">
                  {t('Messages.DeadsDay')}
                </Typography>
              </center>
            </Box>
          )}

          {user && user._id === '62b7494dd3bdaa4e6ddd2f67' && (
            <Box ml={2} mt={4}>
              <center>Te he dicho que me gustas mucho?</center>
            </Box>
          )}

          {moment().format('DD MM') === '31 10' && (
            <Box ml={2} mt={4}>
              <center>
                {/*eslint-disable-next-line*/}
                <sub>🎃</sub>
                {/*eslint-disable-next-line*/}
                <sup>🎃</sup>
                {/*eslint-disable-next-line*/}
                <sub>🎃</sub>
                <br />
                ꧁༒ 👻 ༒꧂
                <br />
                ~ 🍬 🍭 🍫 ~
                <br />
                <br />
                {/*eslint-disable-next-line*/}
                <Typography variant="body2" color="textSecondary">
                  🐈‍⬛ {t('Messages.Halloween')}
                </Typography>
              </center>
            </Box>
          )}
          {moment('2021-10-31T00:00:00-00:00').diff(moment(), 'days') > 0 && (
            <Box ml={2} mt={4}>
              <center>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  style={{
                    color:
                      user &&
                      user.birthday &&
                      moment(user.birthday).format('DD MMMM') ===
                        moment().format('DD MMMM')
                        ? 'black'
                        : moment().format('DD MM') === '29 10'
                        ? 'white'
                        : 'white'
                  }}
                >
                  {localStorage.getItem('language') &&
                  localStorage.getItem('language') === 'es'
                    ? `Faltan ${moment('2021-10-31T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      ) + 1} días para halloween 🎃`
                    : `${moment('2021-10-31T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      ) + 1} days until halloween 🎃` &&
                      localStorage.getItem('language') === 'en'
                    ? `${moment('2021-10-31T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      ) + 1} days until halloween 🎃`
                    : ''}
                </Typography>
              </center>
            </Box>
          )}

          {moment('2021-12-26T00:00:00-00:00').diff(moment(), 'days') > 0 && (
            <Box ml={2} mt={4}>
              <center>
                <Typography variant="h6" color="textPrimary">
                  {localStorage.getItem('language') &&
                  localStorage.getItem('language') === 'es'
                    ? `Faltan ${moment('2021-12-26T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      )} días para navidad 🎅🏼`
                    : `${moment('2021-12-26T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      )} days until christmas 🎅🏼` &&
                      localStorage.getItem('language') === 'en'
                    ? `${moment('2021-12-26T00:00:00-00:00').diff(
                        moment(),
                        'days'
                      )} days until christmas 🎅🏼`
                    : ''}
                </Typography>
              </center>
            </Box>
          )}

          {user &&
            user.role &&
            (isRockstar(user.role._id) || isSuper(user.role._id)) && (
              <Box display={'flex'} flexDirection="row-reverse">
                <IconButton
                  className={classes.iconMenu}
                  onClick={() => history.push('/custom-menu')}
                >
                  <Edit style={{ fontSize: 10 }} />
                </IconButton>
              </Box>
            )}
        </Box>
        <Divider />
        {user && user.role && isRockstar(user.role._id) && (
          <div style={{ position: 'relative' }}>
            <Repeater
              renderNavItems={renderNavItems}
              data={sectionsRockstarX}
              user={user}
              location={location}
              role={roles.rockstar}
            />
          </div>
        )}
        {user &&
          user.role &&
          !isRockstar(user.role._id) &&
          isAdmin(user.role._id) && (
            <div style={{ position: 'relative' }}>
              <Repeater
                renderNavItems={renderNavItems}
                data={sectionsAdmin}
                user={user}
                location={location}
                role={roles.admin}
              />
            </div>
          )}
        {user &&
          user.role &&
          !isRockstar(user.role._id) &&
          !isAdmin(user.role._id) &&
          isCarokouUser(user.role._id) && (
            <div style={{ position: 'relative' }}>
              <Repeater
                renderNavItems={renderNavItems}
                data={sectionsUserCarokou}
                user={user}
                location={location}
                role={roles.carokouUser}
              />
            </div>
          )}
        {user &&
          user.role &&
          !isRockstar(user.role._id) &&
          !isAdmin(user.role._id) &&
          !isCarokouUser(user.role._id) &&
          user.menu && <Menu menu={JSON.parse(user.menu)} />}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
