import React from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  Typography,
  useTheme,
  makeStyles,
  Box,
  IconButton
} from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    float: 'right'
  }
}));

const ColumnChart = ({
  categories,
  customColors,
  customName,
  data,
  title,
  href,
  distributed,
  disableLegend,
  stacked,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  let colors = [theme.palette.primary.main];

  if (customColors) {
    colors = customColors;
  }
  let name = 'Leads'
  if(customName){
    name = customName;
  }
  let Staked = (stacked)?true:false;
  let Distributed= (distributed)?true:false;
  let DisableLegend = (disableLegend)?false:true;

  const chart = {
    options: {
      chart: {
        background: theme.palette.background.paper,
        stacked:Staked,
        toolbar: {
          show: false
        },
        zoom: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          return value;
        },
        textAnchor: 'middle',
        style: {
          fontSize: '17px',
          fontFamily: 'Helvetica, sans-serif',
          fontWeight: '700',
          colors: [theme.palette.text.secondary]
        },
        offsetX: 0,
        offsetY: -30
      },
      plotOptions: {
        bar: {
          distributed:Distributed,
          dataLabels: {
            position: 'top'
          }
        }
      },
      grid: {
        borderColor: theme.palette.divider,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          right: 50,
          left: 50
        }, 
      },
      colors: colors,
      legend: {
        show: DisableLegend,
        position: 'top',
        fontSize: '16px',
        horizontalAlign: 'right',
        labels: {
          colors: theme.palette.text.secondary
        }
      },
      markers: {
        size: 4,
        strokeColors: ['#1f87e6', '#27c6db'],
        strokeWidth: 0,
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      stroke: {
        width: 1
      },
      theme: {
        mode: theme.palette.type
      },
      tooltip: {
        theme: theme.palette.type,
        style: {
          fontSize: '18px'
        }
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider
        },
        axisTicks: {
          show: false,
          color: theme.palette.divider
        },
        categories: categories ? categories : [],
        labels: {
          style: {
            colors: theme.palette.text.primary,
            fontSize: '16px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
          },
        }
      },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: theme.palette.divider
          },
          axisTicks: {
            show: true,
            color: theme.palette.divider
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary
            }
          },
          seriesName: name
        }
      ]
    },
    series: [
      {
        name: name,
        data: data ? data : []
      }
    ]
  };

  return (
    <Card {...rest} style={{height: '100%' }}>
      <CardContent>
        {href && (
          <div className={classes.link}>
            <Box alignItems="center" alignSelf="center" display="flex">
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ marginRight: '1em' }}
              >
                {t('Charts.FullReport')}
              </Typography>{' '}
              <IconButton>
                <ExternalLink
                  onClick={() =>
                    history.push(href)
                  }
                />
              </IconButton>
            </Box>
          </div>
        )}

        <Typography
          variant="h4"
          color="textPrimary"
          style={{ fontSize: '25px' }}
        >
          {title}
        </Typography>
        <Chart type="bar" height="700" {...chart} />
      </CardContent>
    </Card>
  );
};

export default ColumnChart;
