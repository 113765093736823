import { 
  GET_WSPCAMPAIGNS, 
  CREATE_WSPCAMPAIGN, 
  GET_WSPCAMPAIGN, 
  DELETE_WSPCAMPAIGN, 
  UPDATE_WSPCAMPAIGN, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_FILTER_RESULTS
 } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_FILTER_RESULTS:
      return {
        ...state,
        filterLeadsResults: action.payload,
        loading: false,
        error: null
      }
    case GET_WSPCAMPAIGNS:
      return {
        ...state,
        wspCampaigns: action.payload,
        loading: false,
        error: null,
        count: action.count
      };
    case GET_WSPCAMPAIGN:
      return {
        ...state,
        wspCampaign: action.payload,
        loading: false,
        error: null

      };
    case CREATE_WSPCAMPAIGN:
      return {
        ...state,
        loading: false,
        error: null

      };
    case DELETE_WSPCAMPAIGN:
      state.wspCampaigns = state.wspCampaigns.filter(wspCampaign => wspCampaign._id.toString() !== action.payload.toString())
      return {
        ...state,
        wspCampaign: null,
        loading: false,
        error: null

      }
    case UPDATE_WSPCAMPAIGN:
      return {
        ...state,
        wspCampaign: action.payload,
        loading: false,
        error: null

      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        
      }
    case CLEAR_STATE:
      return {
        wspCampaign: {},
        wspCampaigns: [],
        loading: false,
        error: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
        }
      default:
        return state;
  }
};
