import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {
  isAdmin,
  isDigitalMkt,
  isGeneralManager,
  isMarketing,
  isRH,
  isRockstar,
  isSalesManager,
  isSuper,
  isUser
} from 'utils/AuthRoles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  autocomplete: {
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5
  }
}));

const Routing = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const { user } = useAuth();
  const filter = createFilterOptions();
  const history = useHistory();

  let routing = [
    { titleEn: 'Home', titleEs: 'Inicio', route: '/' },
    { titleEn: 'Tickets', titleEs: 'Tickets', route: '/management/tickets' },
    {
      titleEn: 'Notifications',
      titleEs: 'Notificaciones',
      route: '/management/notifications'
    },
    { titleEn: 'Account', titleEs: 'Cuenta', route: '/account' },
    { titleEn: 'Dashboard', titleEs: 'Tablero', route: '/reports/dashboard' },
    { titleEn: 'Create Menu', titleEs: 'Create Menú', route: '/custom-menu' }
  ];

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      ((isAdmin(user.role._id) ||
        isGeneralManager(user.role._id) ||
        isSalesManager(user.role._id) ||
        isUser(user.role._id)) &&
        user &&
        user.leadType &&
        (user.leadType === 'all' || user.leadType === 'lead')))
  ) {
    routing.push({
      titleEn: 'Digital Lead',
      titleEs: 'Leads Digitales',
      route: '/management/leads'
    });
    routing.push({
      titleEn: 'Business Lead',
      titleEs: 'Leads de empresas',
      route: '/management/leadBusiness'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      ((isAdmin(user.role._id) ||
        isGeneralManager(user.role._id) ||
        isSalesManager(user.role._id) ||
        isUser(user.role._id)) &&
        user &&
        user.leadType &&
        (user.leadType === 'all' || user.leadType === 'showroom')))
  ) {
    routing.push({
      titleEn: 'Showroom Lead',
      titleEs: 'Leads de Piso',
      route: '/management/showroom-leads'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isAdmin(user.role._id) ||
      isMarketing(user.role._id) ||
      isDigitalMkt(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isRH(user.role._id))
  ) {
    routing.push({
      titleEn: 'Appointments Dashboard',
      titleEs: 'Tablero Citas',
      route: '/reports/appointments'
    });
    routing.push({
      titleEn: 'Visits Dashboard',
      titleEs: 'Tablero Visitas',
      route: '/reports/visits'
    });
  }

  if (user && user.stores && user.role) {
    routing.push({
      titleEn: 'Conversations',
      titleEs: 'Conversations',
      route: '/conversations'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isAdmin(user.role._id) ||
      isMarketing(user.role._id) ||
      isDigitalMkt(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isRH(user.role._id))
  ) {
    routing.push({
      titleEn: 'Monthly Report',
      titleEs: 'Reporte Mensual',
      route: '/extra/highcharts/monthly'
    });
    routing.push({
      titleEn: 'Bureau Score',
      titleEs: 'Puntuación',
      route: '/extra/highcharts/score'
    });
    routing.push({
      titleEn: "Message's Report",
      titleEs: 'Reporte de Mensajes',
      route: '/extra/highcharts/messages'
    });
    routing.push({
      titleEn: 'Funnel Temperatures',
      titleEs: 'Funnel por Temperaturas',
      route: '/extra/highcharts/temperatures'
    });
    routing.push({
      titleEn: 'Funnel Temperatures (RSI)',
      titleEs: 'Funnel por Temperaturas (RSI)',
      route: '/extra/highcharts/funnel'
    });
    routing.push({
      titleEn: 'Daily Report',
      titleEs: 'Reporte Diario',
      route: '/extra/highcharts/daily'
    });
    routing.push({
      titleEn: 'Hours Report',
      titleEs: 'Reporte por Horas',
      route: '/extra/highcharts/hours'
    });
    routing.push({
      titleEn: 'Visits Report',
      titleEs: 'Reporte de Visitas',
      route: '/extra/highcharts/visits'
    });
    routing.push({
      titleEn: 'Status Funnel',
      titleEs: 'Funnel por Estatus',
      route: '/extra/highcharts/status-report'
    });
    routing.push({
      titleEn: 'Global Report',
      titleEs: 'Reporte Global',
      route: '/extra/highcharts/all'
    });
    routing.push({
      titleEn: 'Models Report',
      titleEs: 'Reporte por Modelo',
      route: '/extra/highcharts/models'
    });
    routing.push({
      titleEn: 'Pending Activities',
      titleEs: 'Actividades Pendientes',
      route: '/reports/pending-tasks'
    });
    routing.push({
      titleEn: 'Investment Dashboard',
      titleEs: 'Tablero Inversión',
      route: '/reports/investment'
    });
    routing.push({
      titleEn: 'Leads by Agent',
      titleEs: 'Prospectos por Agente',
      route: '/extra/highcharts/agentsTable'
    });
    routing.push({
      titleEn: 'Visits Report',
      titleEs: 'Reporte Visitas',
      route: '/reports/visits-leads'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isAdmin(user.role._id) ||
      isMarketing(user.role._id) ||
      isDigitalMkt(user.role._id) ||
      isUser(user.role._id))
  ) {
    routing.push({
      titleEn: 'Appointments Calendar',
      titleEs: 'Calendario de Citas',
      route: '/calendar'
    });
    routing.push({
      titleEn: 'Tasks Calendar',
      titleEs: 'Calendario de Tareas',
      route: '/tasks'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isAdmin(user.role._id) ||
      isMarketing(user.role._id) ||
      isDigitalMkt(user.role._id) ||
      isRH(user.role._id))
  ) {
    routing.push({
      titleEn: 'Models Comparative Report',
      titleEs: 'Reporte Comparativo de Modelos',
      route: '/extra/highcharts/multiple'
    });
    routing.push({
      titleEn: 'Digital Campaigns',
      titleEs: 'Campañas de Redes',
      route: '/management/adCampaigns'
    });
    routing.push({
      titleEn: 'Investment',
      titleEs: 'Inversiones',
      route: '/management/investments'
    });
    routing.push({
      titleEn: 'Templates',
      titleEs: 'Plantillas',
      route: '/management/templates'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isAdmin(user.role._id))
  ) {
    routing.push({
      titleEn: 'Recordings',
      titleEs: 'Grabaciones',
      route: '/management/recordings'
    });
    routing.push({
      titleEn: 'Monitoring Agents Report',
      titleEs: 'Reporte Monitoreo Agentes',
      route: '/management/monitoringAgents'
    });
    routing.push({
      titleEn: 'Load Leads',
      titleEs: 'Cargar Leads',
      route: '/management/loadLeads'
    });
    routing.push({
      titleEn: 'Temperatures',
      titleEs: 'Temperaturas',
      route: '/management/temperatures'
    });
    routing.push({
      titleEn: 'Tasks Logs',
      titleEs: 'Historial de Tareas',
      route: '/management/tasks'
    });
    routing.push({
      titleEn: 'Users',
      titleEs: 'Usuarios',
      route: '/management/users'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isDigitalMkt(user.role._id))
  ) {
    routing.push({
      titleEn: 'Vehicles',
      titleEs: 'Vehículos',
      route: '/management/vehicles'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id) ||
      isRH(user.role._id))
  ) {
    routing.push({
      titleEn: 'Sales Dashboard',
      titleEs: 'Tablero Ventas',
      route: '/reports/sales'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) ||
      isSuper(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id))
  ) {
    routing.push({
      titleEn: 'Closures',
      titleEs: 'Facturas',
      route: '/management/closures'
    });
    routing.push({
      titleEn: 'General Report',
      titleEs: 'Reporte General',
      route: '/extra/highcharts/storesStatus'
    });
    routing.push({
      titleEn: 'Alarms',
      titleEs: 'Alarmas',
      route: '/management/alarms'
    });
    routing.push({
      titleEn: "Stores's Recordings",
      titleEs: 'Grabaciones de Agencias',
      route: '/management/storesRecordings'
    });
    routing.push({
      titleEn: 'Makes',
      titleEs: 'Marcas',
      route: '/management/makes'
    });
    routing.push({
      titleEn: 'Stores',
      titleEs: 'Agencias',
      route: '/management/stores'
    });
    routing.push({
      titleEn: 'Status',
      titleEs: 'Estatus',
      route: '/management/status'
    });
    routing.push({
      titleEn: 'Sources',
      titleEs: 'Fuentes',
      route: '/management/sources'
    });
    routing.push({
      titleEn: 'Contacts',
      titleEs: 'Contactos',
      route: '/management/contacts'
    });
    routing.push({
      titleEn: 'Companies',
      titleEs: 'Compañías',
      route: '/management/companies'
    });
    routing.push({
      titleEn: 'Investment Analysis',
      titleEs: 'Análisis de Inversión',
      route: '/extra/highcharts/investments'
    });
    routing.push({
      titleEn: 'By Multiple Units',
      titleEs: 'Por Unidades Multiple',
      route: '/extra/highcharts/unit-comparative'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) || isDigitalMkt(user.role._id))
  ) {
    routing.push({
      titleEn: "Lead's Finder",
      titleEs: 'Buscador de Leads',
      route: '/leadFinder'
    });
  }

  if (user && user.role && isRockstar(user.role._id)) {
    routing.push({
      titleEn: 'Groups',
      titleEs: 'Grupos',
      route: '/management/groups'
    });
    routing.push({
      titleEn: 'Templates Wsp',
      titleEs: 'Plantillas Wsp',
      route: '/management/templates-wsp'
    });
    routing.push({
      titleEn: 'Token Zapier',
      titleEs: 'Token Zapier',
      route: '/management/configs'
    });
    routing.push({
      titleEn: 'Roles',
      titleEs: 'Roles',
      route: '/management/roles'
    });
    routing.push({
      titleEn: "Service's Packages",
      titleEs: 'Paquetes de Servicio',
      route: '/management/packages'
    });
    routing.push({
      titleEn: 'Token Zapier',
      titleEs: 'Token Zapier',
      route: '/management/configs'
    });
  }

  if (
    user &&
    user.role &&
    (isRockstar(user.role._id) || isSuper(user.role._id))
  ) {
    routing.push({
      titleEn: 'Recording Test',
      titleEs: 'Encuestas de Grabaciones',
      route: '/management/recording-tests'
    });
    routing.push({
      titleEn: 'Matrix',
      titleEs: 'Matrix',
      route: '/reports/matrix'
    });
    routing.push({
      titleEn: 'Matrix Investment',
      titleEs: 'Inversiones Matrix',
      route: '/reports/matrixInvestment'
    });
    routing.push({
      titleEn: 'Matrix Segmentation',
      titleEs: 'Segmentación Matrix',
      route: '/reports/matrixSegmentation'
    });
  }

  if (localStorage.getItem('language')) {
    if (localStorage.getItem('language') === 'en') {
      //eslint-disable-next-line
      routing.sort((a, b) => (a.titleEn > b.titleEn && 1) || -1);
    } else {
      //eslint-disable-next-line
      routing.sort((a, b) => (a.titleEs > b.titleEs && 1) || -1);
    }
  } else {
    //eslint-disable-next-line
    routing.sort((a, b) => (a.titleEn > b.titleEn && 1) || -1);
  }

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (newValue && newValue.route) {
          history.push(newValue.route);
          setValue('');
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params && params.inputValue === '') return [];
        return filtered;
      }}
      handleHomeEndKeys
      options={routing}
      getOptionLabel={option => {
        // Add "xxx" option created dynamically
        if (option && option.inputValue) return option.inputValue;

        // Regular option
        if (localStorage.getItem('language')) {
          if (localStorage.getItem('language') === 'en') {
            return option.titleEn;
          } else {
            return option.titleEs;
          }
        } else {
          return option.titleEn;
        }
      }}
      renderOption={option => {
        if (localStorage.getItem('language')) {
          if (localStorage.getItem('language') === 'en') {
            return option.titleEn;
          } else {
            return option.titleEs;
          }
        } else {
          return option.titleEn;
        }
      }}
      className={classes.autocomplete}
      freeSolo
      size="small"
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('FindSearch')}
          variant="outlined"
          className={classes.input}
        />
      )}
    />
  );
};

export default Routing;
