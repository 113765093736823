import React from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  makeStyles,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { ExternalLink } from 'react-feather';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    float: 'right'
  }
}));

const ColumnChartAP = ({
  categories,
  series,
  customColors,
  title,
  translate,
  type,
  dataType,
  totals,
  href,
  stacked,
  distributed,
  disableLegend,

  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  if (translate) {

    let seriesTranslated = [];
    let categoriesTranslated = [];
    if (type.includes('Serie')) {
      series.map(item =>
        seriesTranslated.push({
          ...item,
          name: t(`${type.split('-')[0]}.${item.name}`)
        })
      );
      series = seriesTranslated;
    } else if (type.includes('Categories')) {
      categories.map(item =>
        categoriesTranslated.push(t(`${type.split('-')[0]}.${item}`))
      );
      categories = categoriesTranslated;
    }else if (type.includes('Days')) {
      categories.map(item =>
        categoriesTranslated.push(t(`${type.split('-')[0]}.${item}`))
      );
      categories = categoriesTranslated;
    }
  }

  let colors = [theme.palette.primary.main];

  if (customColors) {
    colors = customColors;
  }
  let Staked = (stacked)?true:false;
  let Distributed= (distributed)?true:false;
  let DisableLegend = (disableLegend)?false:true;
  const chart = {
    options: {
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      chart: {
        background: theme.palette.background.paper,
        stacked:Staked,
        toolbar: {
          show: false
        },
        zoom: false
      },
      title: {
        text: ''
      },
      colors: colors,
      plotOptions: {
        bar: {
          distributed:Distributed,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          switch (dataType) {
            case 'money':
              return numeral(value).format(`$0,0`);
            default:
              return value;
          }
        },
        textAnchor: 'middle',
        style: {
          fontSize: '15px',
          fontFamily: 'Helvetica, sans-serif',
          fontWeight: '700',
          colors: [theme.palette.text.secondary]
        },
        offsetY: -30
      },
      grid: {
        borderColor: theme.palette.divider,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          right: 50,
          left: 50
        }, 
      },
      // responsive: [
      //   {
      //     breakpoint: 2800,
      //     options: {
      //       xaxis: {
      //         axisBorder: {
      //           color: theme.palette.divider
      //         },
      //         axisTicks: {
      //           show: false,
      //           color: theme.palette.divider
      //         },
      //         categories: categories,
      //         labels: {
      //           style: {
      //             colors: theme.palette.text.primary,
      //             fontSize: '14px',
      //         },
      //         }
      //       },
      //     }
      //   },
      //   {
      //     breakpoint: 2000,
      //     options: {
      //       xaxis: {
      //         axisBorder: {
      //           color: theme.palette.divider
      //         },
      //         axisTicks: {
      //           show: false,
      //           color: theme.palette.divider
      //         },
      //         categories: categories,
      //         labels: {
      //           style: {
      //             colors: theme.palette.text.primary,
      //             // fontSize: '10px',
      //             fontSize: '14px',
      //         },
      //         }
      //       },
      //     }
      //   },
      //   {
      //     breakpoint: 1800,
      //     options: {
      //       xaxis: {
      //         axisBorder: {
      //           color: theme.palette.divider
      //         },
      //         axisTicks: {
      //           show: false,
      //           color: theme.palette.divider
      //         },
      //         categories: categories,
      //         labels: {
      //           style: {
      //             colors: theme.palette.text.primary,
      //             // fontSize: '8px',
      //             fontSize: '14px',
      //         },
      //         }
      //       },
      //     }
      //   }
      // ],
      legend: {
        show: DisableLegend,
        position: 'top',
        fontSize: '20px',
        horizontalAlign: 'center',
        offsetY: 5,
        labels: {
          colors: theme.palette.custom.secondary
        },
        floating: false
      },
      markers: {
        size: 15,
        strokeColors: ['#1f87e6', '#27c6db'],
        strokeWidth: 0,
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      stroke: {
        width: 1
      },
      theme: {
        mode: theme.palette.type
      },
      tooltip: {
        y: {
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            switch (dataType) {
              case 'money':
                if (totals && totals.length > 0) {
                  return (
                    numeral(value).format(`$0,0.00`) +
                    ' - ' +
                    totals[dataPointIndex] +
                    ' ' +
                    t('Charts.ChartSold')
                  );
                } else {
                  return numeral(value).format(`$0,0.00`);
                }
              default:
                return value;
            }
          }
        },
        theme: theme.palette.type,
        style: {
          fontSize: '16px',
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary
          },
          formatter: function(val, index) {
            return (val.toFixed(0)) / 1000 + 'k';
          }
        },
        axisBorder: {
          show: true,
          color: theme.palette.divider
        },
        axisTicks: {
          show: true,
          color: theme.palette.divider
        },
        forceNiceScale: true,
        decimalsInFloat: 0,
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          show: false,
          color: theme.palette.divider
        },
        categories: categories,
        labels: {
          style: {
            colors: theme.palette.text.primary,
            fontSize: '16px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
          },
        }
      },
      
    },
    series: series
  };

  return (
    <Card {...rest} style={{height: '100%' }}>
      <CardContent>
        {href && (
          <div className={classes.link}>
            <Box alignItems="center" alignSelf="center" display="flex">
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ marginRight: '1em' }}
              >
                {t('Charts.FullReport')}
              </Typography>{' '}
              <IconButton>
                <ExternalLink
                  onClick={() =>
                    history.push(href)
                  }
                />
              </IconButton>
            </Box>
          </div>
        )}
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ fontSize: '25px' }}
        >
          {title}
        </Typography>
        <Chart height="700" type="bar" {...chart} />
      </CardContent>
    </Card>
  );
};

export default ColumnChartAP;
