import CustomAlert from '../../components/CustomAlert';
import ERROR_SHOW_TIME from '../../variables/ERROR_SHOW_TIME';
import {
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE,
  GET_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ORDER:
    case UPDATE_ORDER:
    case CREATE_ORDER:
      return {
        ...state,
        order: action.payload,
        loading: false
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        count: action.count,
        loading: false,
        error: null
      };
    case CLEAR_STATE:
      return {
        ...state,
        order: {},
        orders: [],
        loading: false,
        error: null,
        count: null
      };
    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error',
        timer: ERROR_SHOW_TIME
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
