import {
  CREATE_COMMENT_BUSINESS,
  SET_ERROR,
  CLEAR_STATE,
  GET_COMMENTS_BUSINESS,
  GET_COMMENTS_BY_USER_BUSINESS,
  GET_COMMENTS_BY_STORE_BUSINESS,
  SET_LOADING,
  GET_COMMENT,
  UPDATE_COMMENT_BUSINESS,
  CLEAR_COMMENT_BUSINESS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case CLEAR_COMMENT_BUSINESS:
      return {
        ...state,
        comment: {},
        loading: false,
        error: null
      };
    case UPDATE_COMMENT_BUSINESS:
      let updatedComments = [];
      if (!action.payload.pending) {
        updatedComments = state.comments.map(x => x._id !== action.payload._id);
      } else {
        updatedComments = state.comments;
      }
      return {
        ...state,
        comments: updatedComments,
        loading: false
      };
    case GET_COMMENT:
      return {
        ...state,
        loading: false,
        comment: action.payload
      };
    case GET_COMMENTS_BUSINESS:
      return {
        ...state,
        comments: action.payload,
        count: action.count,
        loading: false,
        error: null
      };
    case GET_COMMENTS_BY_USER_BUSINESS:
    case GET_COMMENTS_BY_STORE_BUSINESS:
      return {
        ...state,
        comments: action.payload,
        loading: false,
        error: null
      };
    case CREATE_COMMENT_BUSINESS:
      return {
        ...state,
        comments: [action.payload, ...state.comments],
        loading: false
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        ...state,
        comments: [],
        comment: {},
        error: null,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
