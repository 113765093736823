import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'hooks/useSettings';
import { THEMES } from '../../../constants';
import i18next from 'utils/i18next';
import { useSnackbar } from 'notistack';
import DpxSwitch from 'components/DpxSwitch';
import { useTranslation } from 'react-i18next';
import { CapitalizeNames } from 'utils/capitalize';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const vipsThemes = ['ORNN', 'GWEN', 'GWEN_CAFE_CUTIE'];
const vipsThemesZack = ['GWEN'];

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [language, setLanguage] = useState(false);
  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  useEffect(() => {
    if (localStorage.getItem('language')) {
      i18next.changeLanguage(localStorage.getItem('language'));

      if (localStorage.getItem('language') === 'es') {
        setLanguage(true);
      } else {
        setLanguage(false);
      }
    } else {
      localStorage.setItem('language', 'en');
      i18next.changeLanguage(localStorage.getItem('language'));
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleClearCache = () => {
    let lenguaje = localStorage.getItem('language');
    let lan = localStorage.getItem('i18nextLng');
    let token = localStorage.getItem('token');
    let settings = localStorage.getItem('settings');

    localStorage.clear();

    localStorage.setItem('language', lenguaje);
    localStorage.setItem('i18nextLng', lan);
    localStorage.setItem('token', token);
    localStorage.setItem('settings', settings);

    enqueueSnackbar(t('SnackBar.Cache'), {
      variant: 'info'
    });
  };

  const handleSave = () => {
    if (language) {
      localStorage.setItem('language', 'es');
    }
    if (!language) {
      localStorage.setItem('language', 'en');
    }
    i18next.changeLanguage(localStorage.getItem('language'));
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('Settings.Settings')}>
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('Settings.Settings')}
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <DpxSwitch
                edge="start"
                checked={language}
                onChange={event => {
                  setLanguage(!language);
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                name="language"
                color="primary"
              />
            }
            label="EN/ES"
          />
        </Box>
        <Box mt={2} px={1}>
          <TextField
            fullWidth
            label={t('Settings.Theme')}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map(theme => {
              if (vipsThemesZack.includes(theme)) {
                if (
                  user &&
                  user._id &&
                  user._id === '603981f6a00ed01bc430bfba'
                ) {
                  return (
                    <option key={theme} value={theme}>
                      {' '}
                      {CapitalizeNames(theme)}{' '}
                    </option>
                  );
                }
              } else if (vipsThemes.includes(theme)) {
                if (
                  user &&
                  user._id &&
                  user._id === '5d7a514b5d2c12c7449be042'
                ) {
                  return (
                    <option key={theme} value={theme}>
                      {' '}
                      {CapitalizeNames(theme)}{' '}
                    </option>
                  );
                }
              } else if (theme === 'CASTANUELA') {
                if (
                  user &&
                  user._id &&
                  (user._id === '5d7a514b5d2c12c7449be042' ||
                    user._id === '604a3b6c8b1d510015b0dace')
                ) {
                  return (
                    <option key={theme} value={theme}>
                      {' '}
                      {CapitalizeNames(theme)}{' '}
                    </option>
                  );
                }
              } else {
                return (
                  <option key={theme} value={theme}>
                    {' '}
                    {CapitalizeNames(theme)}{' '}
                  </option>
                );
              }
              return false;
            })}
          </TextField>
        </Box>
        <Box mt={2} px={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClearCache}
          >
            {t('Settings.Cache')}
          </Button>
        </Box>
        <Box mt={2} px={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
          >
            {t('Settings.Save')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
