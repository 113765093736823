import React from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  colors,
  makeStyles,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const data = {
  series: [
    { color: '#7f7be4' },
    { color: '#01bcd4' },
    { color: '#f06292' },
    { color: '#7BC67E' },
    { color: '#7783DB' },
    { color: colors.purple[200] },
    { color: colors.cyan[200] },
    { color: colors.pink[200] },
    { color: colors.green[200] },
    { color: colors.orange[200] },
    { color: colors.blue[200] },
    { color: colors.red[200] }
  ]
};

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    float: 'right'
  }
}));

const LinealChartAP = ({ series, categories, title, href, translated }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  let translatedCategories = [];

  if (translated) {
    translatedCategories = categories.map(item =>
      t(`Status.${item.toLowerCase()}`)
    );
  }

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      title: {
        text: ''
      },
      colors: data.series.map(item => item.color),

      dataLabels: {
        enabled: true,
        style: {
          fontSize: '18px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold'
        }
      },
      labels: translated ? translatedCategories : categories,
      legend: {
        show: true,
        fontSize: '18px',
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 0,
        dashArray: 0
      },
      tooltip: {
        theme: theme.palette.type,
        style: {
          fontSize: '18px',
          fontFamily: undefined
        }
      },
      theme: {
        mode: theme.palette.type
      }
    },
    series: series
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Box>
          {href && (
            <div className={classes.link}>
              <Box alignItems="center" alignSelf="center" display="flex">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ marginRight: '1em' }}
                >
                  {t('Charts.FullReport')}
                </Typography>{' '}
                <IconButton>
                  <ExternalLink onClick={() => history.push(href)} />
                </IconButton>
              </Box>
            </div>
          )}
          <Typography
            variant="h4"
            color="textPrimary"
            style={{ fontSize: '25px' }}
          >
            {title}
          </Typography>
        </Box>
        <Chart type="donut" {...chart} height="570px" />
      </CardContent>
    </Card>
  );
};

export default LinealChartAP;
