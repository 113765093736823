import { 
  GET_REMINDERS, 
  CREATE_REMINDER, 
  GET_REMINDER, 
  DELETE_REMINDER, 
  UPDATE_REMINDER, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
 } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_REMINDERS:
      return {
        ...state,
        reminders: action.payload,
        loading: false, 
        error: null
      };
    case GET_REMINDER:
      return {
        ...state,
        reminder: action.payload,
        loading: false, 
        error: null
      };
    case CREATE_REMINDER:
      return {
        ...state,
        reminders: [...state.reminders, action.payload],
        loading: false, 
        error: null
      };
    case DELETE_REMINDER:
      state.reminders = state.reminders.filter( reminder => reminder._id.toString() !== action.payload.toString())
      return {
        ...state,
        reminder: null,
        loading: false, 
        error: null
      };
    case UPDATE_REMINDER:
      return {
        ...state,
        reminder: action.payload,
        loading: false, 
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case CLEAR_STATE:
      return {
        loading: false,
        error: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
        }
    default:
      return state;
  }
};
