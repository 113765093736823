import React, { useReducer } from 'react';
import TicketContext from './ticketContext';
import TicketReducer from './ticketReducer';
import api from '../../api/api';
import {
  GET_TICKETS,
  CREATE_TICKET,
  GET_TICKET,
  DELETE_TICKET,
  UPDATE_TICKET,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_NOTIFICATIONS_TICKETS,
  UPDATE_TICKET_LIST,
  UPDATE_LIST
} from '../types';

const TicketState = props => {
  const initialState = {
    tickets: [],
    ticket: {},
    notificationTickets: [],
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(TicketReducer, initialState);

  const updateNotificationTickets = async notificationId =>
    dispatch({ type: UPDATE_LIST, payload: notificationId });

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/tickets?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and`,
        config
      );
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateList = async ticket =>
    dispatch({ type: UPDATE_TICKET_LIST, payload: ticket });

  const getTicketsRockstar = async isRead => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.post(`/tickets/rockstar`, { isRead }, config);
      dispatch({ type: GET_NOTIFICATIONS_TICKETS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTickets = async (pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        res = await api.get(
          `/tickets?page=${pagination.page}&limit=${pagination.limit}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      }
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTicketsByUser = async (userId, pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        res = await api.get(
          `/tickets?page=${pagination.page}&limit=${pagination.limit}&user=${userId}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      }
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTicketsByGroup = async (pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';
      if (pagination) {
        res = await api.get(
          `/tickets/ticketsByGroup?page=${pagination.page}&limit=${pagination.limit}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      }
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTicketsByStore = async (stores, pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        res = await api.get(
          `/tickets?page=${pagination.page}&limit=${pagination.limit}${stores}&searchIndex=title-description-make-store&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      }
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Ticket
  const getTicket = async ticketId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearTicketState();
    setLoading();
    try {
      const res = await api.get(`/tickets/${ticketId}`, config);
      dispatch({ type: GET_TICKET, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Ticket
  const deleteTicket = async ticketId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/tickets/${ticketId}`, config);
      dispatch({ type: DELETE_TICKET, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Ticket
  const createTicket = async ticket => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearTicketState();
    setLoading();
    try {
      if (ticket.file) {
        const uploadConfig = await api.post(
          '/medias/images',
          { type: ticket.file.type, fileName: ticket.file.name },
          config
        );

        await api.put(uploadConfig.data.url, ticket.file, {
          headers: { 'Content-Type': ticket.file ? ticket.file.type : null }
        });

        ticket.image = `${uploadConfig.data.key}`;
      }

      const res = await api.post(`/tickets`, { ...ticket }, config);
      dispatch({ type: CREATE_TICKET, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Ticket
  const updateTicket = async (ticket, ticketId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/tickets/${ticketId}`, { ...ticket }, config);
      dispatch({ type: UPDATE_TICKET, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
  //Update Ticket
  const updateTicket2 = async (ticket, ticketId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let imagec = [];
      if (ticket.media === '') {
        ticket.media = [];
      }
      for (let i = 0; i < ticket.media.length; i++) {
        let resp = await api.post(
          '/medias/images',
          { type: ticket.media[i].type, fileName: ticket.media[i].name },
          config
        );
        const { data } = resp;
        const { url, key } = data;
        await api.put(url, ticket.media[i], {
          headers: { 'Content-Type': ticket.media[i].type }
        });
        imagec.push(`${key}`);
      }
      ticket.media = imagec;
      const res = await api.put(`/tickets/${ticketId}`, { ...ticket }, config);
      dispatch({ type: UPDATE_TICKET, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearTicketState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <TicketContext.Provider
      value={{
        loading: state.loading,
        tickets: state.tickets,
        ticket: state.ticket,
        error: state.error,
        count: state.count,
        notificationTickets: state.notificationTickets,
        getTickets,
        createTicket,
        getTicketsRockstar,
        getTicket,
        deleteTicket,
        updateTicket,
        updateTicket2,
        clearTicketState,
        setLoading,
        AdvancedResults,
        getTicketsByUser,
        getTicketsByGroup,
        getTicketsByStore,
        updateNotificationTickets,
        updateList
      }}
    >
      {props.children}
    </TicketContext.Provider>
  );
};

export default TicketState;
