import React, { useReducer } from 'react';
import ClosureInformationContext from './closureInformationContext';
import ClosureInformationReducer from './closureInformationReducer';
import api from '../../api/api';
import {
  GET_CLOSUREINFORMATIONS,
  GET_CLOSUREINFORMATION,
  CREATE_CLOSUREINFORMATION,
  DELETE_CLOSUREINFORMATION,
  UPDATE_CLOSUREINFORMATION,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const ClosureInformationState = props => {
  const initialState = {
    closureInformations: [],
    closureInformation: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(ClosureInformationReducer, initialState);

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/closureInformation/closuresAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
        config
      );
      dispatch({
        type: GET_CLOSUREINFORMATIONS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Recordings by store
  const getClosureInformationsByStore = async (
    stores,
    pagination,
    query,
    typeQuery,
    tab
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (tab === 'all')
        res = await api.get(
          `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      if (tab === 'success')
        res = await api.get(
          `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=success`,
          config
        );
      if (tab === 'canceled')
        res = await api.get(
          `/closureInformation?${stores}&page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=canceled`,
          config
        );

      dispatch({
        type: GET_CLOSUREINFORMATIONS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get ClosureInformations
  const getClosureInformations = async (pagination, query, typeQuery, tab) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      if (!query) query = '';

      let res;
      if (tab === 'all')
        res = await api.get(
          `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      if (tab === 'success')
        res = await api.get(
          `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=success`,
          config
        );
      if (tab === 'canceled')
        res = await api.get(
          `/closureInformation/closuresAr?page=${pagination.page}&searchIndex=invoiceNumber-vin-operationType&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&invoiceStatus=canceled`,
          config
        );

      dispatch({
        type: GET_CLOSUREINFORMATIONS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get ClosureInformation
  const getClosureInformation = async closureInformationId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(
        `/closureInformation/${closureInformationId}`,
        config
      );
      dispatch({ type: GET_CLOSUREINFORMATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get ClosureInformation
  const getClosureInformationByLead = async leadId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`leads/${leadId}/closureInformation`, config);
      dispatch({ type: GET_CLOSUREINFORMATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create ClosureInformation
  const createClosureInformation = async (closureInformation, file) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    clearState();
    setLoading();
    try {
      let res;
      if (file) {
        const uploadConfig = await api.post(
          '/medias/images',
          { type: file.type, fileName: file.name },
          config
        );

        await api.put(uploadConfig.data.url, file, {
          headers: { 'Content-Type': file ? file.type : null }
        });

        const dataKey = uploadConfig.data.key;

        res = await api.post(
          `/closureinformation`,
          { ...closureInformation, invoice: dataKey },
          config
        );
      } else {
        res = await api.post(
          `/closureinformation`,
          { ...closureInformation },
          config
        );
      }
      dispatch({ type: CREATE_CLOSUREINFORMATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete ClosureInformation
  const deleteClosureInformation = async closureInformationId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(
        `/closureInformation/${closureInformationId}`,
        config
      );
      dispatch({
        type: DELETE_CLOSUREINFORMATION,
        payload: res.data.deletedId
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update ClosureInformation
  const updateClosureInformation = async (
    closureInformation,
    closureInformationId,
    file
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();

    try {
      let res;
      if (file) {
        const uploadConfig = await api.post(
          '/medias/images',
          { type: file.type, fileName: file.name },
          config
        );

        await api.put(uploadConfig.data.url, file, {
          headers: { 'Content-Type': file ? file.type : null }
        });

        const dataKey = uploadConfig.data.key;

        res = await api.put(
          `/closureinformation/${closureInformationId}`,
          { invoice: dataKey },
          config
        );
      } else {
        res = await api.put(
          `/closureinformation/${closureInformationId}`,
          { ...closureInformation },
          config
        );
      }
      dispatch({ type: UPDATE_CLOSUREINFORMATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <ClosureInformationContext.Provider
      value={{
        loading: state.loading,
        closureInformations: state.closureInformations,
        closureInformation: state.closureInformation,
        error: state.error,
        count: state.count,
        getClosureInformations,
        createClosureInformation,
        getClosureInformation,
        deleteClosureInformation,
        updateClosureInformation,
        getClosureInformationByLead,
        setLoading,
        clearState,
        AdvancedResults,
        getClosureInformationsByStore
      }}
    >
      {props.children}
    </ClosureInformationContext.Provider>
  );
};

export default ClosureInformationState;
