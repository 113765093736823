import {
  SET_LOADING,
  CLEAR_STATE,
  SET_ERROR,
  SET_VIDEOCALL
} from "../types";
  
const Reducer = (state, action) => {
  switch (action.type) {
    case SET_VIDEOCALL:
      return {
        ...state,
        videoCall: action.payload,
        loading: false, 
        error: null
      }
    case SET_ERROR:
      return {
        ...state,
        loading: false, 
        error: action.payload
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_STATE: 
      return {
        token: null,
        loading: false,
        error: null,
      }
    default:
      return state;
  }
};

export default Reducer;