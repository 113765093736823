import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useChart from 'hooks/useChart';
import ColumnHCChart from 'components/ColumnChart';
// import PieChartAP from 'components/PieAPEX';
import numeral from 'numeral';
import ColumnChartAP from 'components/ColumnAPEX';
import useAuth from 'hooks/useAuth';
import TotalRepeaterCard from '../TotalRepeaterCard';
import useStore from 'hooks/useStore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

const DashboardView = ({ className, getStores, ...rest }) => {
  const classes = useStyles();
  //eslint-disable-next-line
  const [filter, setFilter] = useState('D-MMM');
  const { stores } = useStore();
  const { t } = useTranslation();
  const [date, setDate] = useState();
  const [storesSearch, setStoresSearch] = useState('');
  const [carType, setCarType] = useState('nuevo');
  const [leadType, setLeadType] = useState('&leadType=digital');
  const { user } = useAuth();
  const {
    // leadsByStore,
    appointmentsByStore,
    visitsByStore,
    soldsByStore,
    getAppointmentsByStore,
    getVisitsByStore,
    getSoldsByStore,
    getLeadsStoreChart,
    clearCharts,
    getTotalsDashboard,
    total,
    totalLeads,
    totalVisits,
    totalAppointments,
    // storeSubstatus,
    totalSolds,
    loadingCharts,
    leadsStoreChart
  } = useChart();

  useEffect(() => {
    if (user && user.role) {
      getStores(user.role._id);
    }
    //eslint-disable-next-line
  }, [user]);

  const [totales, setTotales] = useState([
    {
      size: 3,
      title: t('Status.lead3'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.appointment2'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.visit2'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.sold2'),
      data: 0
    }
  ]);

  useEffect(() => {
    setDate(
      `&createdAt[gte]=${moment()
        .startOf('month')
        .format()}&createdAt[lt]=${moment()
        .endOf('month')
        .format()}`
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      total !== null &&
      totalLeads !== null &&
      totalVisits !== null &&
      totalSolds !== null
    ) {
      setTotales([
        {
          size: 3,
          title: t('Status.lead3'),
          data: numeral(totalLeads).format('0,0')
        },
        {
          size: 3,
          title: t('Status.appointment2'),
          data: numeral(totalAppointments).format('0,0')
        },
        {
          size: 3,
          title: t('Status.visit2'),
          data: numeral(totalVisits).format('0,0')
        },
        {
          size: 3,
          title: t('Status.sold2'),
          data: numeral(totalSolds).format('0,0')
        }
      ]);
    }
    //eslint-disable-next-line
  }, [total, totalLeads, totalAppointments, totalVisits, totalSolds]);

  useEffect(() => {
    return () => clearCharts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (date) {
      getData();
    }
    //eslint-disable-next-line
  }, [date, storesSearch, stores, carType, leadType]);

  const handleReload = async () => {
    getData();
  };

  const getData = async () => {
    if (stores && stores.length > 0) {
      if (storesSearch !== '') {
        getLeadsStoreChart(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getAppointmentsByStore(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getVisitsByStore(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getSoldsByStore(`${date}&carType=${carType}${leadType}${storesSearch}`);
        getTotalsDashboard(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
      } else {
        getLeadsStoreChart(`${date}&carType=${carType}${leadType}`);
        getAppointmentsByStore(`${date}&carType=${carType}${leadType}`);
        getVisitsByStore(`${date}&carType=${carType}${leadType}`);
        getSoldsByStore(`${date}&carType=${carType}${leadType}`);
        getTotalsDashboard(`${date}&carType=${carType}${leadType}`);
      }
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header
          setDate={setDate}
          setFilter={setFilter}
          loading={loadingCharts}
          handleReload={handleReload}
          totalLeads={total}
          setStores={setStoresSearch}
          carType={carType}
          setCarType={setCarType}
          leadType={leadType}
          setLeadType={setLeadType}
        />

        <Grid container spacing={2} style={{ marginBottom: '0.25em' }}>
          <TotalRepeaterCard loading={loadingCharts} data={totales} />
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnChartAP
              series={
                leadsStoreChart &&
                leadsStoreChart.bar &&
                leadsStoreChart.bar.series
                  ? leadsStoreChart.bar.series
                  : []
              }
              categories={
                leadsStoreChart && leadsStoreChart.categories
                  ? leadsStoreChart.categories
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                leadsStoreChart && leadsStoreChart.colors
                  ? leadsStoreChart.colors
                  : []
              }
              title={'Total ' + t('Charts.Lbs')}
              href="/extra/highcharts/all"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnHCChart
              data={
                appointmentsByStore && appointmentsByStore.data
                  ? appointmentsByStore.data
                  : []
              }
              categories={
                appointmentsByStore && appointmentsByStore.categories
                  ? appointmentsByStore.categories
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                appointmentsByStore && appointmentsByStore.colors
                  ? appointmentsByStore.colors
                  : []
              }
              customName={t('Navbar.Appointments')}
              title={t('Status.appointment2')}
              href="/extra/highcharts/appointments"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnHCChart
              data={
                visitsByStore && visitsByStore.data ? visitsByStore.data : []
              }
              categories={
                visitsByStore && visitsByStore.categories
                  ? visitsByStore.categories
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                visitsByStore && visitsByStore.colors
                  ? visitsByStore.colors
                  : []
              }
              customName={t('Navbar.Visits')}
              title={t('Status.visit2')}
              href="/extra/highcharts/visits"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnHCChart
              data={soldsByStore && soldsByStore.data ? soldsByStore.data : []}
              categories={
                soldsByStore && soldsByStore.categories
                  ? soldsByStore.categories
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                soldsByStore && soldsByStore.colors ? soldsByStore.colors : []
              }
              customName={t('Navbar.Financing')}
              title={t('Status.sold2')}
              href="/reports/sales"
            />
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnChartAP
              customColors={colorschart}
              series={
                storeSubstatus && storeSubstatus.series
                  ? storeSubstatus.series
                  : []
              }
              categories={
                storeSubstatus && storeSubstatus.categories
                  ? storeSubstatus.categories
                  : []
              }
              title={t('Charts.LbSS')}
              translate={true}
              type="Substatus-Categories"
              href="/#"
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardView;
