import Swal from 'sweetalert2';

/**
 * Custom Alert that displays a custom modal with swal
 *
 * @param {swalConfiguration} swal configuration
 * @returns {void}
 */
const CustomAlert = ({
  title,
  text = '',
  icon = 'success',
  showConfirmButton = true,
  showCloseButton = true,
  showDenyButton = false,
  showCancelButton = false,
  confirmButtonText = 'Continuar',
  denyButtonText = 'Cancelar',
  // optional props
  handleThen,
  handleConfirm,
  handleCancel,
  ...props
}) => {
  let alert = {
    title,
    text,
    icon,
    showConfirmButton,
    showCloseButton,
    confirmButtonText,
    showCancelButton,
    showDenyButton,
    denyButtonText
  };
  if (props.timer) {
    alert.timerProgressBar = true;
  }
  Swal.fire({ ...alert, ...props }).then(result => {
    if (handleThen) handleThen();
    if (result.isConfirmed && handleConfirm) {
      handleConfirm();
    } else if (result.isDenied && handleCancel) {
      handleCancel();
    }
  });
};

export default CustomAlert;
