import React, { useReducer } from 'react';
import CommentBusinessContext from './commentBusinessContext';
import CommentBusinessReducer from './commentBusinessReducer';
import api from '../../api/api';
import {
  CREATE_COMMENT_BUSINESS,
  SET_ERROR,
  CLEAR_STATE,
  GET_COMMENTS_BUSINESS,
  GET_COMMENTS_BY_USER_BUSINESS,
  GET_COMMENTS_BY_STORE_BUSINESS,
  SET_LOADING,
  GET_COMMENT_BUSINESS,
  DELETE_COMMENT_BUSINESS,
  UPDATE_COMMENT_BUSINESS,
  CLEAR_COMMENT_BUSINESS
} from '../types';

const CommenBusinesstState = props => {
  const initialState = {
    comments: [],
    comment: {},
    loading: false,
    error: null,
    count: 0
  };

  const [state, dispatch] = useReducer(CommentBusinessReducer, initialState);

  //Create Comment
  const createCommentBusiness = async (comment, leadId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        `/leads/${leadId}/commentsBusiness`,
        { ...comment },
        config
      );
      dispatch({ type: CREATE_COMMENT_BUSINESS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update Commment
  const updateCommentBusiness = async (comment, commentId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(
        `/commentsBusiness/${commentId}`,
        { ...comment },
        config
      );
      dispatch({ type: UPDATE_COMMENT_BUSINESS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Gt comments to ListView
  const getCommentsBusinessAR = async (data, queryData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      let query = '';
      let reqQuery = { ...data };
      const removeFields = ['role'];

      removeFields.forEach(param => delete reqQuery[param]);

      let keys = [];
      for (var key in reqQuery) {
        keys.push(key);
      }

      keys.map(item => (query += `&${item}=${data[item]}`));

      if (data.role === 'rockstar') {
        res = await api.get(
          `/commentsBusiness/commentsAr?${query}${queryData}&searchIndex=name-make-agent`,
          config
        );
      } else {
        res = await api.get(
          `/commentsBusiness/commentsAr?${query}${queryData}&searchIndex=name-make-agent`,
          config
        );
      }

      dispatch({
        type: GET_COMMENTS_BUSINESS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err });
    }
  };

  //Get Comments
  const getCommentsBusiness = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(
        `/commentsBusiness/AdvancedResults`,
        { ...values },
        config
      );
      dispatch({
        type: GET_COMMENTS_BUSINESS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Comment
  const getCommentBusiness = async commentId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/commentsBusiness/${commentId}`, config);
      dispatch({ type: GET_COMMENT_BUSINESS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Comments By User
  const getCommentsBusinessByUser = async (userId, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `commentsBusiness?user=${userId}&pending=true${query}`,
        config
      );
      dispatch({ type: GET_COMMENTS_BY_USER_BUSINESS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Comments By Store
  const getCommentsBusinessByStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/commentsBusiness/admin?&pending=true${query}`,
        config
      );
      dispatch({
        type: GET_COMMENTS_BY_STORE_BUSINESS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Delete Comment
  const deleteCommentBusiness = async commentId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.delete(`/commentsBusiness/${commentId}`, config);
      dispatch({ type: DELETE_COMMENT_BUSINESS, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  const clearCommentBusiness = () => dispatch({ type: CLEAR_COMMENT_BUSINESS });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <CommentBusinessContext.Provider
      value={{
        loading: state.loading,
        comments: state.comments,
        comment: state.comment,
        error: state.error,
        count: state.count,
        deleteCommentBusiness,
        getCommentsBusinessByStore,
        getCommentsBusinessByUser,
        getCommentBusiness,
        getCommentsBusiness,
        getCommentsBusinessAR,
        createCommentBusiness,
        clearState,
        updateCommentBusiness,
        clearCommentBusiness
      }}
    >
      {props.children}
    </CommentBusinessContext.Provider>
  );
};

export default CommenBusinesstState;
