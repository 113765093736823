
import React, { useReducer } from 'react';
import CommentContext from './commentContext';
import CommentReducer from './commentReducer';
import api from '../../api/api';
import { 
  CREATE_COMMENT, 
  SET_ERROR, 
  CLEAR_STATE,
  GET_COMMENTS,
  GET_COMMENTS_BY_USER,
  GET_COMMENTS_BY_STORE,
  SET_LOADING,
  GET_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  CLEAR_COMMENT
} from '../types';

const CommentState = props => {
  const initialState = {
    comments: [],
    comment: {},
    loading: false,
    error: null,
    count: 0
  };

  const [state, dispatch] = useReducer(CommentReducer, initialState);

  //Create Comment
  const createComment = async (comment, leadId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    try {
      const res = await api.post(`/leads/${leadId}/comments`, { ...comment }, config);
      dispatch({ type: CREATE_COMMENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})
    }
  }


    //Create Comment
    const createCommentLeadBDC = async (comment, leadBDCId) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      try {
        const res = await api.post(`/comments/leadsBDC/${leadBDCId}`, { ...comment }, config);
        dispatch({ type: CREATE_COMMENT, payload: res.data.data });
      } catch (err) {
        dispatch({ type: SET_ERROR, payload: err.response.data.error})
      }
    }
  
  //Update Commment
  const updateComment = async (comment, commentId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/comments/${commentId}`, {...comment} ,config);
      dispatch({ type: UPDATE_COMMENT, payload: res.data.data })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Gt comments to ListView
  const getCommentsAR = async (data, queryData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      let res;
      let query = "";
      let reqQuery = { ...data };
      const removeFields = ["role"];

      removeFields.forEach((param) => delete reqQuery[param]);

      let keys = [];
      for (var key in reqQuery) {
        keys.push(key);
      }

      keys.map(item => query += `&${item}=${data[item]}`)

      if(data.role === 'rockstar'){
        res = await api.get(`/comments/commentsAr?${query}${queryData}&searchIndex=name-store-make-agent-vehicle`, config);
      }else{
        res = await api.get(`/comments/commentsAr?${query}${queryData}&searchIndex=name-store-make-agent-vehicle`, config);
      }

      dispatch({ 
        type: GET_COMMENTS, 
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err})
    }
  };

  //Get Comments
  const getComments = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const  res = await api.post(`/comments/AdvancedResults`, { ...values }, config);
      dispatch({ 
        type: GET_COMMENTS,
        payload: res.data.data,
        count: res.data.pagination.total
       });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})
    }
  };

  // const getComments = async (query) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`
  //     }
  //   };
  //   setLoading();
  //   try {
  //     const res = await api.get(`/comments?pending=true${query}`, config);
  //     dispatch({ type: GET_COMMENTS, payload: res.data.data });
  //   } catch (err) {
  //     dispatch({ type: SET_ERROR, payload: err.response.data.error})
  //   }
  // };

  //Get Comment
  const getComment = async (commentId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/comments/${commentId}`, config);
      dispatch({ type: GET_COMMENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Get Comments By User
  const getCommentsByUser = async (userId,query) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`comments?user=${userId}&pending=true${query}`, config);
      dispatch({ type: GET_COMMENTS_BY_USER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  };


  //Get Comments By User
  const getCommentsByUserLeadsBDC = async (userId,query) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`comments/adminLeadsBDC?user=${userId}&pending=true${query}`, config);
      dispatch({ type: GET_COMMENTS_BY_USER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  };

  //Get Comments By Store
  const getCommentsByStore = async (query) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/comments/admin?&pending=true${query}`, config);
      dispatch({ type: GET_COMMENTS_BY_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  };


  //Get Comments By Store
  const getCommentsByStoreLeadsBDC = async (query) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/comments/adminLeadsBDC?&pending=true${query}`, config);
      dispatch({ type: GET_COMMENTS_BY_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  };

  //Delete Comment
  const deleteComment = async (commentId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    clearState();
    setLoading();
    try {
      
      const res = await api.delete(`/comments/${commentId}`, config);
      dispatch({ type: DELETE_COMMENT, payload: res.data })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });
  
  const clearComment = () => dispatch({ type: CLEAR_COMMENT });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <CommentContext.Provider
      value={{
        loading: state.loading,
        comments: state.comments,
        comment: state.comment,
        error: state.error,
        count: state.count,
        getCommentsByStore,
        getCommentsByUser,
        getComments,
        getCommentsByStoreLeadsBDC,
        getCommentsByUserLeadsBDC,
        createComment,
        clearState,
        getComment,
        deleteComment,
        createCommentLeadBDC,
        updateComment,
        getCommentsAR,
        clearComment
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};

export default CommentState;

