import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Button,
  Menu,
  MenuItem,
  SvgIcon,
  Dialog,
  Box,
  Card,
  CardContent
} from '@material-ui/core';
import moment from 'moment';
import { 
  Calendar as CalendarIcon,
 } from 'react-feather';

 import { KeyboardDatePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'inherit',
    minHeight: '100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  }
}));

const ApexChartsView = ({ className,isDrill, setDate, setFilter, setTitleDate, setTitle, getFilter, all, customStartValue,initialTimeRange=3, ...rest }) => {
    const actionRef = useRef(null);
    const classes = useStyles();
    const { t } = useTranslation()
    const [open, setOpen] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState({
        start: new Date(moment().format()),
        end: new Date(moment().format()),
        });

    const timeRanges = [
        { value: 'all' },
        { value: 'today' },
        { value: 'yesterday' },
        { value: 'month' },
        { value: 'pastMonth' },
        { value: 'year' },
        { value: 'custom' } 
    ];

    const [timeRange, setTimeRange] = useState(timeRanges[initialTimeRange].value);

    useEffect(()=>{
        if(customStartValue) {
            setTimeRange(customStartValue)
        }
        //eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(all){
            setTimeRange(timeRanges[0].value)
        }else if(!customStartValue){
            setTimeRange(timeRanges[initialTimeRange].value)

        }
        //eslint-disable-next-line
    },[]);

    const handleChangeTime = filter => {
   if(!(isDrill?true:false)){
    setTimeRange(filter);
    if(setFilter) setFilter();
    switch (filter) {
        case 'all': 
            setDate('---');
            break;
        case 'today':
            setDate(`&createdAt[gte]=${moment().startOf('day').format()}&createdAt[lt]=${moment().add('1', 'days').startOf('day').format()}`)
            if(setFilter) setFilter('HH:00 a');

            break;
        case 'yesterday':
            setDate(`&createdAt[gte]=${moment().subtract('1','days').startOf('day').format()}&createdAt[lt]=${moment().startOf('day').format()}`);
            if(setFilter) setFilter('HH:00 a');

            break;
        case 'month':
            setDate(`&createdAt[gte]=${moment().startOf('month').format()}&createdAt[lt]=${moment().endOf('month').format()}`);
            if(setFilter) setFilter('DD MMMM YYYY');

            break;
        case 'pastMonth':
            setDate(`&createdAt[gte]=${moment().subtract('1', 'months').startOf('month').format()}&createdAt[lt]=${moment().subtract('1', 'months').endOf('month').format()}`);
            if(setFilter) setFilter('DD MMMM YYYY');

            break;
        case 'year':
            setDate(`&createdAt[gte]=${moment().subtract(12, 'months').format()}`);
            if(setFilter) setFilter('MMMM');

            break;
        case 'custom':
            setOpen(true);
            
            break;

      default:
        break;
    }


    if(getFilter) getFilter(filter)
    if(setTitle) setTitle(t(`Calendar.${filter}`));
   }

    };


    return (
        <div>
            <Grid item className={classes.root} {...rest} >
            <Dialog onClose={(e)=>setOpen(false)} open={open}>
                <Card>
                    <CardContent>
                        <Box>
                            <Box display='flex' justifyContent='center' mb={3} mt={1}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    label={t("Forms.StartDate")}
                                    inputVariant="outlined"
                                    format="DD MMMM YYYY"
                                    value={selectedDate.start}
                                    InputAdornmentProps={{ position: "start" }}
                                    disableToolbar
                                    onChange={date => setSelectedDate({...selectedDate, start: date})}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                            </Box>
                            <Box display='flex' justifyContent='center' mb={3}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    label={t("Forms.EndDate")}
                                    inputVariant="outlined"
                                    format="DD MMMM YYYY"
                                    value={selectedDate.end}
                                    InputAdornmentProps={{ position: "start" }}
                                    disableToolbar
                                    onChange={date => setSelectedDate({...selectedDate, end: date})}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                            </Box>
                        </Box>
                        <Button 
                            fullWidth
                            variant="contained"
                            onClick={(e)=>{
                                setDate(`&createdAt[gte]=${moment(selectedDate.start).startOf('day').format()}&createdAt[lt]=${moment(selectedDate.end).endOf('day').format()}`)
                                if(setTitleDate) setTitleDate(`${moment(selectedDate.start).format('DD MMMM YYYY')}-${moment(selectedDate.end).format('DD MMMM YYYY')}`)
                                // if(getFilter) getFilter(`${moment(selectedDate.start).format('DD MMMM YYYY')}-${moment(selectedDate.end).format('DD MMMM YYYY')}`)
                                setOpen(false)
                            }} 
                            color="primary"
                        >
                        {t("Buttons.Accept")}
                        </Button>
                    </CardContent>
                </Card>
            </Dialog>
            <Button
                ref={actionRef}
                onClick={() => setMenuOpen(true)}
                startIcon={
                <SvgIcon fontSize="small">
                    <CalendarIcon />
                </SvgIcon>
                }
            >
                {t(`Calendar.${timeRange}`)}

            </Button>
            <Menu
                anchorEl={actionRef.current}
                onClose={() => setMenuOpen(false)}
                open={isMenuOpen}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
                }}
            >
                {timeRanges.map(_timeRange => {
                    if(_timeRange.value !== 'all'){
                        return (
                            <MenuItem
                                key={_timeRange.value}
                                onClick={e => {
                                handleChangeTime(_timeRange.value)
                                setMenuOpen(false)
                                }}
                            >
                                {t(`Calendar.${_timeRange.value}`)}

                            </MenuItem>
                        )
                    }  

                    if(_timeRange.value === 'all' && all){
                        return (
                            <MenuItem
                            key={_timeRange.value}
                            onClick={e => {
                            handleChangeTime(_timeRange.value)
                            setMenuOpen(false)
                            }}
                        >
                            {t(`Calendar.${_timeRange.value}`)}

                        </MenuItem>
                        )
                    }  
                    return false;

                    })}
            </Menu>
            </Grid>
        </div>

    );
};

export default ApexChartsView;
