import React, { useReducer } from "react";
import QuotationContext from "./quotationContext";
import quotationReducer from "./quotationReducer";
import api from "../../api/api";
import {
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE,
  GET_QUOTATIONS,
  GET_QUOTATION,
  CREATE_QUOTATION,
  UPDATE_QUOTATION,
} from "../types";

const QuotationState = (props) => {
  const initialState = {
    loading: false,
    error: null,
    count: 0,
    quotations: [],
    quotation: {},
  };
  const [state, dispatch] = useReducer(quotationReducer, initialState);

  const setLoading = () => dispatch({ type: SET_LOADING });

  const clearState = () => dispatch({ type: CLEAR_STATE });

  /**
   * Get quotations of user depending of user.role
   *
   * @param {ObjectId} userId
   */
  const getQuotations = async (query) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setLoading();
    try {
      const res = await api.post(`/quotation/aggregationV3`, query, config);
      dispatch({
        type: GET_QUOTATIONS,
        payload: res.data.results.data,
        count: res.data.results.count,
      });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data,
      });
    }
  };

  /**
   * Get Quotation by quotation Id
   *
   * @param {ObjectId} quotationId
   */
  const getQuotation = async (quotationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoading();
      const res = await api.get(`/quotation/${quotationId}`, config);
      dispatch({ type: GET_QUOTATION, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data,
      });
    }
  };

  /**
   * Create Quotation
   *
   * @param {Object} quotation data required to create
   */
  const createQuotation = async (quotation) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoading();
      const res = await api.post(`/quotation/`, quotation, config);
      dispatch({ type: CREATE_QUOTATION, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data,
      });
    }
  };

  const updateQuotation = async (quotation, quotationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoading();
      const res = await api.put(`/quotation/${quotationId}`, quotation, config);
      dispatch({ type: UPDATE_QUOTATION, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data,
      });
    }
  };

  return (
    <QuotationContext.Provider
      value={{
        quotation: state.quotation,
        quotations: state.quotations,
        count: state.count,
        loading: state.loading,
        error: state.error,
        getQuotations,
        getQuotation,
        createQuotation,
        updateQuotation,
        clearState,
        setLoading,
      }}
    >
      {props.children}
    </QuotationContext.Provider>
  );
};
export default QuotationState;
