import React, { useReducer } from 'react';
import NotificationContext from './notificationContext';
import NotificationReducer from './notificationReducer';
import api from '../../api/api';
import {  
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION_VIEW, 
  SET_ERROR, UPDATE_NOTIFICATION,
  SET_LOADING, 
  UPDATE_NOTIFICATION_LIST,
  GET_NOTIFICATIONS_BY_USER,
  CREATE_NOTIFICATION,
  GET_ALL_NOTIFICATIONS_BY_USER,
  GET_ALL_NOTIFICATIONS,
  CLEAR_STATE,
} from '../types';

const NotificationState = props => {
  const initialState = {
    notifications: [],
    allNotifications: [],
    loading: false,
    error: null
  };

  const [state, dispatch] = useReducer(NotificationReducer, initialState);


  const updateList = async (notification) => dispatch({ type: UPDATE_NOTIFICATION_LIST, payload: notification });

  const createNotification = async (notification) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/notifications`, { ...notification }, config);
      dispatch({ type: CREATE_NOTIFICATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  }

  const updateNotificationsView = async (data) => {
    setLoading();
    try {
      dispatch({ type: UPDATE_NOTIFICATION_VIEW, payload: data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  }

  //Get Notifications
  const getNotificationsByUser = async (user, isRead) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/notifications/notificationsByUser`, { user, isRead }, config);
      if(isRead){
        dispatch({ type: GET_ALL_NOTIFICATIONS_BY_USER, payload: res.data.data });
      }else{
        dispatch({ type: GET_NOTIFICATIONS_BY_USER, payload: res.data.data });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Get Notifications Super admin
  const getNotificationsSuperAdmin = async (isRead, groupId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.post(`/notifications/superadmin`, {isRead, groupId},config);
      if(isRead){
        dispatch({ type: GET_ALL_NOTIFICATIONS, payload: res.data.data });
      }else{
        dispatch({ type: GET_NOTIFICATIONS, payload: res.data.data });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Get Notifications Admin
  const getNotificationsAdmin = async (isRead, stores) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    
    setLoading();

    try {
      const res = await api.post(`/notifications/admin`, {isRead, stores },config);
      if(isRead){
        dispatch({ type: GET_ALL_NOTIFICATIONS, payload: res.data.data });
      }else{
        dispatch({ type: GET_NOTIFICATIONS, payload: res.data.data });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Get Comments
  const updateNotification = async (notificationId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      await api.put(`/notifications/${notificationId}`, { isRead: true }, config);
      dispatch({ type: UPDATE_NOTIFICATION, payload: notificationId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: notificationId})

    }
  };

  //Clear State
  const setLoading = () => dispatch({ type: SET_LOADING });

    //Clear State
    const clearNotificationState = () => dispatch({ type: CLEAR_STATE });

  return (
    <NotificationContext.Provider
      value={{
        loading: state.loading,
        notifications: state.notifications,
        allNotifications: state.allNotifications,
        error: state.error,
        updateNotification,
        updateNotificationsView,
        getNotificationsByUser,
        createNotification,
        updateList,
        getNotificationsSuperAdmin,
        getNotificationsAdmin,
        clearNotificationState
      }}  
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
