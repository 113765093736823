import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import Spinner from 'components/Spinner';
import {
  blue,
  grey,
  purple,
  pink,
  cyan,
  yellow,
  teal,
  green,
  deepPurple,
  indigo,
  deepOrange
} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  primary: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  green: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  lightPurple: {
    backgroundColor: purple[400],
    color: 'white'
  },
  red: {
    backgroundColor: theme.palette.error.main,
    color: 'white'
  },
  blue: {
    backgroundColor: blue[500],
    color: 'white'
  },
  pink: {
    backgroundColor: pink[300],
    color: 'white'
  },
  yellow: {
    backgroundColor: yellow[700],
    color: 'white'
  },
  teal: {
    backgroundColor: teal[500],
    color: 'white'
  },
  grey: {
    backgroundColor: grey[500],
    color: 'white'
  },
  cyan: {
    backgroundColor: cyan[500],
    color: 'white'
  },
  deepPurple: {
    backgroundColor: deepPurple[500],
    color: 'white'
  },
  deepOrange: {
    backgroundColor: deepOrange[500],
    color: 'white'
  },
  indigo: {
    backgroundColor: indigo[500],
    color: 'white'
  },
  purple: {
    backgroundColor: purple[500],
    color: 'white'
  },
  orange: {
    backgroundColor: theme.palette.warning.main,
    color: 'white'
  },
  deepPink: {
    backgroundColor: pink[500],
    color: 'white'
  },
  deepYellow: {
    backgroundColor: yellow[900],
    color: 'white'
  },
  darkGreen: {
    backgroundColor: green[700],
    color: 'white'
  }
}));

const Totales = ({
  className,
  total,
  title,
  loading,
  icon,
  classIcon,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          // color="textSecondary"
          style={{
            color: 'textSecondary',
            fontSize: '19px',
            fontWeight: 'bold'
          }}
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h3" color="textPrimary">
            {loading ? <Spinner width={30} /> : total}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

Totales.propTypes = {
  className: PropTypes.string
};

export default Totales;
