import React, { useReducer } from 'react';
import VehicleContext from './vehicleContext';
import VehicleReducer from './vehicleReducer';
import api from '../../api/api';
import {
  GET_VEHICLES,
  GET_VEHICLES_BY_MAKE,
  GET_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  CREATE_VEHICLE,
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE
} from '../types';

const VehicleState = props => {
  const initialState = {
    vehicles: [],
    vehicle: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(VehicleReducer, initialState);

  //Get Vehicles MulsiStore
  const getVehiclesByMultiStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/vehicles/multiStore?${query}`, config);
      dispatch({ type: GET_VEHICLES_BY_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Vehicles
  const getVehiclesByMake = async makeId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`vehicles/make/${makeId}`, config);
      dispatch({ type: GET_VEHICLES_BY_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/vehicles/vehiclesAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
        config
      );
      dispatch({
        type: GET_VEHICLES,
        payload: res.data.data,
        count: res.data.count
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get vehicles
  const getAllVehicles = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/vehicles/carokou?sort=name`, config);
      dispatch({
        type: GET_VEHICLES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get vehicles
  const getVehicles = async (pagination, query, typeQuery, queryExtra) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (!queryExtra) queryExtra = '';
    setLoading();
    try {
      const res = await api.get(
        `/vehicles/vehiclesAr?page=${pagination.page}&searchIndex=name-make&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1${queryExtra}`,
        config
      );
      dispatch({
        type: GET_VEHICLES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get vehicles
  const getVehiclesV3 = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/vehicles/AggregationV3`, query, config);
      dispatch({
        type: GET_VEHICLES,
        payload: res.data.results.data,
        count: res.data.results.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Vehicles
  const getVehicle = async vehicleId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/vehicles/${vehicleId}`, config);
      dispatch({ type: GET_VEHICLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Vehicle
  const deleteVehicle = async vehicleId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/vehicles/${vehicleId}`, config);
      dispatch({ type: DELETE_VEHICLE, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Vehicle
  const createVehicle = async vehicle => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(
        `makes/${vehicle.make}/vehicles`,
        { ...vehicle },
        config
      );
      dispatch({ type: CREATE_VEHICLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Vehicle
  const updateVehicle = async (vehicle, vehicleId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.put(
        `/vehicles/${vehicleId}`,
        { ...vehicle },
        config
      );
      dispatch({ type: UPDATE_VEHICLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <VehicleContext.Provider
      value={{
        loading: state.loading,
        vehicles: state.vehicles,
        vehicle: state.vehicle,
        error: state.error,
        count: state.count,
        getVehiclesByMake,
        getVehicles,
        getVehiclesV3,
        getVehicle,
        createVehicle,
        AdvancedResults,
        deleteVehicle,
        getVehiclesByMultiStore,
        updateVehicle,
        getAllVehicles,
        clearState,
        setLoading
      }}
    >
      {props.children}
    </VehicleContext.Provider>
  );
};

export default VehicleState;
