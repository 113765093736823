import { 
  CREATE_COMMENT, 
  SET_ERROR, 
  CLEAR_STATE,
  GET_COMMENTS,
  GET_COMMENTS_BY_USER,
  GET_COMMENTS_BY_STORE,
  SET_LOADING,
  GET_COMMENT,
  UPDATE_COMMENT,
  CLEAR_COMMENT
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case CLEAR_COMMENT:
      return {
        ...state,
        comment: {},
        loading: false,
        error: null
      }
    case UPDATE_COMMENT:
      let updatedComments = [];
      if(!action.payload.pending){
        updatedComments = state.comments.map(x=> x._id !== action.payload._id);
      }else{
        updatedComments = state.comments;
      }
      return {
      ...state,
      comments:updatedComments,
      loading:false,
    }
    case GET_COMMENT: 
      return {
        ...state,
        loading: false,
        comment: action.payload
      }
    case GET_COMMENTS: 
    return {
      ...state,
      comments: action.payload,
      count: action.count,
      loading: false,
      error: null
    }
    case GET_COMMENTS_BY_USER:
    case GET_COMMENTS_BY_STORE:
      return {
        ...state,
        comments: action.payload,
        loading: false,
        error: null
      }
    case CREATE_COMMENT:
      return {
        ...state,
        comments: [action.payload, ...state.comments],
        loading: false
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case CLEAR_STATE:
      return {
        ...state,
        comments: [],
        comment: {},
        error: null,
        loading: false
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
        }
    default:
      return state;
  }
};
