import React, { useReducer } from 'react';
import RecordingContext from './recordingContext';
import RecordingReducer from './recordingReducer';
import api from '../../api/api';
import {
  GET_RECORDINGS,
  GET_RECORDINGS_BY_LEAD,
  GET_RECORDINGS_BY_STORE,
  CREATE_RECORDING,
  GET_RECORDING,
  DELETE_RECORDING,
  UPDATE_RECORDING,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const RecordingState = props => {
  const initialState = {
    recordings: [],
    recording: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(RecordingReducer, initialState);

  const AdvancedResults = async (pagination, query, stores = '', tab = '') => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      let rating = ['rated', 'norated'].includes(tab) ? `&rated=${tab}` : '';
      const res = await api.get(
        `/recordings/recordingsAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1${stores}${rating}`,
        config
      );
      dispatch({
        type: GET_RECORDINGS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Recordings
  const getRecordings = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      let res = await api.post(
        `/recordings/recordingsAr`,
        { ...values },
        config
      );

      dispatch({
        type: GET_RECORDINGS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Recordings by lead
  const getRecordingsByLead = async leadId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.get(`/leads/${leadId}/recordings`, config);

      dispatch({ type: GET_RECORDINGS_BY_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Recordings by store
  const getRecordingsByStore = async (
    stores,
    pagination,
    query,
    typeQuery,
    tab
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      let res;
      if (tab === 'all')
        res = await api.get(
          `/recordings/recordingsAr?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      if (tab === 'rated')
        res = await api.get(
          `/recordings/recordingsAr?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&rated=rated`,
          config
        );
      if (tab === 'norated')
        res = await api.get(
          `/recordings/recordingsAr?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1&rated=norated`,
          config
        );

      dispatch({
        type: GET_RECORDINGS_BY_STORE,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Recording
  const getRecording = async recordingId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    clearState();
    setLoading();
    try {
      const res = await api.get(`/recordings/${recordingId}`, config);
      dispatch({ type: GET_RECORDING, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Recording
  const deleteRecording = async recordingId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.delete(`/recordings/${recordingId}`, config);
      dispatch({ type: DELETE_RECORDING, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Recording
  const createRecording = async recording => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    clearState();
    setLoading();
    try {
      const res = await api.post(`/recordings`, { ...recording }, config);
      dispatch({ type: CREATE_RECORDING, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update Recording
  const updateRecording = async (recording, recordingId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.put(
        `/recordings/${recordingId}`,
        { ...recording },
        config
      );
      dispatch({ type: UPDATE_RECORDING, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <RecordingContext.Provider
      value={{
        loading: state.loading,
        recordings: state.recordings,
        recording: state.recording,
        error: state.error,
        count: state.count,
        getRecordings,
        createRecording,
        getRecording,
        deleteRecording,
        updateRecording,
        clearState,
        setLoading,
        getRecordingsByStore,
        getRecordingsByLead,
        AdvancedResults
      }}
    >
      {props.children}
    </RecordingContext.Provider>
  );
};

export default RecordingState;
