import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { getMultiStoresIds, getMultiStoresMakesIds } from 'utils/storesUser';

import useStore from 'hooks/useStore';
import useSource from 'hooks/useSource';
import useUser from 'hooks/useUser';
import useVehicle from 'hooks/useVehicle';
import useComment from 'hooks/useComment';
import useMake from 'hooks/useMake';
import useAppointment from 'hooks/useAppointment';
import useRole from 'hooks/useRole';
import {
  isAdmin,
  isDigitalMkt,
  isGeneralManager,
  isMarketing,
  isRockstar,
  isSuper,
  isUser,
  isSalesManager,
  isRH,
  isCarokou,
  isCarokouUser
} from 'utils/AuthRoles';
import moment from 'moment';

const DPXWrapper = ({ children, props }) => {
  const { user } = useAuth();

  const { getSources } = useSource();
  const { getAgents, setAgent } = useUser();
  const { getStores, getStoresByUser } = useStore();
  const { getMakes } = useMake();
  const { getVehiclesByMultiStore, getAllVehicles } = useVehicle();
  const { getCommentsByStore, getCommentsByUser } = useComment();
  const { getAppointmentsByStore, getAppointmentsByUser } = useAppointment();
  const { getAllRoles } = useRole();

  const getAppointmentsDPX = async ({ role, id, store }) => {
    if (isUser(role) || role === 'agent' || isCarokouUser(role)) {
      await getAppointmentsByUser(
        id,
        `&startDate[lt]=${moment(new Date())
          .add(15, 'days')
          .format('l')}&lead[exists]=true`
      );
    } else if (isAdmin(role) || isSuper(role) || isRH(role)) {
      await getAppointmentsByStore(
        `&store[in]=${getMultiStoresIds(store)}&startDate[lt]=${moment(
          new Date()
        )
          .add(15, 'days')
          .format('l')}&lead[exists]=true`
      );
    } else if (isRockstar(role)) {
      // await getAppointments()
    } else if (role === 'store') {
      await getAppointmentsByStore(
        `&store[in]=${store}&startDate[lt]=${moment(new Date())
          .add(15, 'days')
          .format('l')}&lead[exists]=true`
      );
    }
  };

  const getVehiclesDPX = async ({ role, isActive, store }) => {
    let query = '';
    if (isActive) query = '&isActive=true';
    if (
      isUser(role) ||
      isAdmin(role) ||
      isSuper(role) ||
      isRH(role) ||
      isGeneralManager(role) ||
      isSalesManager(role)
    ) {
      if (store) {
        await getVehiclesByMultiStore(
          `&make[in]=${getMultiStoresMakesIds(store)}${query}`
        );
      }
    } else if (isRockstar(role)) {
      await getAllVehicles(query);
    } else if (role === 'seminuevos') {
      await getAllVehicles(query);
    } else if (role === 'make') {
      if (store) {
        await getVehiclesByMultiStore(`&make[in]=${store}${query}`);
      }
    }
  };

  const getCommentsDPX = async ({ role, id, store, carType }) => {
    let query = '';
    if (carType) query = carType;
    if (isUser(role) || role === 'agent') {
      await getCommentsByUser(
        id,
        `${query}&reschedule[lt]=${moment(new Date())
          .add(15, 'days')
          .format('l')}`
      );
    } else if (isAdmin(role) || isSuper(role) || isRH(role)) {
      await getCommentsByStore(
        `&store[in]=${getMultiStoresIds(store)}${query}&reschedule[lt]=${moment(
          new Date()
        )
          .add(15, 'days')
          .format('l')}`
      );
    } else if (role === 'store') {
      await getCommentsByStore(
        `&store[in]=${store}${query}&reschedule[lt]=${moment(new Date())
          .add(15, 'days')
          .format('l')}`
      );
    }
  };

  const getAgentsDPX = async ({
    role,
    store,
    type,
    areas,
    tier = '6144d47b8fb0f1322599778c'
  }) => {
    let query = '';
    let queryLead = '';
    if (type) query = `&carType=${type}`;
    if (areas) queryLead = `&areas=${areas}`;
    if (
      isAdmin(role) ||
      isSuper(role) ||
      isRH(role) ||
      isGeneralManager(role) ||
      isSalesManager(role)
    ) {
      await getAgents(
        `&stores[in]=${getMultiStoresIds(store)}${query}${queryLead}`,
        tier
      );
    } else if (isRockstar(role)) {
      await getAgents('', tier);
    } else if (role === 'store') {
      await getAgents(`&stores[in]=${store}${query}${queryLead}`, tier);
    } else if (isUser(role)) {
      await setAgent(user);
    }
  };

  const getSourcesDPX = async (role, isActive = true) => {
    let filter = isActive ? '&isActive=true' : '';
    await getSources(filter);
  };

  const getRolesDPX = async () => await getAllRoles();

  const getStoresDPX = async role => {
    if (isAdmin(role) || isUser(role) || isMarketing(role) || role === 'user') {
      await getStoresByUser(user._id);
    } else if (
      isSuper(role) ||
      isRH(role) ||
      isDigitalMkt(role) ||
      isGeneralManager(role) ||
      isSalesManager(role) ||
      isCarokou(role) ||
      isCarokouUser(role)
    ) {
      await getStoresByUser(user._id);
    } else if (isRockstar(role)) {
      await getStores(true);
    }
  };

  const getMakesDPX = async role => await getMakes(`&isActive=true`);

  const childrenDpxProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...props,
        getStores: getStoresDPX,
        getMakes: getMakesDPX,
        getSources: getSourcesDPX,
        getAgents: getAgentsDPX,
        getVehicles: getVehiclesDPX,
        getComments: getCommentsDPX,
        getAppointments: getAppointmentsDPX,
        getRoles: getRolesDPX
      });
    }
    return child;
  });

  return <>{childrenDpxProps}</>;
};

DPXWrapper.propTypes = {
  children: PropTypes.node
};

export default DPXWrapper;
