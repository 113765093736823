import React from 'react';
import { Grid } from '@material-ui/core';
import Total from 'components/Totales';

const TotalRepeaterCard = ({ loading, data }) => {
  return (
    <>
      {data.map((item, i) => {
        if (item.size) {
          return (
            <Grid item lg={item.size} md={item.size} sm={12} xs={12} key={i}>
              <Total
                title={item.title}
                loading={loading}
                total={item.data}
                icon={item.icon}
                classIcon={item.className}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item lg md sm xs key={i}>
              <Total
                title={item.title}
                loading={loading}
                total={item.data}
                icon={item.icon}
                classIcon={item.className}
              />
            </Grid>
          );
        }
      })}
    </>
  );
};

export default TotalRepeaterCard;
