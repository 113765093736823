import React, { useRef, useState, useEffect, Fragment } from 'react';
import { socket } from 'service/socket';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { ExternalLink, X } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import useNotification from 'hooks/useNotification';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import { getMultiStoresIds } from 'utils/storesUser';
import {
  isAdmin,
  isAdminBDC,
  isAdminService,
  isGeneralManager,
  isRockstar,
  isSalesManager,
  isUser
} from 'utils/AuthRoles';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = ({ props, ...rest }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [playbackRate, setPlaybackRate] = useState(0.75);
  const [play] = useSound('/static/newNotification.mp3', {
    playbackRate,
    volume: 1
  });
  const {
    updateNotification,
    getNotificationsByUser,
    notifications,
    updateList,
    getNotificationsAdmin
  } = useNotification();

  const handleMarkAllAsRead = () => {
    if (notifications && notifications.length > 0) {
      notifications.map(notification => updateNotification(notification._id));
    }
  };

  useEffect(() => {
    const keys = [];
    for (var k in socket && socket._callbacks) keys.push(k);
    keys.map(item => {
      if (socket && socket._callbacks[item].length > 1) {
          socket._callbacks[item].push(socket && socket._callbacks[item][0]);
      }
      return false;
    });
  }, [user]);

  useEffect(() => {
    if (playbackRate === 1) {
      play();
    }
    //eslint-disable-next-line
  }, [playbackRate]);

  useEffect(() => {
    if(!socket)return
      socket.on('getNotification', data => {
        if (data.type === 'assign') {
          setPlaybackRate(1);
          enqueueSnackbar(
            data.message.replace(
              'has assigned you a lead',
              t('Messages.AssignLead')
            ),
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              variant: 'info',
              action: key => (
                <Fragment>
                  <IconButton
                    onClick={() => {
                      history.push(`/management/leads/${data.lead}`);
                    }}
                  >
                    <ExternalLink />
                  </IconButton>
                  <IconButton onClick={() => closeSnackbar(key)}>
                    <X />
                  </IconButton>
                </Fragment>
              )
            }
          );
          updateList(data);
          setPlaybackRate(0.75);
        }

        if (data.type === 'newLead' || data.type === 'preownedLead') {
          setPlaybackRate(1);
          enqueueSnackbar(
            data.message.replace(
              'Ha llegado un nuevo lead',
              t('Messages.NewLead')
            ),
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              variant: 'info',

              action: key => (
                <Fragment>
                  <IconButton
                    onClick={() => {
                      history.push(`/management/leads/${data.lead._id}`);
                    }}
                  >
                    <ExternalLink />
                  </IconButton>
                  ,
                  <IconButton onClick={() => closeSnackbar(key)}>
                    <X />
                  </IconButton>
                </Fragment>
              )
            }
          );
          updateList(data);
          setPlaybackRate(0.75);
        }

        if (data.type === 'ticketResponse') {
          enqueueSnackbar(t('Messages.TicketResponse'), {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            variant: 'info',
            action: key => (
              <Fragment>
                <IconButton
                  onClick={() => {
                    history.push(`/management/tickets/${data._id}`);
                  }}
                >
                  <ExternalLink />
                </IconButton>
                ,
                <IconButton onClick={() => closeSnackbar(key)}>
                  <X />
                </IconButton>
              </Fragment>
            )
          });
        }
      });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket && user && user.role && isUser(user.role._id)) {
       socket.emit('leaveRoom', `${user._id}-room`);
       socket.emit('connectRoom', `${user._id}-room`);
      getNotificationsByUser(user._id, false);
    }

    if (socket && user && user.role && user.stores && isAdmin(user.role._id)) {
      user.stores.map(store => {
         socket.emit('leaveRoom', `${store._id}-room`);
         socket.emit('connectRoom', `${store._id}-room`);
        return false;
      });
      getNotificationsAdmin(false, `${getMultiStoresIds(user.stores)}`);
    }

    //Recordings notifications
    if (
      socket &&
      user &&
      user.role &&
      user.stores &&
      (isAdmin(user.role._id) ||
        isAdminBDC(user.role._id) ||
        isAdminService(user.role._id) ||
        isGeneralManager(user.role._id) ||
        isSalesManager(user.role._id))
    ) {
      user.stores.map(store => {
         socket.emit('leaveRoom', `${store._id}-recordings`);
         socket.emit('connectRoom', `${store._id}-recordings`);
        return false;
      });
    }

    if (socket && user && user.role && isRockstar(user.role._id)) {
       socket.emit('leaveRoom', `rockstar-recordings`);
       socket.emit('connectRoom', `rockstar-recordings`);
    }

    //eslint-disable-next-line
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div {...rest}>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <BellIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            {t('Notifications.Notifications')}
          </Typography>
        </Box>
        {notifications && notifications.length === 0 ? (
          <>
            <Box p={2}>
              <Typography variant="h6" color="textPrimary">
                {t('Notifications.NoNotifications')}
              </Typography>
            </Box>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                fullWidth
                to="/management/notifications"
              >
                {t('Notifications.SeeMore')}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <List disablePadding>
              {notifications &&
                notifications.map(notification => {
                  return (
                    <ListItem
                      component={RouterLink}
                      divider
                      key={notification._id}
                      to="#"
                      onClick={e => {
                        updateNotification(notification._id);
                        history.push(
                          `/management/leads/${notification.lead._id}`
                        );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.icon}>
                          <SvgIcon fontSize="small">
                            <MessageIcon />
                          </SvgIcon>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={notification.message.replace(
                          'has assigned you a lead',
                          t('Messages.AssignLead')
                        )}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary'
                        }}
                        secondary={moment(notification.createdAt).fromNow()}
                      />
                    </ListItem>
                  );
                })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                to="#"
                onClick={handleMarkAllAsRead}
              >
                {t('Notifications.AllAsRead')}
              </Button>
            </Box>

            <Box p={1} display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                to="/management/notifications"
              >
                {t('Notifications.SeeMore')}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Notifications;
