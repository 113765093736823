import {
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE,
  GET_QUOTATIONS,
  GET_QUOTATION,
  CREATE_QUOTATION,
  UPDATE_QUOTATION
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_QUOTATION:
    case UPDATE_QUOTATION:
    case CREATE_QUOTATION:
      return {
        ...state,
        quotation: action.payload,
        loading: false
      };
    case GET_QUOTATIONS:
      return {
        ...state,
        quotations: action.payload,
        count: action.count,
        loading: false,
        error: null
      };
    case CLEAR_STATE:
      return {
        ...state,
        quotation: {},
        quotations: [],
        loading: false,
        error: null,
        count: null
      };
    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
