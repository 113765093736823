import React, { useReducer } from 'react';
import UserContext from './userContext';
import UserReducer from './userReducer';
import api from '../../api/api';
import {
  GET_USERS,
  GET_USER,
  GET_AGENTS,
  UPDATE_USER,
  DELETE_USER,
  CREATE_USER,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_USERS_BY_STORE,
  SET_AGENT,
  GET_AGENTS_FORM,
  CLEAR_OPERATORS,
  GET_OPERATORS
} from '../types';

const UserState = props => {
  const initialState = {
    users: [],
    user: {},
    loading: false,
    error: null,
    count: null,
    agents: [],
    operators: [],
    agentsForm: []
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/users/carokou?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and`,
        config
      );
      dispatch({
        type: GET_USERS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getUsers = async (pagination, query, typeQuery, roles = '') => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        res = await api.get(
          `/users/carokou?page=${pagination.page}&limit=${pagination.limit}${roles}&searchIndex=name-email-phone-multiRoles&searchText=${query}&searchType=${typeQuery}&validation=1`,
          config
        );
      }
      dispatch({
        type: GET_USERS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const setAgent = async user => dispatch({ type: SET_AGENT, payload: user });

  //Get Agents
  const getAgents = async (query, role = '641f3ca3e9f658304da5e7b9') => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (!query) query = '';

    setLoading();
    try {
      const res = await api.get(
        `/users/agents?sort=name&role=641f3ca3e9f658304da5e7b9&isActive=true${query}`,
        config
      );
      dispatch({ type: GET_AGENTS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Agents
  const getAgentsForm = async (query, tier = '641f3ca3e9f658304da5e7b9') => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (!query) query = '';

    setLoading();
    try {
      const res = await api.get(
        `/users/agents?sort=name&tier=${tier}&isActive=true${query}`,
        config
      );
      dispatch({ type: GET_AGENTS_FORM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getOperators = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (!query) query = '';

    clearOperators();
    setLoading();
    try {
      const res = await api.get(
        `/users/agents?sort=name&tier=62f68a10ab36e6c1394c9ce2&isActive=true${query}`,
        config
      );
      dispatch({ type: GET_OPERATORS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Users By Store
  const getUsersByStore = async (pagination, query, typeQuery, multiStores) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/users/carokou?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-phone-multiRoles&searchText=${query}&searchType=${typeQuery}&validation=1${multiStores}`,
        config
      );
      dispatch({
        type: GET_USERS_BY_STORE,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Single Item by ID
  const getUser = async userId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/users/${userId}`, config);
      dispatch({
        type: GET_USER,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete User
  const deleteUser = async userId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/users/${userId}`, config);
      dispatch({ type: DELETE_USER, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create User
  const createUser = async user => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearUserState();
    setLoading();
    try {
      const res = await api.post(`/users`, { ...user }, config);
      dispatch({ type: CREATE_USER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update User
  const updateUser = async (user, userId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/users/${userId}`, { ...user }, config);
      dispatch({ type: UPDATE_USER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearUserState = () => dispatch({ type: CLEAR_STATE });
  const clearOperators = () => dispatch({ type: CLEAR_OPERATORS });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <UserContext.Provider
      value={{
        loading: state.loading,
        users: state.users,
        user: state.user,
        agents: state.agents,
        error: state.error,
        count: state.count,
        operators: state.operators,
        agentsForm: state.agentsForm,
        getUsers,
        getAgents,
        getUser,
        getOperators,
        deleteUser,
        createUser,
        updateUser,
        clearUserState,
        setLoading,
        getUsersByStore,
        getAgentsForm,
        AdvancedResults,
        setAgent
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
