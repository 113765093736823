import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { isAdmin, isAdminBDC, isUser, isUserBDC } from 'utils/AuthRoles';

const rolesArray = {
  rockstar: '6144d4548fb0f00000000000',
  'super admin': '641a0aeab9338d2e0616132c',
  admin: '6459145dff30096ffd4f0118',
  adminBDC: '641a0afdb9338d2e06161340',
  user: '641f3ca3e9f658304da5e7b9',
  marketing: '641a0b0eb9338d2e0616134a',
  receptionist: '641a0bbdb9338d2e06161389',
  'digital marketing': '641a0c4eb9338d2e061613af',
  'general manager': '641a0aceb9338d2e06161327',
  userBDC: '641a0c6fb9338d2e061613ca',
  userService: '641a0c7cb9338d2e061613d3',
  adminService: '641a0c90b9338d2e061613e8',
  'sales manager': '641a0ca2b9338d2e061613ed',
  rh: '641a0cc7b9338d2e06161416',
  carone: '641a0d38b9338d2e06161442'
};

const RolesGuard = ({ children, roles }) => {
  const { user } = useAuth();
  const history = useHistory();

  if (!localStorage.getItem('token')) history.push('/');

  useEffect(() => {
    if (user && user.role) {
      if (roles) {
        let access = false;
        roles.map(item => {
          if (rolesArray[item] === user.role._id.toString()) {
            access = true;
          }
          return false;
        });

        if (!access) {
          history.push('/reports/dashboard');
        }

        if (
          (isUser(user.role._id) ||
            isAdmin(user.role._id) ||
            isUserBDC(user.role._id) ||
            isAdminBDC(user.role._id)) &&
          history.location.pathname === '/management/showroom-leads' &&
          user.leadType !== 'all' &&
          user.leadType !== 'showroom'
        )
          history.push('/reports/dashboard');
        if (
          (isUser(user.role._id) ||
            isAdmin(user.role._id) ||
            isUserBDC(user.role._id) ||
            isAdminBDC(user.role._id)) &&
          history.location.pathname === '/management/leads' &&
          user.leadType !== 'all' &&
          user.leadType !== 'lead'
        )
          history.push('/reports/dashboard');
        if (
          (isUser(user.role._id) ||
            isAdmin(user.role._id) ||
            isUserBDC(user.role._id) ||
            isAdminBDC(user.role._id)) &&
          history.location.pathname === '/create/showroom-lead' &&
          user.leadType !== 'all' &&
          user.leadType !== 'showroom'
        )
          history.push('/reports/dashboard');
        if (
          (isUser(user.role._id) ||
            isAdmin(user.role._id) ||
            isUserBDC(user.role._id) ||
            isAdminBDC(user.role._id)) &&
          history.location.pathname === '/create/lead' &&
          user.leadType !== 'all' &&
          user.leadType !== 'lead'
        )
          history.push('/reports/dashboard');
      } else {
        history.push('/');
      }
    }
    //eslint-disable-next-line
  }, [user]);

  return <>{children}</>;
};

RolesGuard.propTypes = {
  children: PropTypes.node
};

export default RolesGuard;
