import React from 'react';
import { Box, List, ListSubheader } from '@material-ui/core';
import { isUser } from 'utils/AuthRoles';

const Repeater = ({ renderNavItems, data, user, location, role }) => {
  if (user.areas && isUser(user.role._id)) {
    let aux = false;
    user.areas.forEach(area => {
      if (area === 'business' && !aux) {
        aux = true;
      }
    });

    if (aux) {
      delete data[1].items[1];
    }
  }

  return (
    <>
      {user &&
        user.role &&
        user.role._id === role &&
        data.map(section => (
          <Box p={2} key={Math.random() + Math.random()}>
            <List
              key={section.subheader + Math.random()}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                user
              })}
            </List>
          </Box>
        ))}
    </>
  );
};

export default Repeater;
