import React, { useReducer } from 'react';
import LeadContext from './leadContext';
import LeadReducer from './leadReducer';
import api from '../../api/api';
import {
  GET_LEADS,
  GET_LEAD,
  UPDATE_LEAD,
  DELETE_LEAD,
  CREATE_LEAD,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_LEADS_BY_STORE,
  GET_LEADS_BY_USER,
  CALL_USER,
  LOAD_CSV,
  ASSIGN_AGENTS,
  ASSIGN_STATUSES,
  ADD_COMMENT,
  ADD_APPOINTMENT,
  UPDATE_TASK_FROM_LEAD,
  ASSIGN_LIST,
  DELETE_COMMENT,
  UPDATE_PAPERWORK,
  LOAD_CORRECT_CSV,
  GET_TOKEN_UPDATE
} from '../types';

const LeadState = props => {
  const initialState = {
    leads: [],
    lead: {},
    loading: false,
    error: null,
    analytics: [],
    lastLeads: [],
    labels: null,
    count: null,
    callToken: null,
    excelResponse: [],
    tokenUpdate: null
  };

  const [state, dispatch] = useReducer(LeadReducer, initialState);

  //Delete lead from state
  const deleteCommentLead = async commentId =>
    dispatch({ type: DELETE_COMMENT, payload: commentId });

  const getTokenUpdate = async leadId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(`/leads/token/${leadId}`, config);
      dispatch({ type: GET_TOKEN_UPDATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const sendURL = async data => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        `/leads/paperwork`,
        { url: data.url, email: data.email, lead: data.lead },
        config
      );
      dispatch({ type: UPDATE_PAPERWORK, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Leads
  const getLeads = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.post(
        `/leads/AdvancedResults`,
        { ...values },
        config
      );
      dispatch({
        type: GET_LEADS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getLeadsV2 = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(
        `/leads/AdvancedResultsV2`,
        { ...values },
        config
      );
      dispatch({
        type: GET_LEADS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Leads
  const startCron = async status => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      await api.post(`/leads/sendAlerts`, { start: status }, config);
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Leads
  const getLeadsByStore = async (
    storequery,
    pagination,
    tabs,
    query,
    typeQuery,
    multiStore
  ) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        if (tabs.includes('temperature')) {
          let temp = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        } else if (tabs.includes('status')) {
          let status = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        } else if (tabs.includes('subStatus')) {
          let substatus = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        } else if (tabs.includes('contacted')) {
          let contacted = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        } else if (tabs.includes('assigned')) {
          let assigned = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        } else {
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}${storequery}${typeQuery}&validation=1${multiStore}`,
            config
          );
        }
      }

      dispatch({
        type: GET_LEADS_BY_STORE,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Leads by User
  const getLeadsByUser = async (userId, pagination, tabs, query, typeQuery) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        if (tabs.includes('temperature')) {
          let temp = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&rating=${temp[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        } else if (tabs.includes('status')) {
          let status = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&status=${status[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        } else if (tabs.includes('subStatus')) {
          let substatus = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&substatus=${substatus[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        } else if (tabs.includes('contacted')) {
          let contacted = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&isContacted=${contacted[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        } else if (tabs.includes('assigned')) {
          let assigned = tabs.split('.');
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&assigned=${assigned[1]}&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        } else {
          res = await api.get(
            `/leads?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name-email-make-phone-agent-source-vehicle-store&searchText=${query}&agent=${userId}${typeQuery}&validation=1`,
            config
          );
        }
      }

      dispatch({
        type: GET_LEADS_BY_USER,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Single Item by ID
  const getLead = async leadId => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      if (leadId && String(leadId) !== 'undefined') {
        const res = await api.get(`/leads/carokou/${leadId}`, config);
        dispatch({
          type: GET_LEAD,
          payload: res.data.data
        });
      } else {
        clearState();
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update task from Lead
  const updateTaskFromLead = async taskId =>
    dispatch({ type: UPDATE_TASK_FROM_LEAD, payload: taskId });

  const uploadCsv = async data => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      await api.post(`/utils/uploadCsv`, data, config);
      dispatch({ type: LOAD_CORRECT_CSV });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const validateExcel = async data => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      let res = await api.post(`/utils/validateExcel`, data, config);
      dispatch({ type: LOAD_CSV, payload: res.data.errores });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getCreditBureau = async leadId => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(`/leads/credit/${leadId}`, config);
      dispatch({ type: UPDATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Lead
  const updateLead = async (lead, leadId) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(`/leads/${leadId}`, { ...lead }, config);
      dispatch({ type: UPDATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateFiles = async ({ files = [], event = 'add', leadId, index }) => {
    setLoading();
    try {
      let uploadConfig;
      let dataKeys = [];

      for (let i = 0; i < files.length; i++) {
        uploadConfig = await api.post('/uploads/publicImage', {
          type: files[i].type,
          fileName: files[i].name
        });
        await api.put(uploadConfig.data.url, files[i], {
          headers: { 'Content-Type': files[i] ? files[i].type : null }
        });
        dataKeys.push(
          `https://carokou.s3.us-east-2.amazonaws.com/${uploadConfig.data.key}`
        );
      }

      const res = await api.put(`/leads/evidences/${leadId}`, {
        images: dataKeys,
        event,
        index
      });
      dispatch({ type: UPDATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateLeadDocumentation = async (file, leadId) => {
    setLoading();
    try {
      let uploadConfig;
      let dataKeys = [];
      let res;

      for (let i = 0; i < file.length; i++) {
        uploadConfig = await api.post('/uploads/publicImage', {
          type: file[i].type,
          fileName: file[i].name
        });
        await api.put(uploadConfig.data.url, file[i], {
          headers: {
            headers: { 'Content-Type': file[i] ? file[i].type : null }
          }
        });
        dataKeys.push(uploadConfig.data.key);
      }

      res = await api.put(`/leads/documentation/${leadId}`, { data: dataKeys });
      dispatch({ type: UPDATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Lead
  const deleteLead = async leadId => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.delete(`/leads/${leadId}`, config);
      dispatch({ type: DELETE_LEAD, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const createMultipleContacts = async data => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        '/mailMarketing/multipleContacts',
        data,
        config
      );
      dispatch({ type: ASSIGN_LIST, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Lead
  const createLead = async lead => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/leads`, { ...lead }, config);
      dispatch({ type: CREATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const assignAgents = async (leads, agent, currentTab) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        `/leads/assignAgent`,
        { leads, agent },
        config
      );
      dispatch({
        type: ASSIGN_AGENTS,
        payload: res.data.data,
        tab: currentTab
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const assignStatus = async (
    leads,
    status,
    substatus,
    isContacted,
    currentTab
  ) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        `/leads/assignStatus`,
        { leads, status, substatus, isContacted },
        config
      );
      dispatch({
        type: ASSIGN_STATUSES,
        payload: res.data.data,
        tab: currentTab
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const AdvancedResults = async (pagination, query) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(
        `/leads?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&`,
        config
      );
      dispatch({
        type: GET_LEADS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const generateToken = async data => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post('/calls/generate', { data }, config);
      localStorage.setItem('callToken', res.data.token);
      dispatch({ type: CALL_USER, payload: res.data.token });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err });
    }
  };

  const makeCall = async phoneNumber => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    await api.post('/calls/connect', phoneNumber, config);
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  const addComment = comment => {
    if (comment && comment.action && comment.action.includes('sold')) return;
    dispatch({ type: ADD_COMMENT, payload: comment });
  };
  const addAppointment = appointment =>
    dispatch({ type: ADD_APPOINTMENT, payload: appointment });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <LeadContext.Provider
      value={{
        loading: state.loading,
        leads: state.leads,
        lead: state.lead,
        error: state.error,
        newS: state.newS,
        sold: state.sold,
        follow: state.follow,
        date: state.date,
        lastLeads: state.lastLeads,
        analytics: state.analytics,
        labels: state.labels,
        value: state.value,
        count: state.count,
        chart: state.chart,
        callToken: state.callToken,
        chartDash: state.chartDash,
        excelResponse: state.excelResponse,
        tokenUpdate: state.tokenUpdate,
        updateTaskFromLead,
        startCron,
        generateToken,
        getLeads,
        getLeadsV2,
        getLead,
        createMultipleContacts,
        updateLead,
        deleteLead,
        createLead,
        clearState,
        setLoading,
        validateExcel,
        getLeadsByStore,
        getLeadsByUser,
        makeCall,
        assignAgents,
        updateLeadDocumentation,
        uploadCsv,
        AdvancedResults,
        assignStatus,
        addComment,
        addAppointment,
        deleteCommentLead,
        sendURL,
        getTokenUpdate,
        getCreditBureau,
        updateFiles
      }}
    >
      {props.children}
    </LeadContext.Provider>
  );
};

export default LeadState;
