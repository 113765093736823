import React, { useReducer } from 'react';
import ConversationsContext from './dpxConversationsContext';
import ConversationsReducer from './dpxConversationsReducer';
import api from '../../api/api';

import {
  GET_CONVERSATIONS,
  CLEAR_STATE,
  CLEAR_MESSAGES,
  SET_ERROR,
  SEND_MESSAGE,
  CLEAR_ERROR,
  GET_CONVERSATION,
  MESSAGE_SOCKET,
  SET_FINAL,
  CONVERSATION_SOCKET,
  DELETE_CONVERSATION,
  CREATE_LEAD,
  UPDATE_CONVERSATION,
  CREATE_BDC,
  CREATE_SERVICE
} from '../types';

const ConversationsState = props => {
  const initialState = {
    conversation: {},
    conversations: [],
    chart: [],
    pagination: {},
    lastItems: null,
    messages: [],
    loading: false,
    error: null
  };

  const [state, dispatch] = useReducer(ConversationsReducer, initialState);

  const updateConversation = async (values, conversationId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(
        `/dpxConversations/${conversationId}`,
        { ...values },
        config
      );
      dispatch({ type: UPDATE_CONVERSATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const createService = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        '/dpxConversations/service',
        { ...values },
        config
      );
      dispatch({ type: CREATE_SERVICE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const createBDC = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        '/dpxConversations/bdc',
        { ...values },
        config
      );
      dispatch({ type: CREATE_BDC, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const createLead = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(
        '/dpxConversations/leads',
        { ...values },
        config
      );
      dispatch({ type: CREATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const deleteConversation = async conversationId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.delete(
        `/dpxConversations/${conversationId}`,
        config
      );
      dispatch({ type: DELETE_CONVERSATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const sendTemplate = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    await api.post(`/dpxConversations/template`, { ...values }, config);
  };

  const getConversations = async (values, reload) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/dpxConversations`, { ...values }, config);
      dispatch({
        type: GET_CONVERSATIONS,
        payload: res.data.data,
        pagination: res.data.pagination,
        reload,
        final: res.data.final
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getConversation = async conversationId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(`/dpxConversations/${conversationId}`, config);
      dispatch({ type: GET_CONVERSATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const addMessageSocket = (message, conversation) =>
    dispatch({ type: MESSAGE_SOCKET, payload: message, conversation });

  const addConversationSocket = conversation =>
    dispatch({ type: CONVERSATION_SOCKET, payload: conversation });

  const sendMessage = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      if (values.files) {
        let files = [...values.files];
        delete values.files;

        for (let i = 0; i < files.length; i++) {
          let response = await api.post(
            '/medias/images',
            { type: files[i].type, fileName: files[i].name },
            config
          );
          await api.put(response.data.url, files[i], {
            headers: { 'Content-Type': files[i].type }
          });

          const res = await api.post(
            `/dpxConversations/sendMessage`,
            {
              ...values,
              MediaUrl0: `${response.data.key}`,
              MediaContentType0: files[i].type
            },
            config
          );
          dispatch({ type: SEND_MESSAGE, payload: res.data.data });
        }
      } else if (values.documents) {
        let documents = [...values.documents];
        delete values.documents;

        for (let i = 0; i < documents.length; i++) {
          const arrayFile = documents[i].file.split('.');
          const type = arrayFile[arrayFile.length - 1];

          const res = await api.post(
            `/dpxConversations/sendMessage`,
            {
              ...values,
              MediaUrl0: `${process.env.AMAZONAWS_URL}/${documents[i].file}`,
              MediaContentType0: type
            },
            config
          );
          dispatch({ type: SEND_MESSAGE, payload: res.data.data });
        }
      } else {
        const res = await api.post(
          `/dpxConversations/sendMessage`,
          { ...values },
          config
        );
        dispatch({ type: SEND_MESSAGE, payload: res.data.data });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const setError = () => dispatch({ type: CLEAR_ERROR });
  const clearState = () => dispatch({ type: CLEAR_STATE });
  const clearMessages = () => dispatch({ type: CLEAR_MESSAGES });
  const setFinal = (value = false) =>
    dispatch({ type: SET_FINAL, payload: value });

  return (
    <ConversationsContext.Provider
      value={{
        conversations: state.conversations,
        pagination: state.pagination,
        loading: state.loading,
        error: state.error,
        chart: state.chart,
        conversation: state.conversation,
        lastItems: state.lastItems,
        messages: state.messages,
        final: state.final,
        addConversationSocket,
        setError,
        getConversations,
        deleteConversation,
        createLead,
        createBDC,
        createService,
        setFinal,
        updateConversation,
        getConversation,
        clearState,
        addMessageSocket,
        sendMessage,
        clearMessages,
        sendTemplate
      }}
    >
      {props.children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsState;
