import React, { useReducer } from 'react';
import ChartContext from './chartContext';
import ChartReducer from './chartReducer';
import api from '../../api/api';
import {
  GET_STORES_SCORES_TABLE,
  GET_STORES_EXTRA_SCORES_TABLE,
  GET_CAPTACION_MEDIOS,
  GET_GLOBAL_SOURCES,
  GET_GLOBAL,
  CLEAR_DRILL_TIME,
  GET_REPORT_SALES,
  GET_MONITORING_AGENT_SOURCES,
  GET_MONITORING_AGENT,
  GET_APPOINTMENTS_BY_STORE_CHART,
  GET_VISITS_BY_STORE_CHART,
  GET_SOLDS_BY_STORE_CHART,
  GET_MONTHLY_CHART,
  GET_BUREAU_CHART,
  GET_DAILY_CHART,
  GET_MODELS_CHART,
  GET_HOURS_CHART,
  SET_ERROR,
  GET_TOTALS_DASHBOARD,
  GET_TEMPERATURES_LEADS_TABLE,
  GET_TEMPERATURES_APPOINTMENTS_TABLE,
  GET_TEMPERATURES_VISITS_TABLE,
  GET_TEMPERATURES_SALES_TABLE,
  CLEAR_STATE,
  SET_LOADING,
  GET_LEADS_MONTHLY_CHART,
  GET_LEADS_STORE_CHART,
  GET_PROFIT_CHART,
  GET_TOTALS_FINANCING_DASHBOARD,
  GET_TOTALS_APPOINTMENTS_DASHBOARD,
  GET_TOTALS_VISITS_DASHBOARD,
  GET_PROFIT_STORE_CHART,
  GET_PROFIT_MODEL_CHART,
  GET_OPERATION_TYPE_CHART,
  GET_INVESTMENT_PROFIT_STORE_CHART,
  GET_TOTALS_INVESTMENT_DASHBOARD,
  GET_AGENTS_LEADS_TABLE,
  GET_AGENTS_CALLS_TABLE,
  GET_AGENTS_SOLDS_TABLE,
  GET_INVESTMENT_PROFIT_CHART,
  GET_AGENTS_VISITS_TABLE,
  GET_AGENTS_APPOINTMENTS_TABLE,
  GET_AGENTS_STATUS_CHART,
  GET_MODELS_BY_MAKE_CHART,
  GET_CONVERSATIONS_CHART,
  GET_TEMPERATURES_CHART,
  GET_MONTHLY_ADMIN_CHART,
  GET_WEEKLY_PROFIT_CHART,
  GET_WEEKLY_INVESTMENT_PROFIT_CHART,
  GET_VS_CHART,
  GET_TEMPERATURES_FUNNEL,
  GET_TEMPERATURES_FUNNEL_RSI,
  GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE,
  GET_STATUS_REPORT_CHART,
  GET_SUBSTATUS_AGENT_CHART,
  GET_VISITS_CHART,
  GET_APPOINTMENTS_CHART,
  GET_APPOINTMENTS_SUBSTATUS_CHART,
  GET_VISITS_SUBSTATUS_CHART,
  GET_VISITS_STORES_CHART,
  GET_VISITS_DETAILS_CHART,
  GET_APPOINTMENTS_STORES_CHART,
  GET_APPOINTMENTS_DETAILS_CHART,
  GET_CALLS_SUBSTATUS_CHART,
  GET_CALLS_STORES_CHART,
  GET_CALLS_MAKES_CHART,
  GET_CALLS_DETAILS_CHART,
  GET_DRILLDOWN_COMPARATIVE,
  GET_TOTALS_CALLS_DASHBOARD,
  GET_CLOSURE_TOP_USERS,
  GET_CONVERSATIONS_BY_AGENT,
  GET_CALLS_DRILLDOWN_CHART,
  GET_BUREAU_DRILLDOWN_CHART,
  GET_CONVERSATIONS_BY_AGENT_CHART,
  GET_AGENTS_CALLS_CHART,
  GET_PROFIT_SOURCE_CHART,
  GET_INVESTMENT_BY_STORE_CHART,
  GET_MESSAGES_CHART,
  GET_STATUS_STORES_CHART,
  GET_MONTHLY_COMPARATIVE_CHART,
  GET_UNIT_MONTHLY_COMPARATIVE_CHART,
  GET_INVESTMENTS_CHART,
  GET_STATUS_STORES_CHART_ACUMULATED,
  GET_TOTAL_TEMPERATURES,
  GET_FUNNEL_APPOINTMENT_CHART,
  GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE,
  GET_FUNNEL_DATOS,
  GET_DRILLDOWN_FUNNEL_SOURCE,
  GET_HOURS_COMPARATIVE_CHART,
  GET_DRILLDOWN_TIME,
  GET_RECORDINGS_REVIEWS,
  GET_AGENTS_RECORDINGS,
  CLEAR_RECORDINGS_TABLE,
  GET_RECORDINGS_DRILLDOWN_CHART,
  GET_PENDING_TASKS,
  GET_REPORT_VISITS,
  GET_MATRIX,
  GET_MATRIX_INVESTMENT,
  GET_MATRIX_SEGMENTATION,
  GET_CREDIT_REQUEST_TABLE,
  GET_TABLEMODELS,
  GET_AGENTS_COMPARATIVE
} from '../types';

const ChartState = props => {
  const initialState = {
    creditRequestTable: [],
    agentsRecordings: [],
    captacionMedios: [],
    globalSources: [],
    global: [],
    drilldownTime: [],
    drilldownComparative: [],
    reportSales: [],
    funnelDatos: [],
    monitoringAgent: [],
    monitoringAgentSources: {},
    drilldownCalls: [],
    drilldownBureau: [],
    drilldownRecordings: [],
    drilldownFunnelSource: [],
    leadsByStore: [],
    appointmentsByStore: [],
    visitsByStore: [],
    soldsByStore: [],
    investmentsByStore: [],
    monthly: [],
    daily: [],
    models: [],
    hours: [],
    linearStores: [],
    matrixInvestment: [],
    pieStatus: [],
    leadsStoreChart: [],
    leadsMonthlyChart: [],
    profitChart: [],
    investmentProfitChart: [],
    operationTypeChart: [],
    temperaturesLeadsTable: [],
    temperaturesAppointmentsTable: [],
    temperaturesVisitsTable: [],
    temperaturesSalesTable: [],
    agentsLeadsTable: [],
    agentsCallsTable: [],
    storesExtraScoresTable: [],
    storesScoresTable: [],
    agentsCallsChart: [],
    totalTemperatures: [],
    agentsVisitsTable: [],
    agentsAppointmentsTable: [],
    agentsSoldsTable: [],
    agentsStatusChart: [],
    modelsByMakeChart: {},
    conversationsChart: [],
    messagesChart: [],
    temperaturesChart: [],
    investmentsChart: [],
    monthlyAdminChart: [],
    monthlyComparativeChart: [],
    unitMonthlyComparativeChart: [],
    substatusAgentChart: [],
    vsChart: [],
    agentsComparative: [],
    temperaturesFunnel: [],
    temperaturesFunnelRSI: [],
    temperaturesFunnelRSIComparative: [[], []],
    temperaturesFunnelAppointmentComparative: [[], []],
    statusReportChart: [],
    appointmentsChart: [],
    hoursComparativeChart: [],
    appointmentsStoresChart: [],
    appointmentsDetailsChart: [],
    visitsChart: [],
    weeklyProfitChart: [],
    weeklyInvestmentProfitChart: [],
    appointmentsSubstatusChart: [],
    total: null,
    totalLeads: null,
    totalVisits: null,
    totalAppointments: null,
    totalSolds: null,
    totalNew: null,
    totalFollowUp: null,
    totalCallAgain: null,
    totalWrongnumber: null,
    totalRejected: null,
    totalDocumentation: null,
    totalConfirm: null,
    totalConfirmed: null,
    totalVisitTracking: null,
    totalReschedule: null,
    totalClientNa: null,
    totalVisited: null,
    totalApplication: null,
    totalApprovedApplication: null,
    totalCondicionedApplication: null,
    totalRejectedApplication: null,
    totalSeparation: null,
    loadingCharts: false,
    totalFinancing: null,
    totalInvestment: null,
    totalProfits: null,
    totalProfitsInvestment: null,
    profitStoreChart: null,
    investmentProfitStoreChart: null,
    profitModelChart: null,
    profitSourceChart: null,
    totalAppointmentsDash: null,
    totalVisitsDash: null,
    visitsSubstatusChart: [],
    visitsStoresChart: [],
    visitsDetailsChart: [],
    totalCallsDash: null,
    callsSubstatusChart: [],
    callsStoresChart: [],
    callsDetailsChart: [],
    callsMakesChart: [],
    closureTopUsers: [],
    conversationsByAgent: [],
    conversationsByAgentChart: [],
    statusStoresChart: [],
    statusStoresChartAcumulated: [],
    funnelAppointment: [],
    recordingsReviews: [],
    pendingTasksReport: [],
    bureauChart: [],
    getVisitsReport: [],
    tableModels: [],
    error: null,
    matrix: {
      clicks: 0,
      impressions: 0,
      cpm: 0,
      leads: 0,
      spend: 0,
      costPerLead: 0
    },
    matrixCampaigns: [],
    matrixSegmentation: {}
  };

  const [state, dispatch] = useReducer(ChartReducer, initialState);

  const getTableModels = async initialQuery => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/charts/tableModels`, initialQuery, config);
      dispatch({ type: GET_TABLEMODELS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getPendingTasksReport = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(
        `/charts/pendingTasksReport`,
        { ...values },
        config
      );
      dispatch({ type: GET_PENDING_TASKS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getMonitoringAgent = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/monitoringAgent?${query}`, config);
      dispatch({ type: GET_MONITORING_AGENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getMonitoringAgentSources = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/monitoringAgentSources?${query}`,
        config
      );
      dispatch({ type: GET_MONITORING_AGENT_SOURCES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getTemperaturesLeadsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesLeadsTable?${query}`,
        config
      );
      dispatch({ type: GET_TEMPERATURES_LEADS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getTemperaturesAppointmentsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesAppointmentsTable?${query}`,
        config
      );
      dispatch({
        type: GET_TEMPERATURES_APPOINTMENTS_TABLE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getTemperaturesVisitsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesVisitsTable?${query}`,
        config
      );
      dispatch({ type: GET_TEMPERATURES_VISITS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getTemperaturesSalesTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesSalesTable?${query}`,
        config
      );
      dispatch({ type: GET_TEMPERATURES_SALES_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getConversationsByAgent = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/conversationsByAgent?${query}`,
        config
      );
      dispatch({ type: GET_CONVERSATIONS_BY_AGENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getConversationsByAgentChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/conversationsByAgentChart?${query}`,
        config
      );
      dispatch({
        type: GET_CONVERSATIONS_BY_AGENT_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getClosureTopUsers = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/closureTopUsers?${query}`, config);
      dispatch({ type: GET_CLOSURE_TOP_USERS, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Visits Report
  const getAppointmentsSubstatusChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/appointmentsSubstatusChart?${query}`,
        config
      );
      dispatch({
        type: GET_APPOINTMENTS_SUBSTATUS_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getAppointmentsStoresChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/appointmentsStoresChart?${query}`,
        config
      );
      dispatch({ type: GET_APPOINTMENTS_STORES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getAppointmentsDetailsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/appointmentsDetailsChart?${query}`,
        config
      );
      dispatch({
        type: GET_APPOINTMENTS_DETAILS_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Visits Report
  const getVisitsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/visitsChart?${query}`, config);
      dispatch({ type: GET_VISITS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Appointments Report
  const getAppointmentsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/appointmentsChart?${query}`, config);
      dispatch({ type: GET_APPOINTMENTS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Appointments Report
  const getHoursComparativeChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/hoursComparativeChart?${query}`,
        config
      );
      dispatch({ type: GET_HOURS_COMPARATIVE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Substatus Report
  const getSubstatusAgentChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/substatusAgentChart?${query}`, config);
      dispatch({ type: GET_SUBSTATUS_AGENT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Substatus Report
  const getStatusStoresChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/statusStoresChart?${query}`, config);
      dispatch({ type: GET_STATUS_STORES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getStatusStoresChartAcumulated = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();

    try {
      const res = await api.get(`/charts/statusStoresChart?${query}`, config);
      dispatch({
        type: GET_STATUS_STORES_CHART_ACUMULATED,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };
  //Get Status Report
  const getStatusReportChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/statusReportChart?${query}`, config);
      dispatch({ type: GET_STATUS_REPORT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getTemperaturesFunnel = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/temperaturesFunnel?${query}`, config);
      dispatch({ type: GET_TEMPERATURES_FUNNEL, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getTemperaturesFunnelRSI = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesFunnelRSI?${query}`,
        config
      );
      dispatch({ type: GET_TEMPERATURES_FUNNEL_RSI, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getFunnelAppointment = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/funnelAppointment?${query}`, config);
      dispatch({ type: GET_FUNNEL_APPOINTMENT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getFunnelDatos = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/funnelDatos?${query}`, config);
      dispatch({ type: GET_FUNNEL_DATOS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getTemperaturesFunnelAppointmentComparative = async (
    query,
    position
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/funnelAppointment?${query}`, config);
      dispatch({
        type: GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE,
        payload: res.data.data,
        position
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getTemperaturesFunnelRSIComparative = async (query, position) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/temperaturesFunnelRSI?${query}`,
        config
      );
      dispatch({
        type: GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE,
        payload: res.data.data,
        position
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };
  //Get drilldownTime Chart
  const getDrilldownTime = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/drilldownTime?${query}`, config);
      dispatch({ type: GET_DRILLDOWN_TIME, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const clearDrilldownTime = async () => dispatch({ type: CLEAR_DRILL_TIME });

  //Get Temperatures Chart
  const getTotalTemperatures = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/totalTemperatures?${query}`, config);
      dispatch({ type: GET_TOTAL_TEMPERATURES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Vs Chart
  const getVsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/vsChart?${query}`, config);
      dispatch({ type: GET_VS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getMonthlyAdminChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/monthlyAdminChart?${query}`, config);
      dispatch({ type: GET_MONTHLY_ADMIN_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Score Chart
  const getBureauChart = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/charts/bureauChart`, { ...values }, config);
      dispatch({ type: GET_BUREAU_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get comparative Chart
  const getMonthlyComparativeChart = async (query, data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(
        `/charts/unitComparativeChart?${query}`,
        data,
        config
      );
      dispatch({ type: GET_MONTHLY_COMPARATIVE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getUnitComparativeChart = async (query, data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(
        `/charts/unitComparativeChart?${query}`,
        data,
        config
      );
      dispatch({
        type: GET_UNIT_MONTHLY_COMPARATIVE_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getUnitComparativeChartGrouped = async (query, data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(
        `/charts/unitComparativeChartGrouped?${query}`,
        data,
        config
      );
      dispatch({
        type: GET_UNIT_MONTHLY_COMPARATIVE_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Temperatures Chart
  const getTemperaturesChart = async (endpoint, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/globalSumary?${query}${endpoint}`,
        config
      );
      dispatch({ type: GET_TEMPERATURES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Global Chart
  const getGlobal = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/global?${query}`, config);
      dispatch({ type: GET_GLOBAL, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get global sources Chart
  const getGlobalSources = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/globalSources?${query}`, config);
      dispatch({ type: GET_GLOBAL_SOURCES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get global sources Chart
  const getCaptacionMedios = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/captacionMedios?${query}`, config);
      dispatch({ type: GET_CAPTACION_MEDIOS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getInvestmentsChart = async (endpoint, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearCharts();
    setLoading();
    try {
      const res = await api.get(
        `/charts/investmentsChartLead?leadStatus=${endpoint.toLowerCase()}s`,
        config
      );
      dispatch({ type: GET_INVESTMENTS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Conversations Chart
  const getConversationsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/conversationsChart?${query}`, config);
      dispatch({ type: GET_CONVERSATIONS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getMessagesChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/messagesChart?${query}`, config);
      dispatch({ type: GET_MESSAGES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Make - Models Chart
  const getModelsByMakeChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/modelsByMakeChart?${query}`, config);
      dispatch({ type: GET_MODELS_BY_MAKE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Status Chart
  const getAgentsStatusChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/agentsStatusChart?${query}`, config);
      dispatch({ type: GET_AGENTS_STATUS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Recordings reviews Chart
  const getRecordingsReviews = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/recordingsReviews?${query}`, config);
      dispatch({ type: GET_RECORDINGS_REVIEWS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Sold Table
  const getAgentsSoldsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(`/charts/agentsSoldsTable?${query}`, config);
      dispatch({ type: GET_AGENTS_SOLDS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Visit Table
  const getAgentsVisitsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(`/charts/agentsVisitsTable?${query}`, config);
      dispatch({ type: GET_AGENTS_VISITS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Visit Table
  const getAgentsCallsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/agentsCallsChart?${query}`, config);
      dispatch({ type: GET_AGENTS_CALLS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Visit Table
  const getAgentsCallsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/agentsCallsTable?${query}`, config);
      dispatch({ type: GET_AGENTS_CALLS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getStoresExtraScoresTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/storesExtraScoresTable?${query}`,
        config
      );
      dispatch({ type: GET_STORES_EXTRA_SCORES_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getStoresScoresTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/storesScoresTable?${query}`, config);
      dispatch({ type: GET_STORES_SCORES_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Visit Table
  const getVisitsReport = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/charts/reportVisits`, { ...values }, config);
      dispatch({ type: GET_REPORT_VISITS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Appointment Table
  const getAgentsAppointmentsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    // setLoading();
    try {
      const res = await api.get(
        `/charts/agentsAppointmentsTable?${query}`,
        config
      );
      dispatch({ type: GET_AGENTS_APPOINTMENTS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Lead Table
  const getAgentsLeadsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/agentsLeadsTable?${query}`, config);
      dispatch({ type: GET_AGENTS_LEADS_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const clearRecordingsTable = async () =>
    dispatch({ type: CLEAR_RECORDINGS_TABLE });

  //Get Agents Recordings Table
  const getAgentsRecordingsTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/agentsRecordingsTable?${query}`,
        config
      );
      dispatch({ type: GET_AGENTS_RECORDINGS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Operation Type Chart
  const getOperationTypeChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/operationTypeChart?${query}`, config);
      dispatch({ type: GET_OPERATION_TYPE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getProfitModelChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/profitModelChart?${query}`, config);
      dispatch({ type: GET_PROFIT_MODEL_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getProfitSourceChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/profitSourceChart?${query}`, config);
      dispatch({ type: GET_PROFIT_SOURCE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getProfitStoreChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/profitStoreChart?${query}`, config);
      dispatch({ type: GET_PROFIT_STORE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getInvestmentProfitStoreChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/investmentProfitStoreChart?${query}`,
        config
      );
      dispatch({
        type: GET_INVESTMENT_PROFIT_STORE_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getTotalsInvestmentDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/totalsInvestmentDashboard?${query}`,
        config
      );
      dispatch({
        type: GET_TOTALS_INVESTMENT_DASHBOARD,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getTotalsFinancingDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/totalsFinancingDashboard?${query}`,
        config
      );
      dispatch({
        type: GET_TOTALS_FINANCING_DASHBOARD,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Appointment Chart
  const getTotalsAppointmentsDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/totalsAppointmentsDashboard?${query}`,
        config
      );
      dispatch({
        type: GET_TOTALS_APPOINTMENTS_DASHBOARD,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  // ? visit charts dash
  const getTotalsVisitsDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/totalsVisitsDashboard?${query}`,
        config
      );
      dispatch({ type: GET_TOTALS_VISITS_DASHBOARD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getVisitsSubstatusChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/visitsSubstatusChart?${query}`,
        config
      );
      dispatch({ type: GET_VISITS_SUBSTATUS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getVisitsStoresChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/visitsStoresChart?${query}`, config);
      dispatch({ type: GET_VISITS_STORES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getVisitsDetailsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/visitsDetailsChart?${query}`, config);
      dispatch({ type: GET_VISITS_DETAILS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  // ? calls charts dash
  const getTotalsCallsDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/totalsCallsDashboard?${query}`,
        config
      );
      dispatch({ type: GET_TOTALS_CALLS_DASHBOARD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getCallsSubstatusChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/CallsSubstatusChart?${query}`, config);
      dispatch({ type: GET_CALLS_SUBSTATUS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getCallsStoresChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/CallsStoresChart?${query}`, config);
      dispatch({ type: GET_CALLS_STORES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getDrilldownCalls = async (endpoint, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/${endpoint}?${query}`, config);
      dispatch({ type: GET_CALLS_DRILLDOWN_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getDrilldownBureau = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/drillDownBureau?${query}`, config);
      dispatch({ type: GET_BUREAU_DRILLDOWN_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getDrilldownRecordings = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/drilldownRecordings?${query}`, config);
      dispatch({
        type: GET_RECORDINGS_DRILLDOWN_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getDrilldownFunnelSource = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/drilldownFunnelSource?${query}`,
        config
      );
      dispatch({ type: GET_DRILLDOWN_FUNNEL_SOURCE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getCallsDetailsChart = async query => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(`/charts/CallsDetailsChart?${query}`, config);
      dispatch({ type: GET_CALLS_DETAILS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getCallsMakesChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/CallsMakesChart?${query}`, config);
      dispatch({ type: GET_CALLS_MAKES_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getProfitChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/profitChart?${query}`, config);
      dispatch({ type: GET_PROFIT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getInvestmentProfitChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/investmentProfitChart?${query}`,
        config
      );
      dispatch({ type: GET_INVESTMENT_PROFIT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getWeeklyInvestmentProfitChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/weeklyInvestmentProfitChart?${query}`,
        config
      );
      dispatch({
        type: GET_WEEKLY_INVESTMENT_PROFIT_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Profit Chart
  const getWeeklyProfitChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/weeklyProfitChart?${query}`, config);
      dispatch({ type: GET_WEEKLY_PROFIT_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Monthly Leads
  const getLeadsMonthlyChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/leadsMonthlyChart?${query}&detail=true`,
        config
      );
      dispatch({ type: GET_LEADS_MONTHLY_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Leads by Store Chart
  const getLeadsStoreChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/leadsStoreChart?${query}`, config);
      dispatch({ type: GET_LEADS_STORE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Totals Dashboard
  const getTotalsDashboard = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/getTotalsDashboard?${query}`, config);
      dispatch({ type: GET_TOTALS_DASHBOARD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Models Chart
  const getHoursChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/hoursChart?${query}`, config);
      dispatch({ type: GET_HOURS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Models Chart
  const getModelsChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/modelsChart?${query}`, config);
      dispatch({ type: GET_MODELS_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Daily Chart
  const getDailyChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/dailyChart?${query}`, config);
      dispatch({ type: GET_DAILY_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Monthly Chart
  const getMonthlyChart = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/monthlyChart?${query}`, config);
      dispatch({ type: GET_MONTHLY_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Total Appointments
  const getAppointmentsByStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/statusAppointmentChart?${query}`,
        config
      );
      dispatch({
        type: GET_APPOINTMENTS_BY_STORE_CHART,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Total Visits
  const getVisitsByStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/statusVisitChart?${query}`, config);
      dispatch({ type: GET_VISITS_BY_STORE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Total Solds
  const getInvestmentsByStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/statusInvestmentChart?${query}`,
        config
      );
      dispatch({ type: GET_INVESTMENT_BY_STORE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Total Solds
  const getSoldsByStore = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/statusSoldChart?${query}`, config);
      dispatch({ type: GET_SOLDS_BY_STORE_CHART, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get report sales
  const getReportSales = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/reportSales?${query}`, config);
      dispatch({ type: GET_REPORT_SALES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get report sales
  const getDrilldownComparative = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/charts/drilldownComparative?${query}`,
        config
      );
      dispatch({ type: GET_DRILLDOWN_COMPARATIVE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Matrix
  const getMatrix = async ({ account, date }) => {
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      try {
        setLoading();
        const res = await api.post(`/charts/matrix`, { account, date }, config);
        dispatch({
          type: GET_MATRIX,
          payload: { data: res.data.matrix, campaigns: res.data.campaigns }
        });
      } catch (err) {
        dispatch({ type: SET_ERROR, payload: err.response.data.error });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Matrix
  const getMatrixInvestment = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      setLoading();
      const res = await api.post(
        `/charts/matrixInvestment`,
        { ...values },
        config
      );
      dispatch({ type: GET_MATRIX_INVESTMENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Matrix
  const getMatrixSegmentation = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      setLoading();
      const res = await api.post(
        `/charts/matrixSegmentation`,
        { ...values },
        config
      );
      dispatch({ type: GET_MATRIX_SEGMENTATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getCreditRequestTable = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/charts/creditRequestTable`, query, config);
      dispatch({ type: GET_CREDIT_REQUEST_TABLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Agents - Substatus Lead Table
  const getAgentsComparative = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/charts/agentsComparative?${query}`, config);
      dispatch({ type: GET_AGENTS_COMPARATIVE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearCharts = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <ChartContext.Provider
      value={{
        loadingCharts: state.loadingCharts,
        error: state.error,
        leadsByStore: state.leadsByStore,
        appointmentsByStore: state.appointmentsByStore,
        visitsByStore: state.visitsByStore,
        soldsByStore: state.soldsByStore,
        investmentsByStore: state.investmentsByStore,

        getAppointmentsByStore,
        getVisitsByStore,
        getSoldsByStore,
        getInvestmentsByStore,

        getMonthlyChart,
        monthly: state.monthly,

        getDailyChart,
        daily: state.daily,

        getModelsChart,
        models: state.models,

        getHoursChart,
        hours: state.hours,

        linearStores: state.linearStores,

        pieStatus: state.pieStatus,

        getTotalsDashboard,
        totalLeads: state.totalLeads,
        totalVisits: state.totalVisits,
        totalAppointments: state.totalAppointments,
        totalSolds: state.totalSolds,
        total: state.total,
        totalNew: state.totalNew,
        totalFollowUp: state.totalFollowUp,
        totalCallAgain: state.totalCallAgain,
        totalWrongnumber: state.totalWrongnumber,
        totalRejected: state.totalRejected,
        totalDocumentation: state.totalDocumentation,
        totalConfirm: state.totalConfirm,
        totalConfirmed: state.totalConfirmed,
        totalVisitTracking: state.totalVisitTracking,
        totalReschedule: state.totalReschedule,
        totalClientNa: state.totalClientNa,
        totalVisited: state.totalVisited,
        totalApplication: state.totalApplication,
        totalApprovedApplication: state.totalApprovedApplication,
        totalCondicionedApplication: state.totalCondicionedApplication,
        totalRejectedApplication: state.totalRejectedApplication,
        totalSeparation: state.totalSeparation,

        getLeadsMonthlyChart,
        leadsMonthlyChart: state.leadsMonthlyChart,

        getLeadsStoreChart,
        leadsStoreChart: state.leadsStoreChart,

        getProfitChart,
        profitChart: state.profitChart,

        getInvestmentProfitChart,
        investmentProfitChart: state.investmentProfitChart,

        getWeeklyProfitChart,
        weeklyProfitChart: state.weeklyProfitChart,

        getWeeklyInvestmentProfitChart,
        weeklyInvestmentProfitChart: state.weeklyInvestmentProfitChart,

        getTotalsFinancingDashboard,
        totalFinancing: state.totalFinancing,
        totalProfits: state.totalProfits,

        getTotalsInvestmentDashboard,
        totalInvestment: state.totalInvestment,
        totalProfitsInvestment: state.totalProfitsInvestment,

        getProfitStoreChart,
        profitStoreChart: state.profitStoreChart,

        getInvestmentProfitStoreChart,
        investmentProfitStoreChart: state.investmentProfitStoreChart,

        getProfitModelChart,
        profitModelChart: state.profitModelChart,

        getProfitSourceChart,
        profitSourceChart: state.profitSourceChart,

        getOperationTypeChart,
        operationTypeChart: state.operationTypeChart,

        getConversationsByAgentChart,
        conversationsByAgentChart: state.conversationsByAgentChart,

        getAgentsLeadsTable,
        agentsLeadsTable: state.agentsLeadsTable,

        getAgentsVisitsTable,
        agentsVisitsTable: state.agentsVisitsTable,

        getAgentsCallsChart,
        agentsCallsChart: state.agentsCallsChart,

        getAgentsCallsTable,
        agentsCallsTable: state.agentsCallsTable,

        getStoresExtraScoresTable,
        storesExtraScoresTable: state.storesExtraScoresTable,

        getStoresScoresTable,
        storesScoresTable: state.storesScoresTable,

        getAgentsAppointmentsTable,
        agentsAppointmentsTable: state.agentsAppointmentsTable,

        getAgentsSoldsTable,
        agentsSoldsTable: state.agentsSoldsTable,

        getAgentsStatusChart,
        agentsStatusChart: state.agentsStatusChart,

        getModelsByMakeChart,
        modelsByMakeChart: state.modelsByMakeChart,

        getConversationsChart,
        conversationsChart: state.conversationsChart,

        getMessagesChart,
        messagesChart: state.messagesChart,

        getTemperaturesChart,
        temperaturesChart: state.temperaturesChart,

        getInvestmentsChart,
        investmentsChart: state.investmentsChart,

        getMonthlyAdminChart,
        monthlyAdminChart: state.monthlyAdminChart,

        getMonthlyComparativeChart,
        monthlyComparativeChart: state.monthlyComparativeChart,

        getUnitComparativeChart,
        getUnitComparativeChartGrouped,
        unitMonthlyComparativeChart: state.unitMonthlyComparativeChart,

        getTemperaturesFunnel,
        temperaturesFunnel: state.temperaturesFunnel,

        getTemperaturesFunnelRSI,
        temperaturesFunnelRSI: state.temperaturesFunnelRSI,

        getFunnelAppointment,
        funnelAppointment: state.funnelAppointment,

        getStatusReportChart,
        statusReportChart: state.statusReportChart,

        getSubstatusAgentChart,
        substatusAgentChart: state.substatusAgentChart,

        getVisitsChart,
        visitsChart: state.visitsChart,

        getAppointmentsChart,
        appointmentsChart: state.appointmentsChart,

        getHoursComparativeChart,
        hoursComparativeChart: state.hoursComparativeChart,

        getAppointmentsSubstatusChart,
        appointmentsSubstatusChart: state.appointmentsSubstatusChart,

        getAppointmentsStoresChart,
        appointmentsStoresChart: state.appointmentsStoresChart,

        getAppointmentsDetailsChart,
        appointmentsDetailsChart: state.appointmentsDetailsChart,

        getVsChart,
        vsChart: state.vsChart,

        getTotalsAppointmentsDashboard,
        totalAppointmentsDash: state.totalAppointmentsDash,

        getTotalsVisitsDashboard,
        totalVisitsDash: state.totalVisitsDash,

        getVisitsSubstatusChart,
        visitsSubstatusChart: state.visitsSubstatusChart,

        getVisitsStoresChart,
        visitsStoresChart: state.visitsStoresChart,

        getVisitsDetailsChart,
        visitsDetailsChart: state.visitsDetailsChart,

        getTotalsCallsDashboard,
        totalCallsDash: state.totalCallsDash,

        getCallsSubstatusChart,
        callsSubstatusChart: state.callsSubstatusChart,

        getCallsStoresChart,
        callsStoresChart: state.callsStoresChart,

        getCallsDetailsChart,
        callsDetailsChart: state.callsDetailsChart,

        getCallsMakesChart,
        callsMakesChart: state.callsMakesChart,

        getClosureTopUsers,
        closureTopUsers: state.closureTopUsers,

        getConversationsByAgent,
        conversationsByAgent: state.conversationsByAgent,

        getDrilldownCalls,
        drilldownCalls: state.drilldownCalls,

        getDrilldownBureau,
        drilldownBureau: state.drilldownBureau,

        getDrilldownRecordings,
        drilldownRecordings: state.drilldownRecordings,

        getDrilldownFunnelSource,
        drilldownFunnelSource: state.drilldownFunnelSource,

        getStatusStoresChart,
        statusStoresChart: state.statusStoresChart,

        getStatusStoresChartAcumulated,
        statusStoresChartAcumulated: state.statusStoresChartAcumulated,

        getTemperaturesLeadsTable,
        temperaturesLeadsTable: state.temperaturesLeadsTable,

        getTemperaturesAppointmentsTable,
        temperaturesAppointmentsTable: state.temperaturesAppointmentsTable,

        getTemperaturesVisitsTable,
        temperaturesVisitsTable: state.temperaturesVisitsTable,

        getTemperaturesSalesTable,
        temperaturesSalesTable: state.temperaturesSalesTable,

        getMonitoringAgent,
        monitoringAgent: state.monitoringAgent,

        getMonitoringAgentSources,
        monitoringAgentSources: state.monitoringAgentSources,

        getTotalTemperatures,
        totalTemperatures: state.totalTemperatures,

        getTemperaturesFunnelRSIComparative,
        temperaturesFunnelRSIComparative:
          state.temperaturesFunnelRSIComparative,

        getTemperaturesFunnelAppointmentComparative,
        temperaturesFunnelAppointmentComparative:
          state.temperaturesFunnelAppointmentComparative,

        getFunnelDatos,
        funnelDatos: state.funnelDatos,

        getReportSales,
        reportSales: state.reportSales,

        getDrilldownComparative,
        drilldownComparative: state.drilldownComparative,

        getDrilldownTime,
        clearDrilldownTime,
        drilldownTime: state.drilldownTime,

        getGlobal,
        global: state.global,

        getGlobalSources,
        globalSources: state.globalSources,

        getCaptacionMedios,
        captacionMedios: state.captacionMedios,

        getRecordingsReviews,
        recordingsReviews: state.recordingsReviews,

        getAgentsRecordingsTable,
        agentsRecordingsTable: state.agentsRecordingsTable,

        getPendingTasksReport,
        pendingTasksReport: state.pendingTasksReport,

        getTableModels,
        tableModels: state.tableModels,

        getBureauChart,
        bureauChart: state.bureauChart,

        getVisitsReport,
        visitsReport: state.visitsReport,

        getMatrix,
        matrix: state.matrix,
        matrixCampaigns: state.matrixCampaigns,

        getMatrixInvestment,
        matrixInvestment: state.matrixInvestment,

        getMatrixSegmentation,
        matrixSegmentation: state.matrixSegmentation,

        getCreditRequestTable,
        creditRequestTable: state.creditRequestTable,

        getAgentsComparative,
        agentsComparative: state.agentsComparative,

        clearRecordingsTable,

        clearCharts,
        setLoading
      }}
    >
      {props.children}
    </ChartContext.Provider>
  );
};

export default ChartState;
