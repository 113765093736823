import React, { useReducer } from 'react';
import TemplateTwilioContext from './templateTwilioContext';
import TemplateTwilioReducer from './templateTwilioReducer';
import api from '../../api/api';
import {
  GET_TEMPLATESTWILIO,
  GET_TEMPLATESTWILIO_BY_STORE,
  CREATE_TEMPLATETWILIO,
  GET_TEMPLATETWILIO,
  DELETE_TEMPLATETWILIO,
  UPDATE_TEMPLATETWILIO,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const TemplateTwilioState = props => {
  const initialState = {
    templatesTwilio: [],
    templateTwilio: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(TemplateTwilioReducer, initialState);

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/templatesTwilio/templatesTwilioAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
        config
      );
      dispatch({
        type: GET_TEMPLATESTWILIO,
        payload: res.data.data,
        count: res.data.count
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get TemplatesTwilio
  const getTemplatesTwilio = async (pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/templatesTwilio/templatesTwilioAr?page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
        config
      );
      dispatch({
        type: GET_TEMPLATESTWILIO,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get TemplatesTwilio by store
  const getTemplatesTwilioByStore = async (
    storesId,
    typeLead = 'lead',
    query = ''
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/templatesTwilio/templatesTwilioAr?multiStores=${storesId}&typeLead=${typeLead}&isActive=true${query}`,
        config
      );

      dispatch({
        type: GET_TEMPLATESTWILIO_BY_STORE,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get TemplateTwilio
  const getTemplateTwilio = async templateTwilioId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/templatesTwilio/${templateTwilioId}`, config);
      dispatch({ type: GET_TEMPLATETWILIO, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete TemplateTwilio
  const deleteTemplateTwilio = async templateTwilioId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(
        `/templatesTwilio/${templateTwilioId}`,
        config
      );
      dispatch({ type: DELETE_TEMPLATETWILIO, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create TemplateTwilio
  const createTemplateTwilio = async templateTwilio => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(
        `/templatesTwilio`,
        { ...templateTwilio },
        config
      );
      dispatch({ type: CREATE_TEMPLATETWILIO, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update TemplateTwilio
  const updateTemplateTwilio = async (templateTwilio, templateTwilioId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(
        `/templatesTwilio/${templateTwilioId}`,
        { ...templateTwilio },
        config
      );
      dispatch({ type: UPDATE_TEMPLATETWILIO, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <TemplateTwilioContext.Provider
      value={{
        loading: state.loading,
        templatesTwilio: state.templatesTwilio,
        templateTwilio: state.templateTwilio,
        error: state.error,
        count: state.count,
        getTemplatesTwilio,
        createTemplateTwilio,
        getTemplateTwilio,
        deleteTemplateTwilio,
        updateTemplateTwilio,
        clearState,
        setLoading,
        getTemplatesTwilioByStore,
        AdvancedResults
      }}
    >
      {props.children}
    </TemplateTwilioContext.Provider>
  );
};

export default TemplateTwilioState;
