import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_APPOINTMENTS_BY_STORE_CHART,
  GET_VISITS_BY_STORE_CHART,
  GET_SOLDS_BY_STORE_CHART,
  GET_MONTHLY_CHART,
  GET_MODELS_CHART,
  GET_DAILY_CHART,
  GET_HOURS_CHART,
  GET_PIE_STATUS_CHART,
  GET_TOTALS_DASHBOARD,
  GET_LEADS_STORE_CHART,
  GET_LEADS_MONTHLY_CHART,
  GET_TEMPERATURES_LEADS_TABLE,
  GET_TEMPERATURES_APPOINTMENTS_TABLE,
  GET_TEMPERATURES_VISITS_TABLE,
  GET_BUREAU_CHART,
  GET_TEMPERATURES_SALES_TABLE,
  GET_RECORDINGS_REVIEWS,
  GET_PROFIT_CHART,
  GET_INVESTMENT_BY_STORE_CHART,
  GET_TEMPERATURES_FUNNEL_RSI,
  GET_DRILLDOWN_COMPARATIVE,
  GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE,
  GET_TOTALS_FINANCING_DASHBOARD,
  GET_TOTALS_INVESTMENT_DASHBOARD,
  GET_INVESTMENT_PROFIT_STORE_CHART,
  GET_INVESTMENT_PROFIT_CHART,
  GET_WEEKLY_PROFIT_CHART,
  GET_WEEKLY_INVESTMENT_PROFIT_CHART,
  GET_PROFIT_STORE_CHART,
  GET_PROFIT_MODEL_CHART,
  GET_OPERATION_TYPE_CHART,
  GET_AGENTS_LEADS_TABLE,
  GET_AGENTS_SOLDS_TABLE,
  GET_AGENTS_VISITS_TABLE,
  GET_GLOBAL,
  GET_AGENTS_CALLS_TABLE,
  GET_MONITORING_AGENT_SOURCES,
  GET_AGENTS_APPOINTMENTS_TABLE,
  GET_PROFIT_SOURCE_CHART,
  GET_MODELS_BY_MAKE_CHART,
  GET_CONVERSATIONS_CHART,
  GET_MESSAGES_CHART,
  GET_TEMPERATURES_CHART,
  GET_MONTHLY_ADMIN_CHART,
  GET_MONTHLY_COMPARATIVE_CHART,
  GET_UNIT_MONTHLY_COMPARATIVE_CHART,
  GET_VS_CHART,
  GET_AGENTS_STATUS_CHART,
  GET_TEMPERATURES_FUNNEL,
  GET_REPORT_SALES,
  GET_STATUS_REPORT_CHART,
  GET_SUBSTATUS_AGENT_CHART,
  GET_APPOINTMENTS_CHART,
  GET_VISITS_CHART,
  GET_APPOINTMENTS_SUBSTATUS_CHART,
  GET_VISITS_SUBSTATUS_CHART,
  GET_TOTALS_APPOINTMENTS_DASHBOARD,
  GET_TOTALS_VISITS_DASHBOARD,
  GET_VISITS_DETAILS_CHART,
  GET_VISITS_STORES_CHART,
  GET_APPOINTMENTS_STORES_CHART,
  GET_APPOINTMENTS_DETAILS_CHART,
  GET_CALLS_SUBSTATUS_CHART,
  GET_CALLS_STORES_CHART,
  GET_CALLS_DETAILS_CHART,
  GET_CALLS_MAKES_CHART,
  GET_TOTALS_CALLS_DASHBOARD,
  GET_CLOSURE_TOP_USERS,
  GET_CONVERSATIONS_BY_AGENT,
  GET_CALLS_DRILLDOWN_CHART,
  GET_BUREAU_DRILLDOWN_CHART,
  GET_CONVERSATIONS_BY_AGENT_CHART,
  GET_AGENTS_CALLS_CHART,
  GET_STATUS_STORES_CHART,
  GET_MONITORING_AGENT,
  GET_INVESTMENTS_CHART,
  GET_STATUS_STORES_CHART_ACUMULATED,
  GET_TOTAL_TEMPERATURES,
  GET_FUNNEL_APPOINTMENT_CHART,
  GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE,
  GET_FUNNEL_DATOS,
  GET_DRILLDOWN_FUNNEL_SOURCE,
  GET_HOURS_COMPARATIVE_CHART,
  GET_DRILLDOWN_TIME,
  CLEAR_DRILL_TIME,
  GET_GLOBAL_SOURCES,
  GET_CAPTACION_MEDIOS,
  GET_AGENTS_RECORDINGS,
  CLEAR_RECORDINGS_TABLE,
  GET_RECORDINGS_DRILLDOWN_CHART,
  GET_PENDING_TASKS,
  GET_REPORT_VISITS,
  GET_MATRIX,
  GET_MATRIX_INVESTMENT,
  GET_MATRIX_SEGMENTATION,
  GET_STORES_EXTRA_SCORES_TABLE,
  GET_STORES_SCORES_TABLE,
  GET_CREDIT_REQUEST_TABLE,
  GET_TABLEMODELS,
  GET_AGENTS_COMPARATIVE
} from '../types';
import _ from 'lodash';

export default (state, action) => {
  switch (action.type) {
    case GET_TABLEMODELS:
      return {
        ...state,
        tableModels: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MATRIX_SEGMENTATION:
      return {
        ...state,
        matrixSegmentation: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MATRIX_INVESTMENT:
      return {
        ...state,
        matrixInvestment: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MATRIX:
      let matrix = {};
      if (action.payload.data[0])
        action.payload.data[0].map(
          (item, index) => (matrix[item] = action.payload.data[1][index])
        );

      let dataMatrix = {
        clicks: action?.payload?.data?.data[0]?.clicks
          ? action.payload.data.data[0].clicks
          : 0,
        impressions: action?.payload?.data?.data[0]?.impressions
          ? action.payload.data.data[0].impressions
          : 0,
        cpm: action?.payload?.data?.data[0]?.cpm
          ? action.payload.data.data[0].cpm
          : 0,
        leads: action?.payload?.data?.data[0]?.actions
          ? _.find(action?.payload?.data?.data[0]?.actions, {
              action_type: 'lead'
            })
          : 0,
        spend: action?.payload?.data?.data[0]?.spend
          ? action.payload.data.data[0].spend
          : 0,
        costPerLead: action?.payload?.data?.data[0]?.cost_per_action_type
          ? _.find(action?.payload?.data?.data[0]?.cost_per_action_type, {
              action_type: 'lead'
            })
          : 0
      };

      /* if (matrix['CPM (cost per 1000 impressions)']) dataMatrix.cpm = matrix['CPM (cost per 1000 impressions)']
      if (matrix['Clicks (all)']) dataMatrix.clicks = matrix['Clicks (all)']
      if (matrix['On-Facebook leads']) dataMatrix.leads = matrix['On-Facebook leads']
      if (matrix['Impressions']) dataMatrix.impressions = matrix['Impressions']
      if (matrix['Cost']) dataMatrix.spend = matrix['Cost']
      if (matrix['Cost per on-Facebook lead']) dataMatrix.costPerLead = matrix['Cost per on-Facebook lead'] */

      return {
        ...state,
        matrix: dataMatrix,
        matrixCampaigns: action.payload.campaigns,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_COMPARATIVE:
      return {
        ...state,
        agentsComparative: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_REPORT_VISITS:
      return {
        ...state,
        visitsReport: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_PENDING_TASKS:
      return {
        ...state,
        pendingTasksReport: action.payload,
        loadingCharts: false,
        error: null
      };
    case CLEAR_RECORDINGS_TABLE:
      return {
        ...state,
        agentsRecordingsTable: [],
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_RECORDINGS:
      return {
        ...state,
        agentsRecordingsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_RECORDINGS_REVIEWS:
      return {
        ...state,
        recordingsReviews: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CAPTACION_MEDIOS:
      return {
        ...state,
        captacionMedios: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_GLOBAL_SOURCES:
      return {
        ...state,
        globalSources: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_GLOBAL:
      return {
        ...state,
        global: action.payload,
        loadingCharts: false,
        error: null
      };
    case CLEAR_DRILL_TIME:
      return {
        ...state,
        drilldownTime: [],
        loadingCharts: false,
        error: null
      };
    case GET_DRILLDOWN_TIME:
      return {
        ...state,
        drilldownTime: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_DRILLDOWN_COMPARATIVE:
      return {
        ...state,
        drilldownComparative: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_REPORT_SALES:
      return {
        ...state,
        reportSales: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_FUNNEL_DATOS:
      return {
        ...state,
        funnelDatos: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TOTAL_TEMPERATURES:
      return {
        ...state,
        totalTemperatures: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MONITORING_AGENT_SOURCES:
      return {
        ...state,
        monitoringAgentSources: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MONITORING_AGENT: {
      return {
        ...state,
        monitoringAgent: action.payload,
        loadingCharts: false,
        error: null
      };
    }
    case GET_TEMPERATURES_SALES_TABLE:
      return {
        ...state,
        temperaturesSalesTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_VISITS_TABLE:
      return {
        ...state,
        temperaturesVisitsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_APPOINTMENTS_TABLE:
      return {
        ...state,
        temperaturesAppointmentsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_LEADS_TABLE:
      return {
        ...state,
        temperaturesLeadsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_STATUS_STORES_CHART:
      return {
        ...state,
        statusStoresChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_STATUS_STORES_CHART_ACUMULATED:
      return {
        ...state,
        statusStoresChartAcumulated: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_CALLS_CHART:
      return {
        ...state,
        agentsCallsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CONVERSATIONS_BY_AGENT_CHART:
      return {
        ...state,
        conversationsByAgentChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CONVERSATIONS_BY_AGENT:
      return {
        ...state,
        conversationsByAgent: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CLOSURE_TOP_USERS:
      return {
        ...state,
        closureTopUsers: action.payload.bar,
        loadingCharts: false,
        error: null
      };
    case GET_TOTALS_APPOINTMENTS_DASHBOARD:
      return {
        ...state,
        ...action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_APPOINTMENTS_SUBSTATUS_CHART:
      return {
        ...state,
        appointmentsSubstatusChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_APPOINTMENTS_STORES_CHART:
      return {
        ...state,
        appointmentsStoresChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_APPOINTMENTS_DETAILS_CHART:
      return {
        ...state,
        appointmentsDetailsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    // ? chart visits cases dash
    case GET_TOTALS_VISITS_DASHBOARD:
      return {
        ...state,
        ...action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_VISITS_SUBSTATUS_CHART:
      return {
        ...state,
        visitsSubstatusChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_VISITS_STORES_CHART:
      return {
        ...state,
        visitsStoresChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_VISITS_DETAILS_CHART:
      return {
        ...state,
        visitsDetailsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    // ? end chart visits cases dash

    // ? chart calls cases dash
    case GET_TOTALS_CALLS_DASHBOARD:
      return {
        ...state,
        ...action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_CALLS_SUBSTATUS_CHART:
      return {
        ...state,
        callsSubstatusChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_CALLS_STORES_CHART:
      return {
        ...state,
        callsStoresChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CALLS_DRILLDOWN_CHART:
      return {
        ...state,
        drilldownCalls: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_BUREAU_DRILLDOWN_CHART:
      return {
        ...state,
        drilldownBureau: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_RECORDINGS_DRILLDOWN_CHART:
      return {
        ...state,
        drilldownRecordings: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_DRILLDOWN_FUNNEL_SOURCE:
      return {
        ...state,
        drilldownFunnelSource: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CALLS_DETAILS_CHART:
      return {
        ...state,
        callsDetailsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_CALLS_MAKES_CHART:
      return {
        ...state,
        callsMakesChart: action.payload,
        loadingCharts: false,
        error: null
      };
    // ? end chart calls cases dash
    case GET_VISITS_CHART:
      return {
        ...state,
        visitsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_APPOINTMENTS_CHART:
      return {
        ...state,
        appointmentsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_HOURS_COMPARATIVE_CHART:
      return {
        ...state,
        hoursComparativeChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_SUBSTATUS_AGENT_CHART:
      return {
        ...state,
        substatusAgentChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_STATUS_REPORT_CHART:
      return {
        ...state,
        statusReportChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_FUNNEL_RSI:
      return {
        ...state,
        temperaturesFunnelRSI: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_FUNNEL_APPOINTMENT_CHART:
      return {
        ...state,
        funnelAppointment: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_FUNNEL_APPOINTMENT_COMPARATIVE:
      let finalApp = state.temperaturesFunnelAppointmentComparative;
      finalApp[action.position] = action.payload;
      return {
        ...state,
        temperaturesFunnelAppointmentComparative: finalApp,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_FUNNEL_RSI_COMPARATIVE:
      let finalComparative = state.temperaturesFunnelRSIComparative;
      finalComparative[action.position] = action.payload;
      return {
        ...state,
        temperaturesFunnelRSIComparative: finalComparative,
        loadingCharts: false,
        error: null
      };
    case GET_TEMPERATURES_FUNNEL:
      return {
        ...state,
        temperaturesFunnel: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_STATUS_CHART:
      return {
        ...state,
        agentsStatusChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_VS_CHART:
      return {
        ...state,
        vsChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MONTHLY_ADMIN_CHART:
      return {
        ...state,
        monthlyAdminChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_BUREAU_CHART:
      return {
        ...state,
        bureauChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_MONTHLY_COMPARATIVE_CHART:
      return {
        ...state,
        monthlyComparativeChart: action.payload,
        loadingCharts: false,
        err: null
      };

    case GET_UNIT_MONTHLY_COMPARATIVE_CHART:
      return {
        ...state,
        unitMonthlyComparativeChart: action.payload,
        loadingCharts: false,
        err: null
      };

    case GET_TEMPERATURES_CHART:
      return {
        ...state,
        temperaturesChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_INVESTMENTS_CHART:
      return {
        ...state,
        investmentsChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_CONVERSATIONS_CHART:
      return {
        ...state,
        conversationsChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_MESSAGES_CHART:
      return {
        ...state,
        messagesChart: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_MODELS_BY_MAKE_CHART:
      return {
        ...state,
        modelsByMakeChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_LEADS_TABLE:
      return {
        ...state,
        agentsLeadsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_VISITS_TABLE:
      return {
        ...state,
        agentsVisitsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_CALLS_TABLE:
      return {
        ...state,
        agentsCallsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_STORES_EXTRA_SCORES_TABLE:
      return {
        ...state,
        storesExtraScoresTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_STORES_SCORES_TABLE:
      return {
        ...state,
        storesScoresTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_APPOINTMENTS_TABLE:
      return {
        ...state,
        agentsAppointmentsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_AGENTS_SOLDS_TABLE:
      return {
        ...state,
        agentsSoldsTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_OPERATION_TYPE_CHART:
      return {
        ...state,
        operationTypeChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_INVESTMENT_PROFIT_STORE_CHART:
      return {
        ...state,
        investmentProfitStoreChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_PROFIT_STORE_CHART:
      return {
        ...state,
        profitStoreChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_PROFIT_MODEL_CHART:
      return {
        ...state,
        profitModelChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_PROFIT_SOURCE_CHART:
      return {
        ...state,
        profitSourceChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TOTALS_INVESTMENT_DASHBOARD:
      return {
        ...state,
        ...action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TOTALS_FINANCING_DASHBOARD:
      return {
        ...state,
        ...action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_PROFIT_CHART:
      return {
        ...state,
        profitChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_INVESTMENT_PROFIT_CHART:
      return {
        ...state,
        investmentProfitChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_WEEKLY_INVESTMENT_PROFIT_CHART:
      return {
        ...state,
        weeklyInvestmentProfitChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_WEEKLY_PROFIT_CHART:
      return {
        ...state,
        weeklyProfitChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_LEADS_MONTHLY_CHART:
      return {
        ...state,
        leadsMonthlyChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_LEADS_STORE_CHART:
      return {
        ...state,
        leadsStoreChart: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_TOTALS_DASHBOARD:
      let piechart = {
        series: [
          action.payload.totalLeads,
          action.payload.totalAppointments,
          action.payload.totalVisits,
          action.payload.totalSolds
        ],
        categories: ['Lead', 'Appointment', 'Visit', 'Sold']
      };

      return {
        ...state,
        ...action.payload,
        pieStatus: piechart,
        loadingCharts: false,
        error: null
      };
    case GET_PIE_STATUS_CHART:
      return {
        ...state,
        pieStatus: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_HOURS_CHART:
      return {
        ...state,
        hours: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MODELS_CHART:
      return {
        ...state,
        models: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_DAILY_CHART:
      return {
        ...state,
        daily: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MONTHLY_CHART:
      return {
        ...state,
        monthly: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_APPOINTMENTS_BY_STORE_CHART:
      return {
        ...state,
        appointmentsByStore: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_VISITS_BY_STORE_CHART:
      return {
        ...state,
        visitsByStore: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_SOLDS_BY_STORE_CHART:
      return {
        ...state,
        soldsByStore: action.payload,
        loadingCharts: false,
        error: null
      };

    case GET_CREDIT_REQUEST_TABLE:
      return {
        ...state,
        creditRequestTable: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_INVESTMENT_BY_STORE_CHART:
      return {
        ...state,
        investmentsByStore: action.payload,
        loadingCharts: false,
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingCharts: false
      };
    case CLEAR_STATE:
      return {
        matrix: {
          clicks: 0,
          impressions: 0,
          cpm: 0,
          leads: 0,
          spend: 0,
          costPerLead: 0
        },
        creditRequestTable: [],
        captacionMedios: [],
        globalSources: [],
        global: [],
        drilldownTime: [],
        drilldownComparative: [],
        reportSales: [],
        funnelDatos: [],
        monitoringAgent: [],
        monitoringAgentSources: {},
        drilldownCalls: [],
        drilldownRecordings: [],
        drilldownFunnelSource: [],
        leadsByStore: [],
        appointmentsByStore: [],
        visitsByStore: [],
        soldsByStore: [],
        investmentsByStore: [],
        monthly: [],
        daily: [],
        models: [],
        hours: [],
        linearStores: [],
        pieStatus: [],
        leadsStoreChart: [],
        leadsMonthlyChart: [],
        profitChart: [],
        investmentProfitChart: [],
        operationTypeChart: [],
        temperaturesLeadsTable: [],
        temperaturesAppointmentsTable: [],
        temperaturesVisitsTable: [],
        temperaturesSalesTable: [],
        agentsLeadsTable: [],
        agentsCallsTable: [],
        agentsComparative: [],
        agentsCallsChart: [],
        totalTemperatures: [],
        agentsVisitsTable: [],
        agentsAppointmentsTable: [],
        agentsSoldsTable: [],
        agentsStatusChart: [],
        modelsByMakeChart: [],
        conversationsChart: [],
        messagesChart: [],
        temperaturesChart: [],
        investmentsChart: [],
        monthlyAdminChart: [],
        monthlyComparativeChart: [],
        unitMonthlyComparativeChart: [],
        substatusAgentChart: [],
        vsChart: [],
        temperaturesFunnel: [],
        temperaturesFunnelRSI: [],
        temperaturesFunnelRSIComparative: [[], []],
        temperaturesFunnelAppointmentComparative: [[], []],
        statusReportChart: [],
        appointmentsChart: [],
        hoursComparativeChart: [],
        appointmentsStoresChart: [],
        appointmentsDetailsChart: [],
        visitsChart: [],
        weeklyProfitChart: [],
        weeklyInvestmentProfitChart: [],
        appointmentsSubstatusChart: [],
        total: null,
        totalLeads: null,
        totalVisits: null,
        totalAppointments: null,
        totalSolds: null,
        totalNew: null,
        totalFollowUp: null,
        totalCallAgain: null,
        totalWrongnumber: null,
        totalRejected: null,
        totalDocumentation: null,
        totalConfirm: null,
        totalConfirmed: null,
        totalVisitTracking: null,
        totalReschedule: null,
        totalClientNa: null,
        totalVisited: null,
        totalApplication: null,
        totalApprovedApplication: null,
        totalCondicionedApplication: null,
        totalRejectedApplication: null,
        totalSeparation: null,
        loadingCharts: false,
        totalFinancing: null,
        totalInvestment: null,
        totalProfits: null,
        totalProfitsInvestment: null,
        profitStoreChart: null,
        investmentProfitStoreChart: null,
        profitModelChart: null,
        profitSourceChart: null,
        totalAppointmentsDash: null,
        totalVisitsDash: null,
        visitsSubstatusChart: [],
        visitsStoresChart: [],
        visitsDetailsChart: [],
        totalCallsDash: null,
        callsSubstatusChart: [],
        callsStoresChart: [],
        callsDetailsChart: [],
        callsMakesChart: [],
        closureTopUsers: [],
        conversationsByAgent: [],
        conversationsByAgentChart: [],
        statusStoresChart: [],
        statusStoresChartAcumulated: [],
        funnelAppointment: [],
        recordingsReviews: [],
        matrixCampaigns: [],
        matrixInvestment: [],
        storesExtraScoresTable: [],
        storesScoresTable: [],
        tableModels: [],
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loadingCharts: true
      };
    default:
      return state;
  }
};
