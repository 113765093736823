import React, { useReducer } from 'react';
import OrderContext from './orderContext';
import orderReducer from './orderReducer';
import api from '../../api/api';
import {
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE,
  GET_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER
} from '../types';
import CustomAlert from '../../components/CustomAlert';

const OrderState = props => {
  const initialState = {
    loading: false,
    error: null,
    count: 0,
    orders: [],
    order: {}
  };
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const setLoading = () => dispatch({ type: SET_LOADING });

  const clearState = () => dispatch({ type: CLEAR_STATE });
  const ROUTE_NAME = 'orders';
  /**
   * Get orders of user depending of user.role
   *
   * @param {ObjectId} userId
   */
  const getOrders = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/${ROUTE_NAME}/aggregationV3`, query, config);
      dispatch({
        type: GET_ORDERS,
        payload: res.data.results.data,
        count: res.data.results.count
      });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data
      });
    }
  };

  /**
   * Get Order by order Id
   *
   * @param {ObjectId} orderId
   */
  const getOrder = async orderId => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoading();
      const res = await api.get(`/${ROUTE_NAME}/${orderId}`, config);
      dispatch({ type: GET_ORDER, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data
      });
    }
  };

  /**
   * Create Order
   *
   * @param {Object} order data required to create
   */
  const createOrder = async order => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoading();
      const res = await api.post(`/${ROUTE_NAME}/`, order, config);
      dispatch({ type: CREATE_ORDER, payload: res.data.data });
      CustomAlert({
        title: 'Contrato Creado Correctamente'
        // handleThen: () => {
        //   history.push(`/cuenta/contratos/${res.data.data._id}`);
        // }
      });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data
      });
    }
  };

  const updateOrder = async (order, orderId) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoading();
      const res = await api.put(`/${ROUTE_NAME}/${orderId}`, order, config);
      dispatch({ type: UPDATE_ORDER, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data
      });
    }
  };

  return (
    <OrderContext.Provider
      value={{
        order: state.order,
        orders: state.orders,
        count: state.count,
        loading: state.loading,
        error: state.error,
        getOrders,
        getOrder,
        createOrder,
        updateOrder,
        clearState,
        setLoading
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
export default OrderState;
