import React, { useReducer } from 'react';
import ReminderContext from './reminderContext';
import ReminderReducer from './reminderReducer';
import api from '../../api/api';
import { 
  GET_REMINDERS, 
  CREATE_REMINDER, 
  GET_REMINDER, 
  DELETE_REMINDER, 
  UPDATE_REMINDER, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const ReminderState = props => {
  const initialState = {
    reminders: [],
    reminder: {},
    loading: false,
    error: null
  };

  const [state, dispatch] = useReducer(ReminderReducer, initialState);

  //Get Reminders
  const getReminders = async () => {

    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/reminders?isActive=true`, config);
      dispatch({ type: GET_REMINDERS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Get Reminders by User
  const getRemindersByUser = async (userId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`users/${userId}/reminders?isActive=true`, config);
      dispatch({ type: GET_REMINDERS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

   //Get Reminder
   const getReminder = async (reminderId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    clearReminderState();
    setLoading();
    try {
      const res = await api.get(`/reminders/${reminderId}`,config);
      dispatch({ type: GET_REMINDER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Delete Reminder
  const deleteReminder = async (reminderId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
   
    setLoading();
    try {
      const res = await api.delete(`/reminders/${reminderId}`, config);
      dispatch({ type: DELETE_REMINDER, payload: res.data.deletedId })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Create Reminder
  const createReminder = async (reminder) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/reminders`, { ...reminder }, config);
      dispatch({ type: CREATE_REMINDER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  }

  //Update Reminder
  const updateReminder = async (reminder, reminderId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      
      const res = await api.put(`/reminders/${reminderId}`, {...reminder} ,config);
      dispatch({ type: UPDATE_REMINDER, payload: res.data.data })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Clear State
  const clearReminderState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <ReminderContext.Provider
      value={{
        loading: state.loading,
        reminders: state.reminders,
        reminder: state.reminder,
        error: state.error,
        getReminders,
        createReminder,
        getReminder,
        deleteReminder,
        updateReminder,
        clearReminderState,
        getRemindersByUser,
        setLoading
      }}
    >
      {props.children}
    </ReminderContext.Provider>
  );
};

export default ReminderState;
