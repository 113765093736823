import React, { useReducer } from 'react';
import RecordingTestContext from './recordingTestContext';
import RecordingTestReducer from './recordingTestReducer';
import api from '../../api/api';
import { 
  GET_RECORDING_TESTS, 
  CREATE_RECORDING_TEST, 
  GET_RECORDING_TEST, 
  DELETE_RECORDING_TEST, 
  UPDATE_RECORDING_TEST, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const RecordingTestState = props => {
  const initialState = {
    recordingTests: [],
    recordingTest: {},
    loading: false,
    error: null,
    count: null,
  };

  const [state, dispatch] = useReducer(RecordingTestReducer, initialState);

  const AdvancedResults = async (pagination, query) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    setLoading();
    try {
      const res = await api.get(
        `/recordingTests/recordingTestsAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
        config
      );
      dispatch({
        type: GET_RECORDING_TESTS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get RecordingTests
  const getRecordingTests = async (pagination, query, typeQuery, group) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    setLoading();
    try {
      const res = await api.get(`/recordingTests/recordingTestsAr?page=${pagination.page}&searchIndex=title&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}`, config);

      dispatch({ 
        type: GET_RECORDING_TESTS, 
        payload: res.data.data,
        count: res.data.pagination.total
       });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };
   //Get RecordingTest
   const getRecordingTest = async (recordingTestId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    clearState();
    setLoading();
    try {
      const res = await api.get(`/recordingTests/${recordingTestId}`, config);
      dispatch({ type: GET_RECORDING_TEST, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Delete RecordingTest
  const deleteRecordingTest = async (recordingTestId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    setLoading();
    try {
      const res = await api.delete(`/recordingTests/${recordingTestId}`, config);
      dispatch({ type: DELETE_RECORDING_TEST, payload: res.data.deletedId })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Create RecordingTest
  const createRecordingTest = async (recordingTest) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    clearState();
    setLoading();
    try {
      const res = await api.post(`/recordingTests`, { ...recordingTest }, config);
      dispatch({ type: CREATE_RECORDING_TEST, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})

    }
  }

  //Update RecordingTest
  const updateRecordingTest = async (recordingTest, recordingTestId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    
    setLoading();
    try {
      
      const res = await api.put(`/recordingTests/${recordingTestId}`, {...recordingTest} ,config);
      dispatch({ type: UPDATE_RECORDING_TEST, payload: res.data.data })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error})
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <RecordingTestContext.Provider
      value={{
        loading: state.loading,
        recordingTests: state.recordingTests,
        recordingTest: state.recordingTest,
        error: state.error,
        count: state.count,
        getRecordingTests,
        createRecordingTest,
        getRecordingTest,
        deleteRecordingTest,
        updateRecordingTest,
        clearState,
        setLoading,
        AdvancedResults
      }}
    >
      {props.children}
    </RecordingTestContext.Provider>
  );
};

export default RecordingTestState;
