import React, { useReducer } from 'react';
import StoreContext from './storeContext';
import StoreReducer from './storeReducer';
import api from '../../api/api';
import {
  GET_STORES,
  CREATE_STORE,
  GET_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_STORES_BY_MAKE,
  GET_ALL_STORES,
  UPDATE_ALL_STORES,
  UPDATE_ATTRIBUTES_STORES
} from '../types';

const StoreState = props => {
  const initialState = {
    stores: [],
    allStores: [],
    store: {},
    count: null,
    loading: false,
    error: null
  };

  const [state, dispatch] = useReducer(StoreReducer, initialState);

  //set credit count for Store
  const setCreditCount = async (storeId, data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(
        `/stores/credit/${storeId}`,
        { ...data },
        config
      );
      dispatch({ type: UPDATE_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getStoresV3 = async params => {
    clearStoreState();
    setLoading();
    try {
      const res = await api.post(`stores/getStoresV3`, params);
      dispatch({
        type: GET_STORES,
        payload: res.data.results.data,
        count: res.data.results.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Stores
  const getStores = async all => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearStoreState();
    setLoading();
    try {
      let actives = '';
      if (!all) {
        actives = 'isActive=true';
      }
      const res = await api.post(
        `stores/carokouDpx?sort=make,name&${actives}`,
        config
      );
      dispatch({ type: GET_STORES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateAllStores = async data =>
    dispatch({ type: UPDATE_ALL_STORES, payload: { ...data } });

  //Get Stores
  const getAllStores = async ({ all, group = null }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      let actives = '';
      if (!all) {
        actives = 'isActive=true';
      }

      let res;
      if (group) {
        res = await api.get(
          `/groups/${group}/stores?sort=make,name&${actives}`,
          config
        );
      } else {
        res = await api.post(`stores/carokouDpx?sort=make,name`, config);
      }

      dispatch({ type: GET_ALL_STORES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Stores By User
  const getStoresByUser = async userId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearStoreState();
    setLoading();
    try {
      const res = await api.get(`/users/storesDPX/${userId}`, config);
      dispatch({ type: GET_STORES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateAttStores = async value => {
    dispatch({ type: UPDATE_ATTRIBUTES_STORES, payload: value });
  };

  //Get Stores By Make
  const getStoresByMake = async (makeId, all) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearStoreState();
    setLoading();
    try {
      let actives = '';
      if (!all) {
        actives = '&isActive=true';
      }

      const res = await api.get(`/makes/${makeId}/stores?${actives}`, config);
      dispatch({ type: GET_STORES_BY_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getStoreByConversation = async credentials => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/stores/conversation/${credentials}`, config);
      dispatch({ type: GET_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Store
  const getStore = async storeId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/stores/dpx/${storeId}`, config);
      dispatch({ type: GET_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Store
  const createStore = async store => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearStoreState();
    setLoading();
    try {
      const res = await api.post(`/stores`, { ...store }, config);
      dispatch({ type: CREATE_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Store
  const deleteStore = async storeId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/stores/${storeId}`, config);
      dispatch({ type: DELETE_STORE, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Store
  const updateStore = async (store, storeId, file) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (file) {
      let uploadConfig = await api.post(
        '/medias/images',
        { type: file.type, fileName: file.name },
        config
      );

      await api.put(uploadConfig.data.url, file, {
        headers: { 'Content-Type': file ? file.type : null }
      });

      store.image = `${uploadConfig.data.url}`;
    }

    setLoading();
    try {
      const res = await api.put(`/stores/${storeId}`, { ...store }, config);
      dispatch({ type: UPDATE_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearStoreState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <StoreContext.Provider
      value={{
        loading: state.loading,
        stores: state.stores,
        store: state.store,
        error: state.error,
        allStores: state.allStores,
        count: state.count,
        getStores,
        getStoresV3,
        getStore,
        createStore,
        deleteStore,
        updateStore,
        clearStoreState,
        setLoading,
        getStoresByMake,
        updateAllStores,
        getStoreByConversation,
        getStoresByUser,
        getAllStores,
        updateAttStores,
        setCreditCount
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreState;
