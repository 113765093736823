import React, { useReducer } from 'react';
import RoleContext from './roleContext';
import RoleReducer from './roleReducer';
import api from '../../api/api';
import {
  GET_ROLES,
  CREATE_ROLE,
  GET_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const RoleState = props => {
  const initialState = {
    roles: [],
    role: {},
    loading: false,
    error: null
  };

  const [state, dispatch] = useReducer(RoleReducer, initialState);

  //Get Recordings by store
  const getRolesSuper = async (stores, pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(
        `/recordings/recordingsAr?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`,
        config
      );
      dispatch({
        type: GET_ROLES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getRolesByGroup = async groupId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearRoleState();
    setLoading();
    try {
      const res = await api.get(`/groups/${groupId}/roles`, config);
      dispatch({ type: GET_ROLES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Roles
  const getAllRoles = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/roles`, config);

      dispatch({
        type: GET_ROLES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Roles
  const getRoles = async (pagination, query, typeQuery) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      let res;
      if (!query) query = '';

      if (pagination) {
        res = await api.get(
          `/roles/dpxCarokou?page=${pagination.page}&limit=${pagination.limit}&searchIndex=name&searchText=${query}&searchType=${typeQuery}&isActive=true&validation=1`,
          config
        );
      } else {
        res = await api.get(
          `/roles/dpxCarokou?page=1&limit=10&searchIndex=name&searchText=${query}&searchType=${typeQuery}&isActive=true&validation=1`,
          config
        );
      }

      dispatch({
        type: GET_ROLES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Role
  const getRole = async roleId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearRoleState();
    setLoading();
    try {
      const res = await api.get(`/roles/${roleId}`, config);
      dispatch({ type: GET_ROLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Role
  const deleteRole = async roleId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/roles/${roleId}`, config);
      dispatch({ type: DELETE_ROLE, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Role
  const createRole = async role => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearRoleState();
    setLoading();
    try {
      const res = await api.post(`/roles`, { ...role }, config);
      dispatch({ type: CREATE_ROLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Role
  const updateRole = async (role, roleId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/roles/${roleId}`, { ...role }, config);
      dispatch({ type: UPDATE_ROLE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearRoleState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <RoleContext.Provider
      value={{
        loading: state.loading,
        roles: state.roles,
        role: state.role,
        error: state.error,
        count: state.count,
        getRoles,
        createRole,
        getRole,
        deleteRole,
        updateRole,
        clearRoleState,
        setLoading,
        getRolesSuper,
        getRolesByGroup,
        getAllRoles
      }}
    >
      {props.children}
    </RoleContext.Provider>
  );
};

export default RoleState;
