import {
  GET_LEADS_BUSINESS,
  GET_LEAD_BUSINESS,
  UPDATE_LEAD_BUSINESS,
  DELETE_LEAD_BUSINESS,
  CREATE_LEAD_BUSINESS,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_LEADS_BUSINESS:
      return {
        ...state,
        leadsBusiness: action.payload,
        count: action.count,
        loading: false,
        error: null
      };
    case GET_LEAD_BUSINESS:
      return {
        ...state,
        leadBusiness: action.payload,
        loading: false,
        error: null
      };
    case UPDATE_LEAD_BUSINESS:
      return {
        ...state,
        leadBusiness: action.payload,
        loading: false,
        error: null
      };
    case DELETE_LEAD_BUSINESS:
      state.leadsBusiness = state.leadsBusiness.filter(
        leadBusiness =>
          leadBusiness._id.toString() !== action.payload.toString()
      );
      return {
        ...state,
        leadBusiness: null,
        loading: false,
        error: null
      };
    case CREATE_LEAD_BUSINESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        leadBusiness: {},
        leadsBusiness: [],
        loading: false,
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
