export const roles = {
  generalManager: '641a0aceb9338d2e06161327',
  rockstar: '6144d4548fb0f00000000000',
  superAdmin: '641a0aeab9338d2e0616132c',
  admin: '6459145dff30096ffd4f0118',
  adminBDC: '641a0afdb9338d2e06161340',
  user: '641f3ca3e9f658304da5e7b9',
  marketing: '641a0b0eb9338d2e0616134a',
  receptionist: '641a0bbdb9338d2e06161389',
  digitalMarketing: '641a0c4eb9338d2e061613af',
  userBDC: '641a0c6fb9338d2e061613ca',
  userService: '641a0c7cb9338d2e061613d3',
  adminService: '641a0c90b9338d2e061613e8',
  salesManager: '641a0ca2b9338d2e061613ed',
  rh: '641a0cc7b9338d2e06161416',
  carokou: '641a0d38b9338d2e06161442',
  receptionistCall: '641a0cd8b9338d2e0616141b',
  carokouUser: '641f3ca3e9f658304da5e7b9'
};

//Single Roles
export const isRockstar = role => roles.rockstar === role;
export const isSuper = role => roles.superAdmin === role;
export const isAdmin = role => roles.admin === role;
export const isAdminBDC = role => roles.adminBDC === role;
export const isUser = role => roles.user === role;
export const isMarketing = role => roles.marketing === role;
export const isReceptionist = role => roles.receptionist === role;
export const isReceptionistCalls = role => roles.receptionistCall === role;
export const isDigitalMkt = role => roles.digitalMarketing === role;
export const isGeneralManager = role => roles.generalManager === role;
export const isUserBDC = role => roles.userBDC === role;
export const isUserService = role => roles.userService === role;
export const isAdminService = role => roles.adminService === role;
export const isSalesManager = role => roles.salesManager === role;
export const isRH = role => roles.rh === role;
export const isCarokou = role => roles.carokou === role;
export const isCarokouUser = role => roles.carokouUser === role;
