import React, { useReducer } from 'react';
import LeadBusinessContext from './leadBusinessContext';
import LeadBusinessReducer from './leadBusinessReducer';
import api from '../../api/api';
import {
  GET_LEADS_BUSINESS,
  GET_LEAD_BUSINESS,
  UPDATE_LEAD_BUSINESS,
  DELETE_LEAD_BUSINESS,
  CREATE_LEAD_BUSINESS,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const LeadBusinessState = props => {
  const initialState = {
    leadsBusiness: [],
    leadBusiness: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(LeadBusinessReducer, initialState);

  //Get Leads
  const getLeadsBusiness = async values => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.post(
        `/leads/business/AdvancedResults`,
        { ...values },
        config
      );

      dispatch({
        type: GET_LEADS_BUSINESS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Single Item by ID
  const getLeadBusiness = async leadId => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      if (leadId && String(leadId) !== 'undefined') {
        const res = await api.get(`/leads/business/${leadId}`, config);
        dispatch({
          type: GET_LEAD_BUSINESS,
          payload: res.data.data
        });
      } else {
        clearState();
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update Lead
  const updateLeadBusiness = async (lead, leadId) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(
        `/leads/business/${leadId}`,
        { ...lead },
        config
      );
      dispatch({ type: UPDATE_LEAD_BUSINESS, payload: res.data.data });
    } catch (err) {
      console.log(err.response.data.error);
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Lead
  const deleteLeadBusiness = async leadId => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.delete(`/leads/business/${leadId}`, config);
      dispatch({ type: DELETE_LEAD_BUSINESS, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Lead
  const createLeadBusiness = async lead => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/leads/business`, { ...lead }, config);
      dispatch({ type: CREATE_LEAD_BUSINESS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <LeadBusinessContext.Provider
      value={{
        loading: state.loading,
        leadsBusiness: state.leadsBusiness,
        leadBusiness: state.leadBusiness,
        error: state.error,
        count: state.count,
        getLeadsBusiness,
        getLeadBusiness,
        updateLeadBusiness,
        deleteLeadBusiness,
        createLeadBusiness,
        clearState,
        setLoading
      }}
    >
      {props.children}
    </LeadBusinessContext.Provider>
  );
};

export default LeadBusinessState;
