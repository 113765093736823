import { 
  GET_TICKETS, 
  CREATE_TICKET, 
  GET_TICKET, 
  DELETE_TICKET, 
  UPDATE_TICKET, 
  SET_ERROR,
  CLEAR_STATE,
  GET_NOTIFICATIONS_TICKETS,
  SET_LOADING,
  UPDATE_TICKET_LIST,
  UPDATE_LIST
 } from '../types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_LIST:
      if(state && state.notificationTickets){
        state.notificationTickets = state.notificationTickets.filter( ticket => ticket._id.toString() !== action.payload.toString())
      }
      return {
        ...state,
        ticket: null,
        loading: false, 
        error: null
      };
    case UPDATE_TICKET_LIST:
      return {
        ...state,
        notificationTickets: [action.payload, ...state.notificationTickets],
        error: null,
        loading: false
      }
    case GET_NOTIFICATIONS_TICKETS:
      return {
        ...state,
        notificationTickets: action.payload,
        loading: false, 
        error: null
      }
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        loading: false, 
        count: action.count,
        error: null
      };
    case GET_TICKET:
      return {
        ...state,
        ticket: action.payload,
        loading: false, 
        error: null
      };
    case CREATE_TICKET:
      return {
        ...state,
        loading: false, 
        error: null
      };
    case DELETE_TICKET:
      state.tickets = state.tickets.filter( ticket => ticket._id.toString() !== action.payload.toString())
      return {
        ...state,
        ticket: null,
        loading: false, 
        error: null
      };
    case UPDATE_TICKET:
      return {
        ...state,
        ticket: action.payload,
        loading: false, 
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case CLEAR_STATE:
      return {
        ...state,
        loading: false,
        error: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
        }
    default:
      return state;
  }
};
