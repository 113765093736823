import React from 'react';
import { Box, List, ListSubheader } from '@material-ui/core';

const Repeater = ({ renderNavItems, data, location, user }) => {

  return (
    <>
      {
        data.map((section, index) => {

          if(section.items[0].items.length === 1)return false

          return ( 
            <Box p={2} key={index}>
              <List
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {
                  renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                    user
                  })
                }
              </List>
            </Box>
          )
        })
      }
    </>
  );
};

export default Repeater;
