import React from 'react';
import { Typography } from '@material-ui/core';

const Logo = props => {
  return (
    <Typography variant="h6" color="textPrimary">
      <img src="/static/Logo_white.png" style={{ width: 170 }} alt="logo" />
    </Typography>
  );
};

export default Logo;
