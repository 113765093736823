import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import JWTLogin from 'views/auth/LoginView/JWTLogin';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(50),
    paddingRight: theme.spacing(50),
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  currentMethodIcon: {
    height: 60,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    width: '38vw',
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(15deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    display: 'none',
    width: '40vw',
    position: 'absolute',
    top: 0,
    right: 0,
    '& > img': {
      maxWidth: '40vw',
      height: 'auto'
    }
  },
  HeroContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    // maxWidth:'900px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  formContainer: {
    // maxWidth:'900px',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '3rem'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.HeroContainer, className)} {...rest}>
      {/* <Container maxWidth={"lg"}> */}
      <Grid
        item
        xs={12}
        md={7}
        className={classes.imageContainer}
        position="relative"
      >
        <div className={classes.shape}>
          <img alt="Shapes" src="/static/home/shapes.svg" />
        </div>
        <div className={classes.image}>
          <img alt="Presentation" src="/static/home/dark-light.png" />
        </div>
      </Grid>

      <Grid className={classes.formContainer} item xs={12} md={5}>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="overline" color="secondary">
            Introducing
          </Typography>
          <Typography variant="h1" color="textPrimary">
            Carokou
          </Typography>
          <Box mt={3}>
            <Typography variant="body1" color="textSecondary">
              A Business Intelligence + CRM Automotive Platform with
              Conversations
            </Typography>
          </Box>
          {/* <Box mt={3} alignItems="center">
                <Typography variant="body1" style={{ color: 'white' }}>
                  Support on{' '}
                  <TwitterIcon
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      color: '#1da1f2'
                    }}
                  />{' '}
                  <Link
                    href="https://twitter.com/dealerprox"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                  >
                    @dealerprox
                  </Link>
                </Typography>
              </Box> */}
          <Box mt={3}>
            <JWTLogin />
          </Box>
        </Box>
      </Grid>
      {/* </Container> */}
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
