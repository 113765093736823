import React, { useReducer } from 'react';
import AdCampaignContext from './adCampaignContext';
import AdCampaignReducer from './adCampaignReducer';
import api from '../../api/api';
import { 
  GET_ADCAMPAIGNS, 
  CREATE_ADCAMPAIGN, 
  GET_ADCAMPAIGN, 
  DELETE_ADCAMPAIGN, 
  UPDATE_ADCAMPAIGN, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const AdCampaignState = props => {
  const initialState = {
    adCampaigns: [],
    adCampaign: {},
    loading: false,
    error: null,
    count: null,

  };

  const [state, dispatch] = useReducer(AdCampaignReducer, initialState);

  const AdvancedResults = async (pagination, query) => {
    setLoading();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    try {
      const res = await api.get(
        `/adCampaigns?page=${pagination.page}&limit=${pagination.limit}${query.replace(/createdAt/gi, 'startDate')}&searchType=and`,
        config
      );
      dispatch({
        type: GET_ADCAMPAIGNS,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response });
    }
  };
  
  const getAdCampaignsQuery = async (query = '') => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/adCampaigns?${query}`, config);

      dispatch({ 
        type: GET_ADCAMPAIGNS, 
        payload: res.data.data, 
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})
    }
  };

   //Get Recordings by store
   const getAdCampaignsByStore = async (stores, pagination, query, typeQuery) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/adCampaigns?${stores}&page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`, config);

      dispatch({ 
        type: GET_ADCAMPAIGNS, 
        payload: res.data.data, 
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})
    }
  };

  //Get AdCampaigns
  const getAdCampaigns = async (pagination, query, typeQuery) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };

    if (!query) query = '';
    setLoading();
    try {
      const res = await api.get(`/adCampaigns?page=${pagination.page}&searchIndex=name-store-source&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1`, config);
      dispatch({
         type: GET_ADCAMPAIGNS, 
         payload: res.data.data,
         count: res.data.pagination.total
        });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})
    }
  };

   //Get AdCampaign
   const getAdCampaign = async (adCampaignId) => {
    setLoading();
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    try {
      const res = await api.get(`/adCampaigns/${adCampaignId}`, config);
      dispatch({ type: GET_ADCAMPAIGN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})
    }
  };

  //Delete AdCampaign
  const deleteAdCampaign = async (adCampaignId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      
      const res = await api.delete(`/adCampaigns/${adCampaignId}`, config);
      dispatch({ type: DELETE_ADCAMPAIGN, payload: res.data.deletedId })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})

    }
  };

  //Create AdCampaign
  const createAdCampaign = async (adCampaign) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    clearAdCampaignState();
    setLoading();
    try {
      const res = await api.post(`/adCampaigns`, { ...adCampaign }, config);
      dispatch({ type: CREATE_ADCAMPAIGN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error })

    }
  }

  //Update AdCampaign
  const updateAdCampaign = async (adCampaign, adCampaignId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      
      const res = await api.put(`/adCampaigns/${adCampaignId}`, {...adCampaign} ,config);
      dispatch({ type: UPDATE_ADCAMPAIGN, payload: res.data.data })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response})
    }
  };

  //Clear State
  const clearAdCampaignState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AdCampaignContext.Provider
      value={{
        loading: state.loading,
        adCampaigns: state.adCampaigns,
        adCampaign: state.adCampaign,
        error: state.error,
        count: state.count,
        getAdCampaigns,
        createAdCampaign,
        getAdCampaign,
        deleteAdCampaign,
        updateAdCampaign,
        clearAdCampaignState,
        setLoading,
        AdvancedResults,
        getAdCampaignsByStore,
        getAdCampaignsQuery
      }}
    >
      {props.children}
    </AdCampaignContext.Provider>
  );
};

export default AdCampaignState;
