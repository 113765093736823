import React from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { List, Box, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import Repeater from './Repeater';
import useAuth from 'hooks/useAuth';
// import { isAddonAvailableMultiStore } from 'utils/availableAddOn';
import {
  isRockstar,
  isAdmin,
  isSuper,
  isGeneralManager,
  isUser,
  isSalesManager
} from 'utils/AuthRoles';

function renderNavItems({ items, pathname, depth = 0, user }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, user }),
        []
      )}
    </List>
  );
}

const specialRoutes = [
  '/conversations',
  '/management/recording',
  '/management/lists',
  '/management/showroom-leads',
  '/management/leads',
  '/management/campaigns'
];
function reduceChildRoutes({ acc, pathname, item, depth, user }) {
  const key = item.title + depth + Math.random();

  if (item.items) {
    var open = false;
    item.items.forEach(item => {
      if (item.items) {
        item.items.forEach(item2 => {
          if (item2.href && pathname.includes(item2.href)) {
            open = true;
          }
        });
      } else {
        if (item.href && pathname.includes(item.href)) {
          open = true;
        }
      }
    });

    if (item.items.length !== 1) {
      ////END LOGIC
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            user
          })}
        </NavItem>
      );
    }
  } else {
    if (item.title === 'add-section') return acc;

    let show = false;

    ////START LOGIC
    switch (item.href) {
      case '/management/showroom-leads':
        if (
          user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isSuper(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            ((isUser(user.role._id) || isAdmin(user.role._id)) &&
              user.leadType &&
              (user.leadType === 'all' || user.leadType === 'showroom')))
        ) {
          show = true;
        }
        break;
      case '/management/leads':
        if (
          user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isSuper(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            ((isUser(user.role._id) || isAdmin(user.role._id)) &&
              user.leadType &&
              (user.leadType === 'all' || user.leadType === 'lead')))
        ) {
          show = true;
        }
        break;
      default:
        break;
    }

    if (
      !specialRoutes.includes(item.href) ||
      (specialRoutes.includes(item.href) && show)
    ) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          open={true}
          key={key}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {}
}));

const Barra = ({ onMobileClose, menu, openMobile, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Repeater
          renderNavItems={renderNavItems}
          data={menu}
          location={location}
          user={user}
        />
      </PerfectScrollbar>
    </Box>
  );
};

export default Barra;
