import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { DirectionsCar } from '@material-ui/icons';
import {
  isAdmin,
  isCarokou,
  isDigitalMkt,
  isGeneralManager,
  isMarketing,
  isRockstar,
  isSalesManager,
  isSuper,
  isUser
} from 'utils/AuthRoles';

const Header = ({ className, carType, setCarType, ...rest }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      {user &&
        user.role &&
        (isRockstar(user.role._id) ||
          isSuper(user.role._id) ||
          isCarokou(user.role._id) ||
          isMarketing(user.role._id) ||
          isDigitalMkt(user.role._id) ||
          ((isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            isAdmin(user.role._id) ||
            isUser(user.role._id)) &&
            user.carType &&
            user.carType === 'ambos')) && (
          <ButtonGroup color="primary" style={{ marginRight: '1em' }}>
            {[
              { title: t('Users.Both'), value: 'all' },
              { title: t('Users.News'), value: 'nuevo' },
              { title: t('Users.Preowneds'), value: 'seminuevo' }
            ].map(option => (
              <Button
                style={{ textTransform: 'capitalize' }}
                variant={carType === option.value ? 'contained' : 'outlined'}
                onClick={e => {
                  setCarType(option.value);
                }}
              >
                <DirectionsCar />
                <p style={{ marginLeft: 5, fontSize: 12 }}>{option.title}</p>
              </Button>
            ))}
          </ButtonGroup>
        )}
    </>
  );
};

export default Header;
