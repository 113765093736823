import React from 'react'
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const GuestGuard = ({ children }) => {


  if (localStorage.getItem('token')) {

      return <Redirect to="/reports/dashboard" />
   
    
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
