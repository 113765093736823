import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
  Badge
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'components/Logo';
import { THEMES } from '../../../constants';
import Account from './Account';
import Settings from './Settings';
import useNotification from 'hooks/useNotification';
import Notification from './Notifications';
import Recordings from './Recordings';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableIcon from '@material-ui/icons/TodayOutlined';
import EventToday from '@material-ui/icons/EventNoteOutlined';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import {
  isAdmin,
  isRockstar,
  isSuper,
  isUser,
  isGeneralManager,
  isAdminBDC,
  isAdminService,
  isSalesManager,
  isCarokou
} from 'utils/AuthRoles';

//Routes
import Routing from './Routing';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: '#0075df',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.default
      }
      : {}),
    ...(theme.name === THEMES.HALLOWEEN
      ? {
        backgroundRepeat: 'repeat',
        backgroundSize: '15%',
        backgroundImage:
          'url("https://i.pinimg.com/736x/59/ec/b0/59ecb0f60a7b239b73c6fe2f0798291b.jpg")',
        filter:
          'grayscale(15%) drop-shadow(0px 0px 10px #F07D23) brightness(90%)',
        color: 'white',
        '-webkit-text-stroke': '0.25px #FFF'
      }
      : {}),
    ...(theme.name === THEMES.ORNN
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '500px 0px',
        backgroundSize: '55%',
        backgroundImage:
          'url("https://i.ibb.co/kcnWCvH/thumb-1920-864277.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.CATS
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -20px',
        backgroundSize: '10%',
        backgroundImage:
          'url("https://data.whicdn.com/images/190747603/original.png")',
        color: 'black'
      }
      : {}),
    ...(theme.name === THEMES.GWEN
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '350px -50px',
        backgroundSize: '55%',
        backgroundImage:
          'url("https://i.ibb.co/s3d4Q6S/campeona-de-munecas-gwen-league-of-legends-lol-papel-pintado-3440x1440-15.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.GWEN_CAFE_CUTIE
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '450px 0px',
        backgroundSize: '55%',
        backgroundImage:
          'url("https://i.postimg.cc/R0W1wsXz/uhdpaper-com-201e-pc-8k-1.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.SAN_VALENTIN
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -20px',
        backgroundSize: '15%',
        backgroundImage:
          'url("https://image.freepik.com/vector-gratis/patron-fisuras-dia-san-valentin_96961-564.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.SAN_VALENTIN_SAD
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -30px',
        backgroundSize: '15%',
        backgroundImage:
          'url("https://images.freeimages.com/images/large-previews/a3d/broken-heart-brush-1637382.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.NEW_YEAR
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -40px',
        backgroundSize: '11%',
        backgroundImage:
          'url("https://www.bestoforlando.com/assets/upload/articles/best-orlando-views-watching-new-years-eve-fireworks/fireworks-450X270.jpg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.NOWAYHOME
      ? {
        backgroundPositionY: '25%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0px -40px',
        backgroundImage:
          'url("https://allears.net/wp-content/uploads/2021/06/22EC9E80-F2EE-44EF-95FA-850A9D181C31-1536x864.jpeg")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.DEADS_DAY
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -150px',
        backgroundSize: '12%',
        backgroundImage: `url("${process.env.AMAZONAWS_URL}/5d7a514b5d2c12c7449be042/e945b57f-e721-4a6e-9ea0-b419240176b6/diademuertos.jpeg")`,
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.NAVIDAD
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -150px',
        backgroundSize: '12%',
        backgroundImage:
          'url("https://i.pinimg.com/736x/40/b5/79/40b579283c397cd40d8b35f9658c5812.jpg")',
        color: 'black'
      }
      : {}),
    ...(theme.name === THEMES['NAVIDAD 2023']
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -30px',
        backgroundSize: '13%',
        backgroundImage:
          'url("https://images.vexels.com/content/218577/preview/merry-christmas-pattern-design-be5780.png")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.GRINCH || theme.name === THEMES['GRINCH DARK']
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -30px',
        backgroundSize: '13%',
        backgroundImage:
          'url("https://d2v8skpstyl8bm.cloudfront.net/products/images/product/4/2/42f808af-6d82-3b92-8105-541050e9ec66.jpg?1595522024")',
        color: 'white'
      }
      : {}),
    ...(theme.name === THEMES.NAVIDARKS
      ? {
        backgroundRepeat: 'repeat',
        backgroundPosition: '0px -150px',
        backgroundSize: '12%',
        backgroundImage:
          'url("https://cdn2.vectorstock.com/i/1000x1000/74/56/black-christmas-snowy-background-vector-22617456.jpg")',
        color: 'white'
      }
      : {})
  },
  birthday: {
    backgroundRepeat: 'repeat',
    backgroundSize: '10%',
    backgroundImage:
      'url("https://i.pinimg.com/736x/67/98/8d/67988d494f5bd62945fcd88fff44d80d.jpg")',
    color: 'black'
  },
  autocomplete: {
    width: 600,
    backgroundColor:
      theme.palette.type === 'light' ? 'white' : theme.palette.divider,
    borderRadius: 5
  },
  deadsDay: {
    backgroundRepeat: 'repeat',
    backgroundPosition: '0px -150px',
    backgroundSize: '12%',
    backgroundImage: `url("${process.env.AMAZONAWS_URL}/5d7a514b5d2c12c7449be042/e945b57f-e721-4a6e-9ea0-b419240176b6/diademuertos.jpeg")`,
    color: 'white'
  },
  halloween: {
    backgroundRepeat: 'repeat',
    backgroundSize: '13%',
    backgroundImage:
      'url("https://image.freepik.com/free-vector/hand-drawn-halloween-pattern-with-coffins_52683-44439.jpg")',
    color: 'white'
  },
  toolbar: {
    minHeight: 64
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { notifications } = useNotification();
  const { user } = useAuth();

  return (
    <AppBar
      className={clsx({
        [classes.root]: true,
        [classes.deadsDay]: moment().format('DD MM') === '02 11',
        [classes.halloween]: moment().format('DD MM') === '31 10',
        [classes.birthday]:
          user &&
          user.birthday &&
          moment(user.birthday).format('DD MMMM') === moment().format('DD MMMM')
      })}
      {...rest}
    >

      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" p={0}>
            <Logo />
          </RouterLink>
        </Hidden>
        <Box flexGrow={1} />
        {user && user.role && !isCarokou(user.role._id) && (
          <Box flexGrow={1}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{
                color:
                  user &&
                    user.birthday &&
                    moment(user.birthday).format('DD MMMM') ===
                    moment().format('DD MMMM')
                    ? 'black'
                    : 'white'
              }}
            >
              {user && user.group && localStorage.getItem('language')
                ? user.group.messageEs &&
                  localStorage.getItem('language') === 'es'
                  ? user.group.messageEs
                  : user.group.messageEn &&
                    localStorage.getItem('language') === 'en'
                    ? user.group.messageEn
                    : ''
                : ''}
            </Typography>
          </Box>
        )}
        {user && user.role && !isCarokou(user.role._id) && (
          <Box p={1}>
            <Routing />
          </Box>
        )}
        {user &&
          user.role &&
          (isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            isRockstar(user.role._id) ||
            isAdminBDC(user.role._id) ||
            isAdminService(user.role._id) ||
            isAdmin(user.role._id) ||
            isUser(user.role._id) ||
            isSuper(user.role._id)) && (
            <Box ml={2}>
              <Tooltip title="Go to Tasks" arrow>
                <IconButton
                  color="inherit"
                  aria-label="go to task"
                  component={RouterLink}
                  to="/tasks"
                >
                  <EventAvailableIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        {user &&
          user.role &&
          (isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            isRockstar(user.role._id) ||
            isAdminBDC(user.role._id) ||
            isAdminService(user.role._id) ||
            isAdmin(user.role._id) ||
            isUser(user.role._id) ||
            isSuper(user.role._id)) && (
            <Box ml={2}>
              <Tooltip title="Go to Calendar" arrow>
                <IconButton
                  color="inherit"
                  aria-label="go to calendar"
                  component={RouterLink}
                  to="/calendar"
                >
                  <EventToday />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        {user && user.role && !isCarokou(user.role._id) && (
          <Box ml={2}>
            <Badge
              overlap='rectangular'
              style={{ marginTop: 15 }}
              badgeContent={
                notifications && notifications.length > 0
                  ? notifications.length
                  : 0
              }
              max={99}
              color="error"
            >
              <Notification style={{ marginTop: '-15px' }} />
            </Badge>
          </Box>
        )}
        {user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isAdminBDC(user.role._id) ||
            isAdminService(user.role._id) ||
            isSuper(user.role._id) ||
            isAdmin(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id)) && (
            <Box ml={2}>
              <Recordings />
            </Box>
          )}
        {user && user.role && !isCarokou(user.role._id) && (
          <Box ml={2}>
            <Settings />
          </Box>
        )}

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
