import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  Box
} from '@material-ui/core';
import Page from 'components/Page';
import Header from './HeaderCarone';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useChart from 'hooks/useChart';
import numeral from 'numeral';
import ColumnChartAP from 'components/ColumnAPEX';
import TotalRepeaterCard from '../TotalRepeaterCard';
import ColumnHCChart from 'components/ColumnChart';
import { getMultiStoresIds } from 'utils/storesUser';
import useAuth from 'hooks/useAuth';
import useStore from 'hooks/useStore';
import Filters from 'components/Filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import PieChart from 'components/PieHC';
import BackDrop from 'components/Backdrop';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

const DashboardView = ({ className, getStores, getMakes, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [storesSearch, setStoresSearch] = useState('');
  const { stores } = useStore();
  const [filter, setFilter] = useState('month');
  const filters = ['makeId', 'store', 'carType', 'leadType', 'indicador'];
  const [date, setDate] = useState(
    `&createdAt[gte]=${moment()
      .startOf('month')
      .format()}`
  );
  const { t } = useTranslation();
  //eslint-disable-next-line
  const [custom, setCustom] = useState('D MMMM');
  const [leadType, setLeadType] = useState('&leadType=digital');
  const [carType, setCarType] = useState('nuevo');
  const [totales, setTotales] = useState([
    {
      size: 6,
      title: t('Financing.Total'),
      data: 0
    }
  ]);

  const [drill, setDrill] = useState(false);
  const [carTypeSearch, setCarTypeSearch] = useState('&carType=nuevo');
  const [leadStatusSearch, setLeadStatusSearch] = useState('Lead');
  const [makeSearch, setMakeSearch] = useState('');
  const [leadTypeSearch, setLeadTypeSearch] = useState('');
  const [indicador, setIndicador] = useState('lead');
  //eslint-disable-next-line
  const [search, setSearch] = useState(false);

  const {
    getTotalsFinancingDashboard,
    totalFinancing,
    clearCharts,
    loadingCharts,
    getProfitStoreChart,
    profitStoreChart,
    soldsByStore,
    getSoldsByStore,

    getGlobalSources,
    globalSources
  } = useChart();

  useEffect(() => {
    if (totalFinancing !== null) {
      setTotales([
        {
          size: 6,
          title: t('Financing.Total'),
          data: numeral(totalFinancing).format('0,0')
        }
      ]);
    }
    //eslint-disable-next-line
  }, [totalFinancing]);

  useEffect(() => {
    if (user && user.role) getStores(user.role._id);
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    return () => clearCharts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stores && stores.length > 0 && carType) {
      let fil = '';
      switch (filter) {
        case 'today':
          setCustom('HH:MM a');
          fil = 'HH:MM a';
          break;
        case 'yesterday':
          setCustom('HH:MM a');
          fil = 'HH:MM a';
          break;
        case 'month':
          setCustom('D MMMM');
          fil = 'D MMMM';
          break;
        case 'pastMonth':
          setCustom('D MMMM');
          fil = 'D MMMM';
          break;
        case 'year':
          setCustom('MMMM');
          fil = 'MMMM';
          break;
        case 'custom':
          setCustom('DD MMMM');
          fil = 'DD MMMM';
          break;
        default:
          setCustom('DD');
          fil = 'DD';
          break;
      }

      getData(fil);
    }
    //eslint-disable-next-line
  }, [filter, user, stores, date, carType, leadType, storesSearch]);

  const getData = (fil = 'DD') => {
    if (storesSearch === '') {
      let groupSearch = user.group ? `&group=${user.group._id}` : '';
      getTotalsFinancingDashboard(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}`
      );
      getProfitStoreChart(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}${groupSearch}`
      );
      getSoldsByStore(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}${groupSearch}`
      );
    } else {
      getTotalsFinancingDashboard(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
      getProfitStoreChart(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
      getSoldsByStore(`${date}&carType=${carType}${leadType}${storesSearch}`);
    }
  };

  const getSources = () => {
    if (storesSearch) {
      getGlobalSources(
        `${makeSearch}${carTypeSearch}${leadTypeSearch}${storesSearch}${date}&indicador=${indicador}`
      );
    } else {
      getGlobalSources(
        `${makeSearch}${carTypeSearch}${leadTypeSearch}&store[in]=${getMultiStoresIds(
          user.group.stores
        )}${date}&indicador=${indicador}`
      );
    }
  };

  const handleReload = async () => {
    let groupSearch = user.group ? `&group=${user.group._id}` : '';
    if (stores && stores.length > 0) {
      if (storesSearch === '') {
        getTotalsFinancingDashboard(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
        getProfitStoreChart(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}${groupSearch}`
        );
        getSoldsByStore(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}${groupSearch}`
        );
      } else {
        getTotalsFinancingDashboard(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
        getProfitStoreChart(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getSoldsByStore(`${date}&carType=${carType}${leadType}${storesSearch}`);
      }
    }
  };

  useEffect(() => {
    if (!loadingCharts) {
      getSources();
    }
    //eslint-disable-next-line
  }, [
    leadStatusSearch,
    carTypeSearch,
    ,
    date,
    user,
    makeSearch,
    leadTypeSearch,
    indicador,
    storesSearch
  ]);

  return (
    <Page className={classes.root} title="Dashboard">
      <BackDrop loading={loadingCharts} />

      <Container maxWidth={false}>
        <Header
          setDate={setDate}
          getFilter={setFilter}
          loading={loadingCharts}
          setStores={setStoresSearch}
          handleReload={handleReload}
          carType={carType}
          setCarType={setCarType}
          leadType={leadType}
          setLeadType={setLeadType}
        />
        <Grid container spacing={2} style={{ marginBottom: '0.25em' }}>
          <TotalRepeaterCard loading={loadingCharts} data={totales} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnHCChart
              style={{ height: 580 }}
              data={soldsByStore && soldsByStore.data ? soldsByStore.data : []}
              categories={
                soldsByStore && soldsByStore.categories
                  ? soldsByStore.categories
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                soldsByStore && soldsByStore.colors ? soldsByStore.colors : []
              }
              title={t('Status.sold2')}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnChartAP
              series={
                profitStoreChart &&
                profitStoreChart.bar &&
                profitStoreChart.bar.series
                  ? profitStoreChart.bar.series
                  : []
              }
              categories={
                profitStoreChart && profitStoreChart.categories
                  ? profitStoreChart.categories
                  : []
              }
              totals={
                profitStoreChart &&
                profitStoreChart.bar &&
                profitStoreChart.bar.totals
                  ? profitStoreChart.bar.totals
                  : []
              }
              distributed={true}
              disableLegend={true}
              customColors={
                profitStoreChart && profitStoreChart.colors
                  ? profitStoreChart.colors
                  : []
              }
              title={t(`Charts.Ps`)}
              dataType="money"
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Filters
                  setCarType={setCarTypeSearch}
                  setLeadStatus={setLeadStatusSearch}
                  setStore={setStoresSearch}
                  setMake={setMakeSearch}
                  setLeadType={setLeadTypeSearch}
                  leadType={leadTypeSearch}
                  setIndicador={setIndicador}
                  setSearch={setSearch}
                  filters={filters}
                  getMakes={getMakes}
                  getSources={getSources}
                  getStores={getStores}
                  setDrill={setDrill}
                  isDrill={drill}
                />

                <Box
                  display={'flex'}
                  flexDirection={'row-reverse'}
                  alignContent={'center'}
                >
                  <Box p={1}>
                    {loadingCharts === true ? (
                      <FontAwesomeIcon
                        className={classes.icon}
                        icon={faSync}
                        spin
                      />
                    ) : (
                      <FontAwesomeIcon
                        className={classes.icon}
                        icon={faSync}
                        onClick={getSources}
                      />
                    )}
                  </Box>
                </Box>
                <PieChart
                  customPointFormat={`{point.name}:<b>{point.y}</b> <br/> ${t(
                    'PercentChart'
                  )}: <b>{point.percentage:.2f}%</b>`}
                  customFormat={`{point.name}:<b>{point.y}</b> <br/> ${t(
                    'PercentChart'
                  )}: <b>{point.percentage:.2f}%</b>`}
                  percents={false}
                  series={
                    globalSources && globalSources.pie ? globalSources.pie : []
                  }
                  categories={
                    globalSources && globalSources.categories
                      ? globalSources.categories
                      : []
                  }
                  title={t('Charts.Global')}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardView;
