import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { CapitalizeNames } from 'utils/capitalize';
import { useTranslation } from 'react-i18next';
import { socket } from 'service/socket';
import { isAdmin, isCarokou, isSuper, isUser } from 'utils/AuthRoles';
import useNotification from 'hooks/useNotification';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const { clearNotificationState } = useNotification();

  const { logout } = useAuth();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    if (
      socket &&
      user &&
      user.stores &&
      user.role &&
      (isAdmin(user.role._id) || isUser(user.role._id))
    ) {
      user.stores.map(store => {
        socket.emit(
          'leaveRoom',
          `notifications-${store.twilioNumber}-${store.fbPage}`
        );
        return false;
      });
    }

    if (user && user.stores && user.role && isAdmin(user.role._id)) {
      user.stores.map(store => {
        if (
          socket &&
          user.carType &&
          (user.carType === 'nuevo' || user.carType === 'ambos')
        ) {
          socket.emit('leaveRoom', `${store._id}-room-nuevo`);
        }
        if (
          socket &&
          user.carType &&
          (user.carType === 'seminuevo' || user.carType === 'ambos')
        ) {
          socket.emit('leaveRoom', `${store._id}-room-seminuevo`);
        }
        return false;
      });
    }

    if (socket && user && user.role && isSuper(user.role._id)) {
      socket.emit('leaveRoom', `${user.group._id}-room`);
    }

    if (socket &&  user && user.role && isUser(user.role._id)) {
      socket.emit('leaveRoom', `${user._id}-room`);
    }

    if (socket &&  user && user.role && isSuper(user.role._id)) {
      socket.emit('leaveRoom', `${user.group._id}-room`);
    }

    if (socket &&  user && user.role && user.stores && isAdmin(user.role._id)) {
      user.stores.map(store => {
        socket.emit('leaveRoom', `${store._id}-room`);
        return false;
      });
    }

    let lenguaje = localStorage.getItem('language');
    let menu = localStorage.getItem('typeMenu');
    let lan = localStorage.getItem('i18nextLng');
    let token = localStorage.getItem('token');
    let settings = localStorage.getItem('settings');

    localStorage.clear();

    localStorage.setItem('language', lenguaje);
    localStorage.setItem('typeMenu', menu);
    localStorage.setItem('i18nextLng', lan);
    localStorage.setItem('token', token);
    localStorage.setItem('settings', settings);

    if (socket) socket._callbacks = [];

    logout();
  };

  useEffect(() => {
    return () => clearNotificationState();
    //eslint-disable-next-line
  }, []);

  if (!localStorage.getItem('token')) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user && user.image ? `${user.image}` : ''}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user ? CapitalizeNames(user.name) : ''}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/account">
          {t('Account.Account')}
        </MenuItem>
        {user && user.role && !isCarokou(user.role._id) && (
          <MenuItem component={RouterLink} to="/reviews/recordings">
            {t('Navbar.Recordings')}
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>{t('Account.Logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
