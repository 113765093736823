import React, { useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
  Box
} from '@material-ui/core';
import { CapitalizeNames } from 'utils/capitalize';
import useAuth from 'hooks/useAuth';
import useSource from 'hooks/useSource';
import useUser from 'hooks/useUser';
import useMake from 'hooks/useMake';
import useStore from 'hooks/useStore';
import { useTranslation } from 'react-i18next';
import {
  getMultiStoresIds,
  getMultiStoresMakesIds,
  getStoresByMake,
  getUserStoresByMake
} from 'utils/storesUser';
import Down from '@material-ui/icons/ArrowDropDown';
import Up from '@material-ui/icons/ArrowDropUp';
import {
  Airplay as GeneralIcon,
  Users as AgentsIcon,
  BarChart2 as BarIcon,
  Circle as CakeIcon,
  TrendingUp as LineIcon,
  Facebook as SourceIcon,
  AlertCircle as StatusIcon,
  CloudDrizzle as TemperatureIcon,
  User as LeadIcon,
  Calendar as AppointmentIcon,
  Home as VisitIcon,
  DollarSign as SoldIcon,
  Triangle,
  Folder
} from 'react-feather';
import {
  isAdmin,
  isDigitalMkt,
  isGeneralManager,
  isMarketing,
  isRockstar,
  isSalesManager,
  isSuper,
  isAdminBDC,
  roles,
  isCarokou
} from 'utils/AuthRoles';
import useVehicle from '../hooks/useVehicle';
import { makesByGroup } from '../utils/makesByGroup';

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    padding: 10
  },
  list: {
    border:
      theme.palette.type === 'light'
        ? `solid #bcbdbf 1px`
        : `solid #555a61 1px`,
    color: theme.palette.text.primary,
    borderRadius: 5,
    height: 42,
    padding: 0,
    margin: 0,
    '&:hover': {
      border:
        theme.palette.type === 'light' ? `solid black 1px` : `solid white 1px`,
      backgroundColor: '#ffffff00'
    }
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#ffffff00'
    }
  },
  icon: {
    color: theme.palette.type === 'light' ? `#536e7a` : `#9a9ca0`
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border:
      theme.palette.type === 'light'
        ? `solid #bcbdbf 1px`
        : `solid #555a61 1px`,
    zIndex: 50
  }
}));

const VIEW_TYPES_ICONS = {
  column: <BarIcon style={{ width: 14 }} />,
  lineal: <LineIcon style={{ width: 14 }} />,
  pie: <CakeIcon style={{ width: 14 }} />,
  sources: <SourceIcon style={{ width: 14 }} />,
  temperatures: <TemperatureIcon style={{ width: 14 }} />,
  statuses: <StatusIcon style={{ width: 14 }} />,
  lead: <LeadIcon style={{ width: 14 }} />,
  appointment: <AppointmentIcon style={{ width: 14 }} />,
  visit: <VisitIcon style={{ width: 14 }} />,
  sold: <SoldIcon style={{ width: 14 }} />,
  funnel: <Triangle style={{ width: 14 }} />,
  'general table': <Folder style={{ width: 14 }} />,
  drilldown: <BarIcon style={{ width: 14 }} />,
  grouped: <StatusIcon style={{ width: 14 }} />
};

const ApexChartsView = ({
  setOpen,
  modalData,
  //Gets for resources
  getMakes,
  getStores,
  getAgents,
  getSources,
  getVehicles,

  //Sets for filters (query)
  setMake,
  setStore,
  setVehicle,
  setSource,
  setAgent,
  setCarType,
  setArea,
  setCallType,
  callType = '',
  setLeadType,
  leadType = '',
  setLeadStatus,
  setDateType,
  setAgrupado,
  setIndicador,
  leadStatusSearch = 'Lead',
  setHighlighted,

  //vs
  handleSelectStore,
  handleAddStore,
  storesIds,
  categories,
  open,
  setStoresIds,
  setCategories,

  //Button group
  translation,
  view,
  setView,
  category,
  setCategory,
  categoryTypes,
  viewTypes,
  isDrill,

  //Is Searching a store?
  setSearch,

  filters,

  optionAll = 'All',

  // Messages
  setDirection,

  serieName,
  setSerieName,
  disableMake,
  quantity
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { sources } = useSource();
  const { stores, allStores } = useStore();
  const { makes } = useMake();
  const { agents } = useUser();
  const { vehicles, getAllVehicles } = useVehicle();
  const [rowSize, setRowSize] = useState(6);
  //Here we store the actual values of the filters
  const [areas, setAreas] = useState([]);
  const [currents, setCurrents] = useState({
    make: null,
    store: null,
    agent: null,
    source: null
  });

  const [currentVehicles, setCurrentVehicles] = useState([]);

  useEffect(() => {
    setCurrentVehicles(vehicles);
  }, [vehicles]);

  useEffect(() => {
    var newCurrentVehicles = [];
    if ((currents.store || currents.make) && setVehicle) {
      if (currents.make) {
        newCurrentVehicles = vehicles.filter(
          vehicle => vehicle.make._id === currents.make
        );
      } else if (currents.store) {
        const storeIndex = stores.findIndex(
          store => store._id === currents.store
        );
        newCurrentVehicles = vehicles.filter(
          vehicle => vehicle.make._id === stores[storeIndex].make._id
        );
      }
    }
    setCurrentVehicles(
      newCurrentVehicles.length > 0 ? newCurrentVehicles : vehicles
    );
    //eslint-disable-next-line
  }, [currents]);

  const handleSetAllLeadTypes = initialAreas => {
    let types = initialAreas || areas;
    if (setLeadType) setLeadType(`&leadType=${types.join()}`);
  };

  const handleSetAllAreas = initialAreas => {
    let aux = [];
    if (isRockstar(user.role._id) || isSuper(user.role._id))
      aux = ['nuevos', 'seminuevos', 'bdc', 'servicio', 'refacciones', 'hyp'];
    else {
      if (
        user.carType &&
        areas.includes('digital') &&
        (user.carType === 'nuevo' || user.carType === 'ambos')
      )
        aux.push('nuevos');
      if (
        user.carType &&
        areas.includes('digital') &&
        (user.carType === 'seminuevo' || user.carType === 'ambos')
      )
        aux.push('seminuevos');
      if (areas.includes('bdc')) aux.push('bdc');
      if (areas.includes('hyp')) aux.push('hyp');
      if (areas.includes('refacciones')) aux.push('refacciones');
      if (areas.includes('servicio')) aux.push('servicio');
    }

    if (setArea) setArea(`&area=${aux.join()}`);
  };

  const defaultAreas = [
    'digital',
    'showroom',
    'bdc',
    'servicio',
    'hyp',
    'refacciones'
  ];

  useEffect(() => {
    //We find the resources here by role
    if (user && user.role) {
      if (getSources) getSources(user.role._id);
      if (getMakes) getMakes(user.role._id);
      if (getStores) getStores(user.role._id);
      if (setVehicle) getAllVehicles();

      if (
        isSuper(user.role._id) ||
        isMarketing(user.role._id) ||
        isGeneralManager(user.role._id) ||
        isSalesManager(user.role._id)
      ) {
        if (getAgents) {
          getAgents({ role: user.role._id, store: user.stores });
        }
      }
      if (isAdminBDC(user.role._id))
        getAgents({
          store: user.stores,
          tier: roles.userBDC
        });
      if (isAdmin(user.role._id))
        if (getAgents) getAgents({ store: user.stores });
      if (isRockstar(user.role._id)) if (getAgents) getAgents();

      let aux;

      if (isRockstar(user.role._id) || isSuper(user.role._id))
        setAreas(defaultAreas);
      if (!isRockstar(user.role._id) && !isSuper(user.role._id)) {
        aux = user.areas || [];
        setAreas(aux);
      }
      handleSetAllLeadTypes(aux || defaultAreas);
    }

    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (quantity) {
      if (quantity === 2) return setRowSize(3);
    } else {
      return setRowSize(2);
    }

    //eslint-disable-next-line
  }, [filters, stores]);

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      <Grid container spacing={2} className={classes.root}>
        {filters.includes('make') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Make')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              name="make"
              disabled={isDrill ? true : false}
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query for make, store and agent
                  if (setStore) setStore('');
                  if (setAgent) setAgent('');
                  if (setSearch) setSearch(false);

                  //Set currents values
                  setCurrents({
                    ...currents,
                    make: null,
                    store: null,
                    agent: null
                  });
                } else {
                  //Set make query
                  if (setStore && !disableMake) {
                    if (
                      getUserStoresByMake(stores, e.target.value).length > 0
                    ) {
                      setStore(
                        `&store[in]=${getUserStoresByMake(
                          stores,
                          e.target.value
                        )}`
                      );
                    } else {
                      setStore(`&store[in]=000000000000`);
                    }
                  }
                  if (setSerieName && serieName) {
                    const { options, selectedIndex } = e.target;
                    setSerieName({
                      ...serieName,
                      store: options[selectedIndex].innerHTML
                    });
                  }
                  if (setSearch) setSearch(true);
                  if (handleAddStore) {
                    let aux = [];
                    let names = [];

                    let storesBymake = getStoresByMake(stores, e.target.value);
                    storesBymake.map(store => {
                      if (storesIds.indexOf(store._id) === -1) {
                        storesIds.map(item => aux.push(item));
                        aux.push(store._id);

                        categories.map(item => names.push(item));
                        names.push(store.name);
                      } else {
                        aux = storesIds.filter(item => item !== store._id);
                        names = categories.filter(item => item !== store.name);
                      }
                      return false;
                    });

                    if (names.length > 0 && aux.length > 0) {
                      setStoresIds(aux);
                      setCategories(names);
                    } else {
                      setStoresIds(['0000000000000000']);
                      setCategories('No data');
                    }
                  }
                  //set currents values
                  setCurrents({
                    ...currents,
                    make: e.target.value,
                    store: null,
                    agent: null
                  });
                }
              }}
              // disabled={user && (isRockstar(user.role._id)|| isSuper(user.role._id)) ? false : true}
              select
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.${optionAll}`)}
              </option>

              {makes &&
                makes.map(make => {
                  if (user && user.role && isRockstar(user.role._id))
                    return (
                      <option key={make._id} value={make._id} make={make.name}>
                        {CapitalizeNames(make.name)}
                      </option>
                    );
                  else if (
                    user &&
                    user.group &&
                    makesByGroup[user.group._id].includes(make._id)
                  )
                    return (
                      <option key={make._id} value={make._id} make={make.name}>
                        {CapitalizeNames(make.name)}
                      </option>
                    );
                  return false;
                })}
            </TextField>
          </Grid>
        )}

        {filters.includes('makeId') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Make')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              name="make"
              disabled={isDrill ? true : false}
              onChange={e => {
                if (e.target.value === '-') {
                  //Clear the query for make, store and agent
                  if (setMake) setMake('');

                  //Set currents values
                  setCurrents({
                    ...currents,
                    make: null,
                    store: null
                  });
                } else {
                  setMake(`&make=${e.target.value}`);
                  //set currents values
                  setCurrents({
                    ...currents,
                    make: e.target.value,
                    store: null,
                    agent: null
                  });
                }
              }}
              // disabled={user && (isRockstar(user.role._id)|| isSuper(user.role._id)) ? false : true}
              select
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'-'}>
                {t(`Tabs.${optionAll}`)}
              </option>

              {makes &&
                makes.map(make => {
                  if (user && user.role && isRockstar(user.role._id))
                    return (
                      <option key={make._id} value={make._id} make={make.name}>
                        {CapitalizeNames(make.name)}
                      </option>
                    );
                  else if (
                    user &&
                    user.group &&
                    makesByGroup[user.group._id].includes(make._id)
                  )
                    return (
                      <option key={make._id} value={make._id} make={make.name}>
                        {CapitalizeNames(make.name)}
                      </option>
                    );
                  return false;
                })}
            </TextField>
          </Grid>
        )}

        {filters.includes('store-vs') && (
          <Grid item xs={4} md={4}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {t('Charts.Store')}
            </Typography>
            <List className={classes.list}>
              <ListItem
                button
                onClick={handleSelectStore}
                className={classes.listItem}
                style={{ padding: 0, paddingLeft: 10 }}
              >
                <ListItemText primary={t('AdvancedAll.Stores')} />
                {open ? (
                  <Up className={classes.icon} />
                ) : (
                  <Down className={classes.icon} />
                )}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List className={classes.paper}>
                  {stores &&
                    stores.map(store => (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleAddStore(store._id, store.name)}
                      >
                        <Checkbox
                          checked={storesIds.indexOf(store._id) > -1}
                          onClick={() => handleAddStore(store._id, store.name)}
                        />
                        <ListItemText primary={CapitalizeNames(store.name)} />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </List>
          </Grid>
        )}
        {filters.includes('store') &&
          user &&
          user.role &&
          (isRockstar(user.role._id) ||
            isSuper(user.role._id) ||
            isCarokou(user.role._id) ||
            isGeneralManager(user.role._id) ||
            isSalesManager(user.role._id) ||
            isDigitalMkt(user.role._id) ||
            ((isAdmin(user.role._id) ||
              isAdminBDC(user.role._id) ||
              isMarketing(user.role._id)) &&
              stores &&
              stores.length > 1)) && (
            <Grid item xs={rowSize} md={rowSize}>
              <Typography
                variant="body1"
                style={{ fontSize: 12 }}
                color="textPrimary"
              >
                {' '}
                {t('Charts.Store')}{' '}
              </Typography>
              <TextField
                inputProps={{ className: classes.input }}
                fullWidth
                disabled={isDrill ? true : false}
                name="store"
                onChange={e => {
                  if (e.target.value === '') {
                    //Clear the query for store and agent
                    if (setStore) setStore('');
                    if (setAgent) setAgent('');

                    //We set search false to use the stores of the group instead of source query
                    if (setSearch) setSearch(false);

                    //set currents values
                    setCurrents({
                      ...currents,
                      store: null,
                      agent: null
                    });
                  } else {
                    //set store query
                    if (setStore) {
                      setStore(`&store[in]=${e.target.value}`);
                      if (setAgent) setAgent('');
                    }
                    if (setSerieName && serieName) {
                      const { options, selectedIndex } = e.target;
                      setSerieName({
                        ...serieName,
                        store: options[selectedIndex].innerHTML
                      });
                    }

                    //We set search true to use the store selected
                    if (setSearch) setSearch(true);

                    //set currents values
                    setCurrents({
                      ...currents,
                      store: e.target.value,
                      agent: null
                    });
                  }
                }}
                select
                variant="outlined"
                SelectProps={{ native: true }}
                value={currents.store}
              >
                <option key={0} value={''}>
                  {t(`Tabs.${optionAll}`)}
                </option>
                {stores &&
                  stores.map(store => (
                    <option
                      key={store._id}
                      value={store._id}
                      twilio={store.twilioNumber}
                      fb={store.fbPage}
                    >
                      {CapitalizeNames(store.name)}
                    </option>
                  ))}
              </TextField>
            </Grid>
          )}

        {filters.includes('storeVs') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Store')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="store"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query for store and agent
                  if (setStore) setStore('');
                  if (setAgent) setAgent('');

                  //We set search false to use the stores of the group instead of source query
                  if (setSearch) setSearch(false);

                  //set currents values
                  setCurrents({
                    ...currents,
                    store: null,
                    agent: null
                  });
                } else {
                  //set store query
                  if (setStore) {
                    setStore(`&store[in]=${e.target.value}`);
                    if (setAgent) setAgent('');
                  }
                  if (setSerieName && serieName) {
                    const { options, selectedIndex } = e.target;
                    setSerieName({
                      ...serieName,
                      store: options[selectedIndex].innerHTML
                    });
                  }

                  //We set search true to use the store selected
                  if (setSearch) setSearch(true);

                  //set currents values
                  setCurrents({
                    ...currents,
                    store: e.target.value,
                    agent: null
                  });
                }
              }}
              select
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.${optionAll}`)}
              </option>
              {allStores &&
                allStores.map(store => (
                  <option
                    key={store._id}
                    value={store._id}
                    twilio={store.twilioNumber}
                    fb={store.fbPage}
                  >
                    {' '}
                    {CapitalizeNames(store.name)}
                  </option>
                ))}
            </TextField>
          </Grid>
        )}

        {filters.includes('vehicle') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Vehicles')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              name="model"
              onChange={e => {
                if (setVehicle)
                  setVehicle(
                    e.target.value !== '' ? `&vehicle=${e.target.value}` : ''
                  );
                if (setSerieName && serieName) {
                  const { options, selectedIndex } = e.target;
                  setSerieName({
                    ...serieName,
                    vehicle: options[selectedIndex].innerHTML
                  });
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.${optionAll}`)}
              </option>

              {currentVehicles &&
                currentVehicles.map(model => (
                  <option key={model._id} value={model._id}>
                    {CapitalizeNames(model.make.name + ' ' + model.model)}
                  </option>
                ))}
            </TextField>
          </Grid>
        )}

        {filters.includes('agent') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Agents')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              disabled={isDrill ? true : false}
              fullWidth
              name="agent"
              onChange={e => {
                if (e.target.value === '') {
                  //clear query for agent
                  if (setAgent) setAgent('');

                  //Set current values
                  setCurrents({
                    ...currents,
                    agent: null
                  });
                } else {
                  //Set agent query
                  if (setAgent) setAgent(`&agent=${e.target.value}`);
                  if (setSerieName && serieName) {
                    const { options, selectedIndex } = e.target;
                    setSerieName({
                      ...serieName,
                      agent: options[selectedIndex].innerHTML
                    });
                  }
                  //Set current values
                  setCurrents({
                    ...currents,
                    agent: e.target.value
                  });
                }
              }}
              select
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.${optionAll}`)}
              </option>

              {agents &&
                agents.map(agent => {
                  if (!currents.store) {
                    return (
                      <option
                        key={agent._id}
                        value={agent._id}
                        agent={agent.name}
                      >
                        {CapitalizeNames(agent.name)}
                      </option>
                    );
                  } else if (currents.store) {
                    if (
                      getMultiStoresIds(agent.stores).includes(currents.store)
                    )
                      return (
                        <option
                          key={agent._id}
                          value={agent._id}
                          agent={agent.name}
                        >
                          {CapitalizeNames(agent.name)}
                        </option>
                      );
                  } else if (currents.store) {
                    if (
                      getMultiStoresIds(agent.stores).includes(currents.store)
                    )
                      return (
                        <option
                          key={agent._id}
                          value={agent._id}
                          agent={agent.name}
                        >
                          {CapitalizeNames(agent.name)}
                        </option>
                      );
                  } else if (currents.make) {
                    if (
                      getMultiStoresMakesIds(agent.stores).includes(
                        currents.make
                      )
                    )
                      return (
                        <option
                          key={agent._id}
                          value={agent._id}
                          agent={agent.name}
                        >
                          {CapitalizeNames(agent.name)}
                        </option>
                      );
                  }
                  return false;
                })}
            </TextField>
          </Grid>
        )}

        {filters.includes('source') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Charts.Source')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={(isDrill ? true : false) || callType !== ''}
              name="source"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setSource) setSource('');

                  //Set current values
                  setCurrents({
                    ...currents,
                    source: null
                  });
                } else {
                  //Set query source
                  if (setSource) setSource(`&source=${e.target.value}`);
                  if (setSerieName && serieName) {
                    const { options, selectedIndex } = e.target;
                    setSerieName({
                      ...serieName,
                      source: options[selectedIndex].innerHTML
                    });
                  }
                  //Set current values
                  setCurrents({
                    ...currents,
                    source: e.target.value
                  });
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>

              {sources &&
                sources.map(source => (
                  <option key={source._id} value={source._id}>
                    {CapitalizeNames(source.name)}
                  </option>
                ))}
            </TextField>
          </Grid>
        )}

        {/* {
               filters.length !== 0 && filters.length % 5 === 0 && <Grid item xs={2} md={2}/>
            } */}

        {filters.includes('carType') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Leads.CarType')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="carType"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setCarType) setCarType('');
                } else {
                  //Set query source
                  if (setCarType) setCarType(`&carType=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>
              <option selected key={1} value={'nuevo'}>
                {t('Leads.Nuevo')}
              </option>
              <option key={2} value={'seminuevo'}>
                {t('Leads.Seminuevo')}
              </option>
            </TextField>
          </Grid>
        )}
        {filters.includes('area') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Users.Areas')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="area"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setArea) handleSetAllAreas();
                } else {
                  //Set query source
                  if (setArea) setArea(`&area=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>
              <option selected key={1} value={'nuevos'}>
                {t('Leads.Nuevo')}
              </option>
              <option key={2} value={'seminuevos'}>
                {t('Leads.Seminuevo')}
              </option>
              <option key={3} value={'bdc'}>
                {t('BDC')}
              </option>
              <option key={4} value={'servicio'}>
                {t('Leads.Servicio')}
              </option>
              <option key={5} value={'hyp'}>
                HYP
              </option>
              <option key={6} value={'refacciones'}>
                {t('Users.Refacciones')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('callType') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Leads.CallType')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="callType"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setCallType) setCallType('');
                } else {
                  //Set query source

                  if (setCallType) setCallType(`&callType=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>
              <option key={1} value={'dpx'}>
                Dpx
              </option>
              <option key={2} value={'calls'}>
                Calls
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('leadType') && areas.length > 0 && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Users.LeadType')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="leadType"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setLeadType) handleSetAllLeadTypes();
                } else {
                  //Set query source
                  if (setLeadType) setLeadType(`&leadType=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option value={''}>{t('Leads.all')}</option>

              {areas.map((item, index) => (
                <option selected={index === 0} value={item}>
                  {t(`Leads.${item}`)}
                </option>
              ))}
            </TextField>
          </Grid>
        )}
        {filters.includes('openModal') && setOpen && (
          <Grid item xs={rowSize} md={rowSize}>
            <Button
              disabled={modalData.length <= 0}
              variant="contained"
              color="primary"
              style={{ marginTop: '17px', padding: '6px' }}
              fullWidth
              onClick={() => setOpen(true)}
            >
              Vista Monitoreo
            </Button>
          </Grid>
        )}

        {filters.includes('direction') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('Forms.Direction')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="direction"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setDirection) setDirection('');
                } else {
                  //Set query source
                  if (setDirection)
                    setDirection(`&Direction=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>
              <option key={1} value={'inbound'}>
                {t('Chat.incoming-api')}
              </option>
              <option key={2} value={'outbound'}>
                {t('Chat.outbound-api')}
              </option>
              {/* <option key={3} value={'no-direction'}>{t("traducir.no-direction")}</option> */}
            </TextField>
          </Grid>
        )}
        {filters.includes('leadStatus') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.Indicador')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="leadStatus"
              value={leadStatusSearch}
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setLeadStatus) setLeadStatus('');
                } else {
                  //Set query source
                  if (setLeadStatus) setLeadStatus(`${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'Lead'}>
                {t('Leads.Lead')}
              </option>
              <option key={2} value={'Appointment'}>
                {t('Leads.Appointment')}
              </option>
              <option key={1} value={'Visit'}>
                {t('Leads.Visit')}
              </option>
              <option key={3} value={'ClosureInformation'}>
                {t('Leads.Sold')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('dateType') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.DateType')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="dateType"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setDateType) setDateType('');
                } else {
                  //Set query source
                  if (setDateType) setDateType(`${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'arrival'}>
                {t('Charts.Arrival')}
              </option>
              <option key={1} value={'follow'}>
                {t('Charts.Follow')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('highlighted') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.Highlighted')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="dateType"
              onChange={e => {
                if (setHighlighted) setHighlighted(`${e.target.value}`);
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'all'}>
                {t('Charts.All')}
              </option>
              <option key={1} value={'correct'}>
                {t('Charts.Correct')}
              </option>
              <option key={1} value={'wrong'}>
                {t('Charts.Wrong')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('indicador') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.Indicador')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="indicador"
              onChange={e => {
                //Set query source
                if (setIndicador) setIndicador(`${e.target.value}`);
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'lead'}>
                {t('Leads.Lead')}
              </option>
              <option key={1} value={'appointment'}>
                {t('Leads.Appointment')}
              </option>
              <option key={2} value={'visit'}>
                {t('Leads.Visit')}
              </option>
              <option key={3} value={'sold'}>
                {t('Leads.Sold')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('agrupado') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.Agrupado')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="grouped"
              onChange={e => {
                //Set query source
                if (setAgrupado) setAgrupado(`${e.target.value}`);
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={'all'}>
                {t('Charts.GroupedAll')}
              </option>
              <option key={1} value={'temperatures'}>
                {t('Charts.GroupedTemp')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters.includes('Direction') && (
          <Grid item xs={rowSize} md={rowSize}>
            <Typography
              variant="body1"
              style={{ fontSize: 12 }}
              color="textPrimary"
            >
              {' '}
              {t('BreadCumbs.Direction')}{' '}
            </Typography>
            <TextField
              inputProps={{ className: classes.input }}
              fullWidth
              disabled={isDrill ? true : false}
              name="direction"
              onChange={e => {
                if (e.target.value === '') {
                  //Clear the query of source
                  if (setDirection) setDirection('');
                } else {
                  //Set query source
                  if (setDirection)
                    setDirection(`&Direction=${e.target.value}`);
                }
              }}
              select
              required
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key={0} value={''}>
                {t(`Tabs.All`)}
              </option>
              <option key={1} value={'inbound'}>
                {t('Calls.Inbound')}
              </option>
              <option key={2} value={'outbound'}>
                {t('Calls.Outbound')}
              </option>
            </TextField>
          </Grid>
        )}

        {filters && filters.length !== 0 && filters.length % 6 !== 0 && (
          <Grid item xs={12 - (filters.length % 6) * 2} />
        )}

        {categoryTypes && (
          <Grid item xs={6} md={6} container direction="row">
            <ButtonGroup
              color="primary"
              size="large"
              style={{ marginRight: '1em', width: '100%' }}
            >
              {categoryTypes.map(option => (
                <Button
                  disabled={isDrill ? true : false}
                  style={{ textTransform: 'capitalize' }}
                  variant={category === option ? 'contained' : 'outlined'}
                  onClick={e => {
                    if (isDrill === undefined || isDrill === false)
                      setCategory(option);
                  }}
                >
                  {option === 'agents' && <AgentsIcon />}
                  {option === 'all' && <GeneralIcon />}
                  <p style={{ marginLeft: 5, fontSize: 14 }}>
                    {t(`Charts.${option}`)}
                  </p>
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        )}

        {viewTypes && (
          <Grid item xs={6} container direction="row">
            <ButtonGroup
              color="primary"
              size="large"
              style={{ marginRight: '1em', width: '100%' }}
            >
              {viewTypes.map(option => (
                <Button
                  disabled={isDrill ? true : false}
                  style={{ textTransform: 'capitalize' }}
                  variant={view === option ? 'contained' : 'outlined'}
                  startIcon={
                    VIEW_TYPES_ICONS[option] ? VIEW_TYPES_ICONS[option] : null
                  }
                  onClick={e => {
                    if (isDrill === undefined || isDrill === false)
                      setView(option);
                  }}
                >
                  <p style={{ marginLeft: 5, fontSize: 12 }}>
                    {t(`Charts.${option}`)}
                  </p>
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        )}

        {translation && (
          <Grid item xs={6} container direction="row">
            <Grid container spacing={2}>
              <Grid item sm={3} md={2} display="flex">
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#761b90',
                      height: 20,
                      width: 20,
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontSize: 16 }}
                  >
                    Total
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} md={2}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#6F6CC9',
                      height: 20,
                      width: 20,
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontSize: 16 }}
                  >
                    {t('Status.extra hot')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} md={2}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#e16a6b',
                      height: 20,
                      width: 20,
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontSize: 16 }}
                  >
                    {t('Status.hot')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} md={2}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#f2a94c',
                      height: 20,
                      width: 20,
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontSize: 16 }}
                  >
                    {t('Status.warm')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} md={2}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#4bb5d7',
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontSize: 16 }}
                  >
                    {t('Status.cold')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ApexChartsView;
