import React, { useReducer } from 'react';
import MakeContext from './makeContext';
import MakeReducer from './makeReducer';
import api from '../../api/api';
import {
  GET_MAKES,
  GET_MAKE,
  CREATE_MAKE,
  DELETE_MAKE,
  UPDATE_MAKE,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  SET_MAKES
} from '../types';

const MakeState = props => {
  const initialState = {
    makes: [],
    make: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(MakeReducer, initialState);

  const setMakesUser = async makes =>
    dispatch({ type: SET_MAKES, payload: makes });

  const getMakesByGroup = async groupId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearMakeState();
    setLoading();
    try {
      const res = groupId
        ? await api.get(`/groups/${groupId}/makes`)
        : await api.get(`/groups/makes`, config);
      dispatch({ type: GET_MAKES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get makes
  const getMakesAr = async (pagination, query, typeQuery, queryExtra) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    if (!queryExtra) queryExtra = '';
    try {
      const res = await api.get(
        `/makes/makesAr?page=${pagination.page}&searchIndex=name&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1${queryExtra}`,
        config
      );
      dispatch({
        type: GET_MAKES,
        payload: res.data.data,
        count: res.data.pagination.total
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Make
  const getMake = async makeId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearMakeState();
    setLoading();
    try {
      const res = await api.get(`/makes/${makeId}`, config);
      dispatch({ type: GET_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create Make
  const createMake = async make => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearMakeState();
    setLoading();
    try {
      const res = await api.post(`/makes`, { ...make }, config);
      dispatch({ type: CREATE_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Make
  const deleteMake = async makeId => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/makes/${makeId}`, config);
      dispatch({ type: DELETE_MAKE, payload: res.data.deletedId });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Make
  const updateMake = async (make, makeId, file) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (file) {
      let uploadConfig = await api.post(
        '/medias/images',
        { type: file.type, fileName: file.name },
        config
      );

      await api.put(uploadConfig.data.url, file, {
        headers: { 'Content-Type': file ? file.type : null }
      });

      make.image = `${uploadConfig.data.key}`;
    }
    setLoading();

    try {
      const res = await api.put(`/makes/${makeId}`, { ...make }, config);
      dispatch({ type: UPDATE_MAKE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearMakeState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const AdvancedResults = async (pagination, query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.get(
        `/makes/makesAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`,
        config
      );
      dispatch({
        type: GET_MAKES,
        payload: res.data.data,
        count: res.data.count
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Makes
  const getMakes = async query => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearMakeState();
    setLoading();
    try {
      if (!query) query = '';
      const res = await api.get(`/makes?sort=name${query}`, config);
      dispatch({ type: GET_MAKES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  return (
    <MakeContext.Provider
      value={{
        loading: state.loading,
        makes: state.makes,
        make: state.make,
        error: state.error,
        count: state.count,
        getMakes,
        createMake,
        getMake,
        deleteMake,
        AdvancedResults,
        updateMake,
        setLoading,
        clearMakeState,
        getMakesByGroup,
        getMakesAr,
        setMakesUser
      }}
    >
      {props.children}
    </MakeContext.Provider>
  );
};

export default MakeState;
