import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Calendar from 'components/Calendar';
import useAuth from 'hooks/useAuth';
import useStore from 'hooks/useStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { CapitalizeNames } from 'utils/capitalize';
import Down from '@material-ui/icons/ArrowDropDown';
import Up from '@material-ui/icons/ArrowDropUp';
import FilterCarType from 'components/filterCarTypeDash';
import FilterLeadType from 'components/filterLeadTypeDash';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  sync: {
    fontSize: '20px',
    cursor: 'pointer',
    color: theme.palette.text.primary
  },
  icon: {
    color: theme.palette.type === 'light' ? `#536e7a` : `#9a9ca0`
  },
  list: {
    display: 'inline-block',
    marginRight: 10,
    border:
      theme.palette.type === 'light'
        ? `solid #bcbdbf 1px`
        : `solid #555a61 1px`,
    color: theme.palette.text.primary,
    borderRadius: 5,
    height: 44,
    width: '100%',
    padding: 0,
    margin: 0,
    '&:hover': {
      border:
        theme.palette.type === 'light' ? `solid black 1px` : `solid white 1px`,
      backgroundColor: '#ffffff00'
    }
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#ffffff00'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border:
      theme.palette.type === 'light'
        ? `solid #bcbdbf 1px`
        : `solid #555a61 1px`,
    zIndex: 50
  }
}));

const Header = ({
  className,
  setFilter,
  setDate,
  setTitleDate,
  loading,
  getFilter,
  handleReload,
  totalLeads,
  setStores,
  carType,
  leadType,
  setCarType,
  setLeadType,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { stores } = useStore();
  const { t } = useTranslation();
  const today = new Date();
  const curHr = today.getHours();
  const [open, setOpen] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedStoresNames, setSelectedStoresNames] = useState([]);
  const [title, setTitle] = useState('');

  let greeting;

  if (curHr < 12) {
    greeting = t('Dashboard.Morning');
  } else if (curHr < 18) {
    greeting = t('Dashboard.Afternoon');
  } else {
    greeting = t('Dashboard.Evening');
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddStore = (id, name) => {
    let aux = [];
    let names = [];

    if (selectedStores.indexOf(id) === -1) {
      selectedStores.map(item => aux.push(item));
      aux.push(id);

      selectedStoresNames.map(item => names.push(item));
      names.push(CapitalizeNames(name));
    } else {
      aux = selectedStores.filter(item => item !== id);
      names = selectedStoresNames.filter(
        item => item !== CapitalizeNames(name)
      );
    }

    setSelectedStores(aux);
    setSelectedStoresNames(names);

    if (aux.length > 0) {
      setStores(`&store[in]=${aux}`);
    } else {
      setStores('');
    }
  };

  useEffect(() => {
    let string = '';
    selectedStoresNames.map((name, index) => {
      string += name;
      if (index !== selectedStoresNames.length - 1) {
        string += ' - ';
      }
      return false;
    });

    if (selectedStoresNames.length > 0) {
      setTitle(string);
    } else {
      setTitle('');
    }

    //eslint-disable-next-line
  }, [selectedStoresNames]);

  return (
    <Grid
      spacing={2}
      container
      justifyContent="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item xs={8} md={8} lg={6} sm={8} style={{ position: 'relative' }}>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ textTransform: 'uppercase' }}
        >
          Dashboard
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ textTransform: 'capitalize' }}
        >
          {greeting}, {user && user.name}
        </Typography>

        <Typography variant="subtitle2" color="textPrimary">
          {title !== '' && `${t('Dashboard.FilterBy')}: ${title}`}
        </Typography>
      </Grid>

      <Calendar
        setDate={setDate}
        setFilter={setFilter}
        getFilter={getFilter}
        setTitleDate={setTitleDate}
      />

      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Grid container>
          <Grid item xs={10}>
            <FilterCarType carType={'ambos'} setCarType={setCarType} />
            <FilterLeadType leadType={'ambos'} setLeadType={setLeadType} />
          </Grid>
          {stores && stores.length > 0 && (
            <Grid item xs={2}>
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignItems={'center'}
              >
                <List className={classes.list}>
                  <ListItem
                    button
                    onClick={handleClick}
                    className={classes.listItem}
                    disabled={loading}
                    style={{ padding: 0, paddingLeft: 10 }}
                  >
                    <ListItemText primary={t('AdvancedAll.Stores')} />
                    {open ? (
                      <Up className={classes.icon} />
                    ) : (
                      <Down className={classes.icon} />
                    )}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List className={classes.paper}>
                      {stores &&
                        stores.map(store => (
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={() =>
                              handleAddStore(store._id, store.name)
                            }
                          >
                            <Checkbox
                              checked={selectedStores.includes(store._id)}
                              onClick={() =>
                                handleAddStore(store._id, store.name)
                              }
                            />
                            <ListItemText
                              primary={CapitalizeNames(store.name)}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </List>
                <Box style={{ display: 'inline-block' }}>
                  {loading === true ? (
                    <FontAwesomeIcon
                      className={classes.sync}
                      icon={faSync}
                      spin
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={classes.sync}
                      icon={faSync}
                      onClick={handleReload}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
