import React from 'react';
import useAuth from 'hooks/useAuth';
import DashboardRockstar from 'components/dashboard/DashboardRockstar';
import DashboardSuperAdmin from 'components/dashboard/DashboardSuperAdmin';
import DashboardAdmin from 'components/dashboard/DashboardAdmin';
import DashboardUser from 'components/dashboard/DashboardUser';
import DashboardCarone from 'components/dashboard/DashboardCarone';

import {
  isAdmin,
  isCarokou,
  isDigitalMkt,
  isGeneralManager,
  isMarketing,
  isRH,
  isRockstar,
  isSalesManager,
  isSuper,
  isUser
} from 'utils/AuthRoles';

const DashboardView = ({ className, ...rest }) => {
  const { user } = useAuth();

  if (user && user.role && isCarokou(user.role._id)) {
    return <DashboardCarone {...rest} />;
  }

  if (user && user.role && isRockstar(user.role._id)) {
    return <DashboardRockstar {...rest} />;
  }

  if (
    user &&
    user.role &&
    (isSuper(user.role._id) ||
      isRH(user.role._id) ||
      isDigitalMkt(user.role._id) ||
      isGeneralManager(user.role._id) ||
      isSalesManager(user.role._id))
  ) {
    return <DashboardSuperAdmin {...rest} />;
  }

  if (
    user &&
    user.role &&
    (isAdmin(user.role._id) || isMarketing(user.role._id))
  ) {
    return <DashboardAdmin {...rest} />;
  }

  if (user && user.role && isUser(user.role._id)) {
    return <DashboardUser {...rest} />;
  }

  return null;
};

export default DashboardView;
