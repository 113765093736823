import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { isRockstar, isSuper } from 'utils/AuthRoles';
import { Person } from '@material-ui/icons';

const ICONS_AREAS = {
  all: <Person />,
  digital: <Person />,
  showroom: <Person />,
  bdc: <Person />,
  servicio: <Person />,
  hyp: <Person />,
  refacciones: <Person />
};

const Header = ({ className, leadType, setLeadType, ...rest }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    if (user && user.role) {
      if (isRockstar(user.role._id) || isSuper(user.role._id))
        setAreas([
          'all',
          'digital',
          'showroom',
          'bdc',
          'servicio',
          'hyp',
          'refacciones'
        ]);
      if (!isRockstar(user.role._id) && !isSuper(user.role._id)) {
        let aux = user.areas || [];
        if (aux.length > 1) aux.unshift('all');
        setAreas(aux);
      }
    }
    //eslint-disable-next-line
  }, [user]);

  return (
    <>
      {areas.length > 0 && (
        <ButtonGroup color="primary" style={{ marginRight: '1em' }}>
          {areas.map(item => (
            <Button
              style={{ textTransform: 'capitalize' }}
              variant={
                leadType === `&leadType=${item}` ||
                (leadType === '' && item === 'all')
                  ? 'contained'
                  : 'outlined'
              }
              onClick={e => {
                if (item === 'all') setLeadType('');
                else setLeadType(`&leadType=${item}`);
              }}
            >
              {ICONS_AREAS[item]}
              <p style={{ marginLeft: 5, fontSize: 12 }}>
                {t(`Leads.${item}`)}
              </p>
            </Button>
          ))}
        </ButtonGroup>
      )}
    </>
  );
};

export default Header;
