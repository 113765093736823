import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useChart from 'hooks/useChart';
import PieChartAP from 'components/PieAPEX';
import ColumnChartAP from 'components/ColumnAPEX';
import numeral from 'numeral';
import TotalRepeaterCard from '../TotalRepeaterCard';
import { getMultiStoresIds } from 'utils/storesUser';
import useAuth from 'hooks/useAuth';
import useStore from 'hooks/useStore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

const DashboardView = ({ className, getStores, ...rest }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState('month');
  const { t } = useTranslation();
  const [date, setDate] = useState(
    `&createdAt[gte]=${moment()
      .startOf('month')
      .format()}&createdAt[lt]=${moment()
      .endOf('month')
      .format()}`
  );
  const [titleDate, setTitleDate] = useState('');
  const [storesSearch, setStoresSearch] = useState('');
  const [custom, setCustom] = useState('D MMMM');
  const [carType, setCarType] = useState(null);
  const { user } = useAuth();
  const { stores } = useStore();
  const [leadType, setLeadType] = useState(null);

  const {
    clearCharts,
    loadingCharts,

    getTotalsDashboard,
    total,
    totalLeads,
    totalVisits,
    totalAppointments,
    totalSolds,

    pieStatus,

    getLeadsMonthlyChart,
    leadsMonthlyChart,

    closureTopUsers,
    getClosureTopUsers,

    agentsCallsChart,
    getAgentsCallsChart,

    getConversationsByAgentChart,
    conversationsByAgentChart
  } = useChart();

  const [totales, setTotales] = useState([
    {
      size: 3,
      title: t('Status.lead3'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.appointment2'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.visit2'),
      data: 0
    },
    {
      size: 3,
      title: t('Status.sold2'),
      data: 0
    }
  ]);

  useEffect(() => {
    if (
      total !== null &&
      totalLeads !== null &&
      totalVisits !== null &&
      totalSolds !== null
    ) {
      setTotales([
        {
          size: 3,
          title: t('Status.lead3'),
          data: numeral(totalLeads).format('0,0')
        },
        {
          size: 3,
          title: t('Status.appointment2'),
          data: numeral(totalAppointments).format('0,0')
        },
        {
          size: 3,
          title: t('Status.visit2'),
          data: numeral(totalVisits).format('0,0')
        },
        {
          size: 3,
          title: t('Status.sold2'),
          data: numeral(totalSolds).format('0,0')
        }
      ]);
    }
    //eslint-disable-next-line
  }, [total, totalLeads, totalAppointments, totalVisits, totalSolds]);

  useEffect(() => {
    if (user && user.role) {
      getStores(user.role._id);
      if (user.carType) {
        if (user.carType === 'ambos') {
          setCarType('nuevo');
        } else {
          setCarType(user.carType);
        }
      }

      if (user.leadType) {
        if (user.leadType === 'all') {
          setLeadType('');
        } else {
          if (user.leadType === 'lead') setLeadType(`&leadType=digital`);
          if (user.leadType === 'showroom') setLeadType(`&leadType=showroom`);
        }
      }
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    return () => clearCharts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && stores && stores.length > 0 && carType && leadType !== null) {
      let fil = '';
      switch (filter) {
        case 'today':
          setCustom('HH:00 a');
          fil = 'HH:00 a';
          break;
        case 'yesterday':
          setCustom('HH:00 a');
          fil = 'HH:00 a';
          break;
        case 'month':
          setCustom('D MMMM');
          fil = 'D MMMM';
          break;
        case 'pastMonth':
          setCustom('D MMMM');
          fil = 'D MMMM';
          break;
        case 'year':
          setCustom('MMMM');
          fil = 'MMMM';
          break;
        case 'custom':
          setCustom('DD MMMM');
          fil = 'DD MMMM';
          break;
        default:
          setCustom('DD');
          fil = 'DD';
          break;
      }

      getData(fil);
    }

    //eslint-disable-next-line
  }, [filter, stores, user, carType, date, leadType, storesSearch]);

  const getData = async (fil = 'DD') => {
    if (storesSearch === '') {
      getLeadsMonthlyChart(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}&filter=${fil}`
      );
      getClosureTopUsers(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}`
      );
      getAgentsCallsChart(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}`
      );
      getConversationsByAgentChart(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}`
      );
      getTotalsDashboard(
        `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
          stores
        )}`
      );
    } else {
      getLeadsMonthlyChart(
        `${date}&carType=${carType}${leadType}${storesSearch}&filter=${fil}`
      );
      getClosureTopUsers(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
      getAgentsCallsChart(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
      getConversationsByAgentChart(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
      getTotalsDashboard(
        `${date}&carType=${carType}${leadType}${storesSearch}`
      );
    }
  };

  const handleReload = async () => {
    if (stores && stores.length > 0) {
      if (storesSearch === '') {
        getLeadsMonthlyChart(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}&filter=${custom}`
        );
        getAgentsCallsChart(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
        getConversationsByAgentChart(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
        getClosureTopUsers(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
        getTotalsDashboard(
          `${date}&carType=${carType}${leadType}&store[in]=${getMultiStoresIds(
            stores
          )}`
        );
      } else {
        getLeadsMonthlyChart(
          `${date}&carType=${carType}${leadType}${storesSearch}&filter=${custom}`
        );
        getAgentsCallsChart(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getConversationsByAgentChart(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getClosureTopUsers(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
        getTotalsDashboard(
          `${date}&carType=${carType}${leadType}${storesSearch}`
        );
      }
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header
          setDate={setDate}
          getFilter={setFilter}
          loading={loadingCharts}
          handleReload={handleReload}
          setTitleDate={setTitleDate}
          setStores={setStoresSearch}
          totalLeads={total}
          carType={carType}
          setCarType={setCarType}
          leadType={leadType}
          setLeadType={setLeadType}
        />

        <Grid container spacing={2} style={{ marginBottom: '0.25em' }}>
          <TotalRepeaterCard loading={loadingCharts} data={totales} />
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <ColumnChartAP
              series={
                leadsMonthlyChart &&
                leadsMonthlyChart.bar &&
                leadsMonthlyChart.bar.series
                  ? leadsMonthlyChart.bar.series
                  : []
              }
              categories={
                leadsMonthlyChart && leadsMonthlyChart.categories
                  ? leadsMonthlyChart.categories
                  : []
              }
              title={
                filter === 'Custom'
                  ? titleDate
                  : t('Charts.Lo') + ' ' + t(`Calendar.${filter}`)
              }
              href="/extra/highcharts/monthly"
            />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <PieChartAP
              series={pieStatus && pieStatus.series ? pieStatus.series : []}
              categories={
                pieStatus && pieStatus.categories ? pieStatus.categories : []
              }
              title={t('Charts.Lbst')}
              href="/extra/highcharts/agentsStatus"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ColumnChartAP
              series={
                closureTopUsers && closureTopUsers.series
                  ? closureTopUsers.series
                  : []
              }
              categories={
                closureTopUsers && closureTopUsers.categories
                  ? closureTopUsers.categories
                  : []
              }
              title={t('Charts.Sba')}
              href="/extra/highcharts/agentsLeads"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ColumnChartAP
              series={
                agentsCallsChart && agentsCallsChart.series
                  ? agentsCallsChart.series
                  : []
              }
              categories={
                agentsCallsChart && agentsCallsChart.categories
                  ? agentsCallsChart.categories
                  : []
              }
              title={t('Charts.Cba')}
              href="/extra/highcharts/agentsCalls"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ColumnChartAP
              series={
                conversationsByAgentChart && conversationsByAgentChart.series
                  ? conversationsByAgentChart.series
                  : []
              }
              categories={
                conversationsByAgentChart &&
                conversationsByAgentChart.categories
                  ? conversationsByAgentChart.categories
                  : []
              }
              title={t('Charts.Coba')}
              href="/extra/highcharts/conversations"
            />
          </Grid>

          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <ColumnChartAP
              translate={true}
              type="Substatus-Categories"
              style={{ height: 640 }}
              series={substatusAgentChart && substatusAgentChart.series  ? substatusAgentChart.series : []}
              categories={substatusAgentChart && substatusAgentChart.categories ? substatusAgentChart.categories : []}
              title={storesNames + t('Charts.LSsS')}
              href='/#'
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardView;
