import {
  GET_STORES,
  CREATE_STORE,
  GET_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_STORES_BY_MAKE,
  GET_ALL_STORES,
  UPDATE_ALL_STORES,
  UPDATE_ATTRIBUTES_STORES
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_ATTRIBUTES_STORES:
      let storesChanged = [];
      if (state && state.stores) {
        storesChanged = state.stores.map(item => {
          return { ...item, ...action.payload };
        });
      }
      return {
        ...state,
        stores: storesChanged
      };
    case UPDATE_ALL_STORES:
      let aux = [];
      if (state && state.allStores) {
        state.allStores.map(store => {
          if (store._id === action.payload._id) {
            aux.push(action.payload);
          } else {
            aux.push(store);
          }
          return false;
        });
      }
      return {
        ...state,
        allStores: aux,
        loading: false
      };
    case GET_ALL_STORES:
      return {
        ...state,
        allStores: action.payload,
        loading: false
      };
    case GET_STORES:
      return {
        ...state,
        count: action.count,
        stores: action.payload,
        loading: false
      };
    case GET_STORES_BY_MAKE:
      return {
        ...state,
        stores: action.payload,
        loading: false
      };
    case GET_STORE:
      return {
        ...state,
        store: action.payload,
        loading: false
      };
    case CREATE_STORE:
      return {
        ...state,
        loading: false
      };
    case DELETE_STORE:
      state.stores = state.stores.filter(
        store => store._id.toString() !== action.payload.toString()
      );
      return {
        ...state,
        store: null,
        loading: false
      };
    case UPDATE_STORE:
      return {
        ...state,
        store: action.payload,
        loading: false
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        ...state,
        store: {},
        stores: [],
        loading: false,
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
