import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import Hero from './Hero';
import FAQS from './FAQS';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
    // alignItems: 'center'
  }
}));

const HomeView = () => {
  const classes = useStyles();

  if (!localStorage.getItem('token')) {
    return (
      <Page maxWidth="lg" className={classes.root} title="Home">
        <Hero />

        <FAQS />
      </Page>
    );
  } else {
    return <Redirect to="/management/leads" />;
  }
};

export default HomeView;
