import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  IconButton,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'components/GlobalStyles';
import ScrollReset from 'components/ScrollReset';
import GoogleAnalytics from 'components/GoogleAnalytics';

import useSettings from 'hooks/useSettings';
import { createThemeDPX } from 'theme';
import routes, { renderRoutes } from 'routes';
import AuthState from './contexts/auth/AuthState';
import LeadBusinessState from './contexts/leadBusiness/LeadBusinessState';
import LeadState from './contexts/lead/LeadState';
import StoreState from './contexts/store/StoreState';
import MakeState from './contexts/make/MakeState';
import SourceState from './contexts/source/SourceState';
import CommentState from './contexts/comment/CommentState';
import CommentBusinessState from './contexts/commentBusiness/CommentBusinessState';
import VehicleState from './contexts/vehicle/VehicleState';
import StatusState from './contexts/status/StatusState';
import AlertState from './contexts/alert/AlertState';
import UserState from './contexts/user/UserState';
import AppointmentState from './contexts/appointment/AppointmentState';
import MailState from './contexts/mail/MailState';
import TemperatureState from './contexts/temperature/TemperatureState';
import NotificationState from './contexts/notification/NotificationState';
import SubstatusState from './contexts/substatus/SubstatusState';
import ClosureInformationState from './contexts/closureInformation/ClosureInformationState';
import SettingsState from './contexts/settings/SettingsState';
import RecordingState from './contexts/recording/RecordingState';
import ChartState from './contexts/charts/ChartState';
import ReminderState from './contexts/reminder/ReminderState';
import TicketState from './contexts/ticket/TicketState';
import VisitState from './contexts/visit/VisitState';
import AdCampaignState from './contexts/adCampaign/AdCampaignState';
import RoleState from './contexts/role/RoleState';
import TemplateTwilioState from './contexts/templateTwilio/TemplateTwilioState';
import WspCampaignState from './contexts/wspCampaign/WspCampaignState';
import MediaState from './contexts/media/MediaState';
import VideoCallState from './contexts/videocalls/VideoCallState';
import RecordingTest from './contexts/recordingTest/RecordingTestState';
import DpxConversationsState from './contexts/dpxConversations/DpxConversationsState';

import { ExternalLink } from 'react-feather';
import OrderState from 'contexts/order/OrderState';
import QuotationState from 'contexts/quotation/QuotationState';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createThemeDPX({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            ref={notistackRef}
            action={key => (
              <IconButton onClick={onClickDismiss(key)}>
                <ExternalLink />
              </IconButton>
            )}
          >
            <Router history={history}>
              <NotificationState>
                <OrderState>
                  <QuotationState>
                    <MediaState>
                      <ReminderState>
                        <SettingsState>
                          <AlertState>
                            <AuthState>
                              <UserState>
                                <LeadState>
                                  <LeadBusinessState>
                                    <CommentState>
                                      <CommentBusinessState>
                                        <AppointmentState>
                                          <MakeState>
                                            <StoreState>
                                              <VehicleState>
                                                <SourceState>
                                                  <StatusState>
                                                    <MailState>
                                                      <TemperatureState>
                                                        <ClosureInformationState>
                                                          <RecordingState>
                                                            <ChartState>
                                                              <TicketState>
                                                                <VisitState>
                                                                  <SubstatusState>
                                                                    <AdCampaignState>
                                                                      <TemplateTwilioState>
                                                                        <VideoCallState>
                                                                          <WspCampaignState>
                                                                            <RoleState>
                                                                              <RecordingTest>
                                                                                <DpxConversationsState>
                                                                                  <GlobalStyles />
                                                                                  <ScrollReset />
                                                                                  <GoogleAnalytics />
                                                                                  {renderRoutes(
                                                                                    routes
                                                                                  )}
                                                                                </DpxConversationsState>
                                                                              </RecordingTest>
                                                                            </RoleState>
                                                                          </WspCampaignState>
                                                                        </VideoCallState>
                                                                      </TemplateTwilioState>
                                                                    </AdCampaignState>
                                                                  </SubstatusState>
                                                                </VisitState>
                                                              </TicketState>
                                                            </ChartState>
                                                          </RecordingState>
                                                        </ClosureInformationState>
                                                      </TemperatureState>
                                                    </MailState>
                                                  </StatusState>
                                                </SourceState>
                                              </VehicleState>
                                            </StoreState>
                                          </MakeState>
                                        </AppointmentState>
                                      </CommentBusinessState>
                                    </CommentState>
                                  </LeadBusinessState>
                                </LeadState>
                              </UserState>
                            </AuthState>
                          </AlertState>
                        </SettingsState>
                      </ReminderState>
                    </MediaState>
                  </QuotationState>
                </OrderState>
              </NotificationState>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
