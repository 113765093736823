import React from 'react';
import { useTheme } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import Exporting from 'highcharts/modules/exporting'

// Exporting(Highcharts)

const PieHChart = ({ series, title, customFormat='{point.name}: <b>{point.percentage:.2f}%</b>',customPointFormat='{series.name}: <b>{point.percentage:.2f}%</b>' }) => {
    const theme = useTheme();

    let options = {
        legend: {
            enabled: true,
          },
        // exporting: {
        //     buttons: {
        //       contextButton: {
        //         menuItems: ["viewFullscreen", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadCSV", "downloadXLS"]
        //       }
        //     }
        //   },
        title: {
            text: title,
            style: {
                color: theme.palette.text.primary,
                fontSize: '24px'
            }
        },
        chart: {
            height: 700,
            type: 'pie',
            backgroundColor: theme.palette.background.paper,
            style: {
                color: theme.palette.divider
            }
        },
        plotOptions: {
            
            pie: {
                borderWidth: 0,
                // allowPointSelect: true,
                cursor: 'pointer',
                // colors: colores,
                dataLabels: {
                    color: theme.palette.text.primary,
                    style: {
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textShadow: 'none',
                    },
                    enabled: true,
                    format: customFormat
                },
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                },
                
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            pointFormat: `<tr><td style="color: {series.color}">${customPointFormat}</td></tr>`,
            footerFormat: '</table>',
            valueDecimals: 2,
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                textShadow: 'none',
            },
        },
        // tooltip: {
        //     pointFormat: customFormat,
        //     style: {
        //       fontSize: '18px',
        //     }
        //   },
        accessibility: {
            // point: {
            //     valueSuffix: '%'
            // }
        },
        series: series
        
    }
  return (
    <HighchartsReact style={{height: '100%' }} highcharts={Highcharts} options={options} /> 
  );
};

export default PieHChart;
