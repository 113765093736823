import React, { useRef, useState, useEffect } from 'react';
import { socket } from 'service/socket';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Mic, MicOff } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useStore from 'hooks/useStore';
import useAuth from 'hooks/useAuth';
import {
  isAdmin,
  isAdminBDC,
  isAdminService,
  isGeneralManager,
  isRockstar,
  isSalesManager,
  isSuper
} from 'utils/AuthRoles';
import { CapitalizeNames } from 'utils/capitalize';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  popover: {
    maxWidth: '30ch'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText
  }
}));

const Notifications = ({ ...rest }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { getAllStores, allStores, updateAllStores, updateStore } = useStore();
  const { user, updateStoreAuth } = useAuth();
  const theme = useTheme();

  const activeMic = {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.secondary.contrastText,
    width: 30,
    height: 30
  };

  const inactiveMic = {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.contrastText,
    width: 30,
    height: 30
  };

  useEffect(() => {
    if (socket && allStores && allStores.length > 0) {
      socket.on('updateRecordings', data => {
        if (user && user.role && isRockstar(user.role._id)) {
          allStores.map(item => {
            if (item._id === data._id) {
              updateAllStores({
                ...item,
                isRecording: data.isRecording === 'true' ? true : false
              });
            }
            return false;
          });
        }
      });
    }

    //eslint-disable-next-line
  }, [allStores]);

  useEffect(() => {
    if (user && user.role && isRockstar(user.role._id)) {
      getAllStores({});
    }

    if (
      socket &&
      user &&
      user.role &&
      user.stores &&
      (isAdmin(user.role._id) ||
        isAdminBDC(user.role._id) ||
        isAdminService(user.role._id) ||
        isGeneralManager(user.role._id) ||
        isSalesManager(user.role._id))
    ) {
      socket.on('updateRecordings', data => {
        user.stores.map(item => {
          if (item._id === data._id) {
            updateStoreAuth(
              {
                ...item,
                isRecording: data.isRecording === 'true' ? true : false
              },
              'stores'
            );
          }
          return false;
        });
      });
    }

    if (
      socket &&
      user &&
      user.role &&
      user.group &&
      user.group.stores &&
      isSuper(user.role._id)
    ) {
      socket.on('updateRecordings', data => {
        user.group.stores.map(item => {
          if (item._id === data._id) {
            updateStoreAuth(
              {
                ...item,
                isRecording: data.isRecording === 'true' ? true : false
              },
              'group'
            );
          }
          return false;
        });
      });
    }
    //eslint-disable-next-line
  }, [user]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleUpdateStore = (values, id) => {
    let hour = moment().format('H');
    if (hour >= 9 && hour < 19) {
      updateStore(values, id);
    }
  };

  return (
    <div {...rest}>
      <Tooltip title="Recordings">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <Mic />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            {t('Tabs.Recordings')}
          </Typography>
        </Box>
        <List disablePadding>
          {user &&
            user.role &&
            isRockstar(user.role._id) &&
            allStores &&
            allStores.map((store, key) => (
              <ListItem
                divider
                key={key}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleUpdateStore(
                    { isRecording: !store.isRecording },
                    store._id
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar
                    style={store.isRecording === true ? activeMic : inactiveMic}
                  >
                    <SvgIcon fontSize="small" style={{ height: 20, width: 20 }}>
                      {store.isRecording === true ? <Mic /> : <MicOff />}
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="caption">
                      {CapitalizeNames(store.name)}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          {user &&
            user.role &&
            (isAdmin(user.role._id) ||
              isAdminBDC(user.role._id) ||
              isAdminService(user.role._id) ||
              isGeneralManager(user.role._id) ||
              isSalesManager(user.role._id)) &&
            user.stores &&
            user.stores.map(store => (
              <ListItem
                divider
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleUpdateStore(
                    { isRecording: !store.isRecording },
                    store._id
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar
                    style={store.isRecording === true ? activeMic : inactiveMic}
                  >
                    <SvgIcon fontSize="small" style={{ height: 20, width: 20 }}>
                      {store.isRecording === true ? <Mic /> : <MicOff />}
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="caption">
                      {CapitalizeNames(store.name)}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          {user &&
            user.role &&
            isSuper(user.role._id) &&
            user.group &&
            user.group.stores &&
            user.group.stores.map(store => (
              <ListItem
                divider
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleUpdateStore(
                    { isRecording: !store.isRecording },
                    store._id
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar
                    style={store.isRecording === true ? activeMic : inactiveMic}
                  >
                    <SvgIcon fontSize="small" style={{ height: 20, width: 20 }}>
                      {store.isRecording === true ? <Mic /> : <MicOff />}
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="caption">
                      {CapitalizeNames(store.name)}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
};

export default Notifications;
