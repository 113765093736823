import React, { useReducer } from 'react';
import WspCampaignContext from './wspCampaignContext';
import WspCampaignReducer from './wspCampaignReducer';
import api from '../../api/api';
import { 
  GET_WSPCAMPAIGNS, 
  CREATE_WSPCAMPAIGN, 
  GET_WSPCAMPAIGN, 
  DELETE_WSPCAMPAIGN, 
  UPDATE_WSPCAMPAIGN, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_FILTER_RESULTS,
 } from '../types';

const WspCampaignState = props => {
  const initialState = {
    wspCampaigns: [],
    wspCampaign: {},
    loading: false,
    error: null,
    count: null,
    filterLeadsResults: 0

  };

  const [state, dispatch] = useReducer(WspCampaignReducer, initialState);

  //Get WspCampaigns By Store
  const getWspCampaignsByStore = async (stores) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/wspCampaigns/admin?${stores}`, config);
      dispatch({ type: GET_WSPCAMPAIGNS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  const AdvancedResults = async (pagination, query) => {
    setLoading();
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    try {
      const res = await api.get(
        `/wspCampaigns/wspCampaignsAr?page=${pagination.page}&limit=${pagination.limit}${query}&searchType=and&validation=1`, config
      );
      dispatch({
        type: GET_WSPCAMPAIGNS,
        payload: res.data.data,
        count: res.data.count
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get WspCampaigns
  const getWspCampaigns = async (pagination, query, typeQuery, queryExtra) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
   
    if(!queryExtra) queryExtra = ''
    try {
      const res = await api.get(`/wspCampaigns/wspCampaignsAr?page=${pagination.page}&searchIndex=make-store-vehicle&limit=${pagination.limit}&searchText=${query}&searchType=${typeQuery}&validation=1${queryExtra}`, config);
      dispatch({ 
        type: GET_WSPCAMPAIGNS, 
        payload: res.data.data,
        count: res.data.pagination.total
        });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };
    
  //Get WspCampaign
  const getWspCampaign = async (wspCampaignId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/wspCampaigns/${wspCampaignId}`, config);
      dispatch({ type: GET_WSPCAMPAIGN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})
    }
  };

  //Create WspCampaign
  const createWspCampaign = async (wspCampaign, query) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/wspCampaigns?${query}`, { ...wspCampaign}, config);
      dispatch({ type: CREATE_WSPCAMPAIGN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  }

  //Delete WspCampaign
  const deleteWspCampaign = async (wspCampaignId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      
      const res = await api.delete(`/wspCampaigns/${wspCampaignId}`, config);
      dispatch({ type: DELETE_WSPCAMPAIGN, payload: res.data.deletedId })
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  //Update WspCampaign
  const updateWspCampaign = async (wspCampaign, wspCampaignId) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
    
      const res = await api.put(`/wspCampaigns/${wspCampaignId}`, { ...wspCampaign, }, config);
      dispatch({ type: UPDATE_WSPCAMPAIGN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data})

    }
  };

  const filterLeads = async (query) => {
    const config =  {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    setLoading();
    try {
      
      const res = await api.get(`/wspCampaigns/filter?${query}`, config);
      dispatch({
        type: GET_FILTER_RESULTS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <WspCampaignContext.Provider
      value={{
        loading: state.loading,
        wspCampaigns: state.wspCampaigns,
        wspCampaign: state.wspCampaign,
        error: state.error,
        count: state.count,
        filterLeadsResults: state.filterLeadsResults,
        getWspCampaign,
        createWspCampaign,
        deleteWspCampaign,
        updateWspCampaign,
        AdvancedResults,
        getWspCampaignsByStore,
        clearState,
        setLoading,
        getWspCampaigns,
        filterLeads,

      }}
    >
      {props.children}
    </WspCampaignContext.Provider>
  );
};

export default WspCampaignState;
