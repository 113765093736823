import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import AlertP from 'components/Alert';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Eye, EyeOff } from 'react-feather';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';

const useStyles = makeStyles(() => ({
  root: {}
}));

const JWTLogin = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { login, error, clearState } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [show, setShow] = useState('password');

  const handleClickShowPassword = () => {
    if (show === 'password') {
      setShow('text');
    } else {
      setShow('password');
    }
  };

  useEffect(() => {
    clearState();
    //eslint-disable-next-line
  }, []);

  if (localStorage.getItem('token')) {
    return <Redirect to="/reports/dashboard" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('Yup.Email'))
          .max(255)
          .required(t('Yup.EmailReq')),
        password: Yup.string()
          .max(255)
          .required('Password is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          login(values);

          if (isMountedRef.current) {
            if (!error) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.error });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t('Forms.Email')}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            margin="normal"
            label={t('Forms.Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={show}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {show === 'password' ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {error && <AlertP severity="error" msg={error.error} />}

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('Login.Login')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

JWTLogin.propTypes = {
  className: PropTypes.string
};

export default JWTLogin;
