import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import VideoCallLayout from 'layouts/VideoCallLayout';
import HomeView from 'views/home/HomeView';
import LoadingScreen from 'components/LoadingScreen';
import GuestGuard from 'components/GuestGuard';
import DPXWrapper from 'components/DPXWrapper';
import RolesGuard from 'components/RolesGuard';

///Imports
import DashboardMain from 'views/reports/DashboardView';
import ErrorPage from 'views/errors/NotFoundView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => {
              if (Guard !== Fragment) {
                return (
                  <Guard roles={route.roles}>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        //Here we wrapp our component in DPX Wrapper
                        //This component have Get functions we need to use
                        <DPXWrapper {...props}>
                          <Component />
                        </DPXWrapper>
                      )}
                    </Layout>
                  </Guard>
                );
              } else {
                return (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        //Here we wrapp our component in DPX Wrapper
                        //This component have Get functions we need to use
                        <DPXWrapper {...props}>
                          <Component />
                        </DPXWrapper>
                      )}
                    </Layout>
                  </Guard>
                );
              }
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/',
    component: HomeView
  },
  {
    exact: true,
    layout: VideoCallLayout,
    path: '/videocall/:type/:room',
    component: lazy(() => import('views/videocalls'))
  },
  {
    exact: true,
    path: '/404',
    component: ErrorPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('views/auth/LoginView'))
  },
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/register',
  //   component: lazy(() => import('views/auth/RegisterView'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgotPassword',
    component: lazy(() => import('views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/resetPassword/:token',
    component: lazy(() => import('views/auth/ResetPassword'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('views/auth/RegisterView'))
  },
  {
    path: '/',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'user',
          'general manager',
          'sales manager'
        ],
        path: '/management/leads',
        component: lazy(() => import('views/lead/LeadListView'))
      },
      {
        exact: true,
        path: '/management/quotations',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/quotation/QuotationListView'))
      },
      {
        exact: true,
        path: '/management/leadBusiness',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/leadBusiness/LeadListView'))
      },
      {
        exact: true,
        path: '/create/leadBusiness',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/leadBusiness/LeadCreateView'))
      },
      {
        exact: true,
        path: '/management/leadBusiness/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/leadBusiness/LeadDetailsView'))
      },
      {
        exact: true,
        path: '/management/leadBusiness/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/leadBusiness/LeadBusinessEditView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        path: '/management/tickets',
        component: lazy(() => import('views/ticket/TicketListView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'admin'],
        path: '/management/closures',
        component: lazy(() => import('views/closures/ClosureListView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        path: '/reports/closures',
        component: lazy(() => import('views/chartLeads/reportSales'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        path: '/management/notifications',
        component: lazy(() => import('views/notification/NotificationListView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        path: '/management/adCampaigns/:id',
        component: lazy(() => import('views/adCampaign/AdCampaignDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'marketing'],
        path: '/management/wspCampaigns/:id',
        component: lazy(() =>
          import('views/wspCampaign/WspCampaignDetailsView')
        )
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        path: '/management/closures/:id',
        component: lazy(() => import('views/closures/ClosureDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/roles/:id',
        component: lazy(() => import('views/role/RoleDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        path: '/management/tickets/:id',
        component: lazy(() => import('views/ticket/TicketDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'user',
          'general manager',
          'sales manager'
        ],
        path: '/management/leads/:id',
        component: lazy(() => import('views/lead/LeadDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        path: '/management/adCampaigns/:id/edit',
        component: lazy(() => import('views/adCampaign/AdCampaignEditView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        path: '/management/leads/:id/edit',
        component: lazy(() => import('views/lead/LeadEditView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/roles/:id/edit',
        component: lazy(() => import('views/role/RoleEditView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/template-wsp/:id/edit',
        component: lazy(() =>
          import('views/templateTwilio/TemplateTwilioEditView')
        )
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'admin'],
        path: '/management/closures/:id/edit',
        component: lazy(() => import('views/closures/ClosureEditView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'general manager',
          'sales manager'
        ],
        path: '/management/recordings',
        component: lazy(() => import('views/recording/RecordingListView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/management/templates-wsp',
        component: lazy(() =>
          import('views/templateTwilio/TemplateTwilioListView')
        )
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'general manager',
          'sales manager'
        ],
        path: '/management/recordings/:id',
        component: lazy(() => import('views/recording/RecordingDetailsView'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        path: '/management/templates-wsp/:id',
        component: lazy(() =>
          import('views/templateTwilio/TemplateTwilioDetailsView')
        )
      },
      {
        exact: true,
        path: '/account',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'carone',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager',
          'super admin'
        ],
        component: lazy(() => import('views/account/AccountView'))
      },
      {
        exact: true,
        path: '/calendar',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/tasks',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/tasks/TasksView'))
      },
      {
        exact: true,
        path: '/chat',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        component: () => <Redirect to="/chat/new" />
      },
      {
        exact: true,
        path: '/management/wspCampaigns',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'marketing'],
        component: lazy(() => import('views/wspCampaign/WspCampaignListView'))
      },

      {
        exact: true,
        path: '/management/configs',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/zapier/zapier'))
      },
      {
        exact: true,
        path: '/custom-menu',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/CustomizeMenu'))
      },
      // {
      //   exact: true,
      //   path: '/extra/charts/linear',
      //   guard: RolesGuard,
      //  roles: ["rockstar", "admin", "super admin"],
      //   component: lazy(() => import('views/chartLeads/linear'))
      // },
      {
        exact: true,
        path: '/extra/highcharts/monthly',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/monthlyHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/score',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/scoreHighchart'))
      },
      {
        exact: true,
        path: '/reports/pending-tasks',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/taskStoreChart'))
      },
      // deprecated
      // {
      //   exact: true,
      //   path: '/extra/highcharts/comparative',
      //   guard: RolesGuard,
      //   roles: ["rockstar", "admin", "super admin", "marketing" , "digital marketing", "general manager", "sales manager"],
      //   component: lazy(() => import('views/chartLeads/statusComparative'))
      // },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'user',
          'general manager',
          'sales manager'
        ],
        path: '/extra/highcharts/modelsTable',
        component: lazy(() => import('views/chartLeads/ModelsProspects'))
      },
      {
        exact: true,
        path: '/extra/highcharts/unit-comparative',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/unitComparative'))
      },
      {
        exact: true,
        path: '/extra/highcharts/unit-comparative-extra',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/unitComparative2'))
      },
      {
        exact: true,
        path: '/extra/highcharts/time',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'admin',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/ByTime'))
      },
      {
        exact: true,
        path: '/conversations',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'admin',
          'rh',
          'user'
        ],
        component: lazy(() => import('views/dpxChat'))
      },
      {
        exact: true,
        path: '/extra/highcharts/conversations',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/conversationsHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/messages',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/messagesHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/agentsStatus',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/agentsStatus'))
      },
      {
        exact: true,
        path: '/extra/highcharts/temperatures',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/temperaturesChart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/funnel',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/temperaturesChartRSI'))
      },
      {
        exact: true,
        path: '/extra/highcharts/temperaturesComparative',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'admin',
          'rh'
        ],
        component: lazy(() =>
          import('views/chartLeads/temperaturesComparative')
        )
      },
      {
        exact: true,
        path: '/extra/highcharts/vs',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/vsBarHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/daily',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/dailyHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/hours',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/hoursHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/agentsLeads',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/chartLeads/AgentsLeads'))
      },
      {
        exact: true,
        path: '/extra/highcharts/agentsCalls',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/AgentsCalls'))
      },
      {
        exact: true,
        path: '/extra/highcharts/stores-extra-scores',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/chartLeads/storesExtraScores'))
      },
      {
        exact: true,
        path: '/extra/highcharts/stores-scores',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/storesScores'))
      },
      {
        exact: true,
        path: '/reports/visits-leads',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/VisitsReport'))
      },
      {
        exact: true,
        path: '/reviews/recordings',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'user',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/AgentsRecordings'))
      },
      {
        exact: true,
        path: '/extra/highcharts/visits',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/visitsHighchart'))
      },
      {
        exact: true,
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'user',
          'general manager',
          'sales manager',
          'marketing'
        ],
        path: '/extra/highcharts/agentsTable',
        canMarkAsFavorite: true,
        component: lazy(() => import('views/chartLeads/AgentsComparative'))
      },
      {
        exact: true,
        path: '/extra/highcharts/recordings-reviews',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'admin',
          'general manager',
          'sales manager',
          'rh',
          'marketing'
        ],
        component: lazy(() => import('views/chartLeads/recordingsReviews'))
      },
      {
        exact: true,
        path: '/extra/highcharts/appointments',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/appointmentsHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/hours-comparative',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/chartLeads/hoursComparative'))
      },
      {
        exact: true,
        path: '/extra/highcharts/status-report',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh',
          'user'
        ],
        component: lazy(() => import('views/chartLeads/pyramidHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/totalTemperatures',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/totalTemperatures'))
      },
      {
        exact: true,
        path: '/extra/highcharts/monitoringAgents',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/monitoringAgents'))
      },
      {
        exact: true,
        path: '/extra/highcharts/all',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/allHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/captacion-medios',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() =>
          import('views/chartLeads/captacionMediosHighchart')
        )
      },
      {
        exact: true,
        path: '/extra/highcharts/investments',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh',
          'marketing'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownInvestmentAll'))
      },
      {
        exact: true,
        path: '/extra/highcharts/models',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/modelsHighchart'))
      },
      {
        exact: true,
        path: '/extra/highcharts/multiple',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing'
        ],
        component: lazy(() => import('views/chartLeads/multiBarHighchart'))
      },
      {
        exact: true,
        path: '/management/storesRecordings',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() =>
          import('views/storeRecording/RecordingsDetailsView')
        )
      },
      {
        exact: true,
        path: '/management/recording-tests',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/recordingTest/RecordingListView'))
      },
      {
        exact: true,
        path: '/management/loadLeads',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/uploadCsv/DetailsView'))
      },
      {
        exact: true,
        path: '/management/temperatures',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/temperature/TemperatureListView'))
      },
      {
        exact: true,
        path: '/management/makes',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/make/MakeListView'))
      },
      {
        exact: true,
        path: '/management/stores',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/store/StoreListView'))
      },
      {
        exact: true,
        path: '/management/roles',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/role/RoleListView'))
      },
      {
        exact: true,
        path: '/management/tasks',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin', 'general manager'],
        component: lazy(() => import('views/tasks/TaskListview'))
      },
      {
        exact: true,
        path: '/management/vehicles',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'digital marketing'],
        component: lazy(() => import('views/vehicle/VehicleListView'))
      },
      {
        exact: true,
        path: '/management/status',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/status/StatusListView'))
      },
      {
        exact: true,
        path: '/management/adCampaigns',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing'
        ],
        component: lazy(() => import('views/adCampaign/AdCampaignListView'))
      },
      {
        exact: true,
        path: '/management/sources',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/source/SourceListView'))
      },
      {
        exact: true,
        path: '/management/users',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/user/UserListView'))
      },
      {
        exact: true,
        path: '/reports/matrix',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'marketing',
          'digital marketing',
          'admin'
        ],
        component: lazy(() => import('views/chartLeads/matrix'))
      },
      {
        exact: true,
        path: '/reports/matrixInvestment',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'marketing',
          'digital marketing',
          'admin'
        ],
        component: lazy(() => import('views/chartLeads/matrixInvestment'))
      },
      {
        exact: true,
        path: '/reports/matrixSegmentation',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'marketing',
          'digital marketing',
          'admin'
        ],
        component: lazy(() => import('views/chartLeads/matrixSegmentation'))
      },
      {
        exact: true,
        path: '/reports/matrixStores',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'marketing',
          'digital marketing',
          'admin'
        ],
        component: lazy(() => import('views/chartLeads/matrixInvestmentStores'))
      },
      {
        exact: true,
        path: '/management/temperatures/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() =>
          import('views/temperature/TemperatureDetailsView')
        )
      },
      {
        exact: true,
        path: '/management/substatus/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/substatus/SubstatusDetailsView'))
      },
      {
        exact: true,
        path: '/management/stores/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/store/StoreDetailsView'))
      },
      {
        exact: true,
        path: '/management/makes/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/make/MakeDetailsView'))
      },
      {
        exact: true,
        path: '/management/recording-tests/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() =>
          import('views/recordingTest/RecordingDetailsView')
        )
      },
      {
        exact: true,
        path: '/management/sources/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/source/SourceDetailsView'))
      },
      {
        exact: true,
        path: '/management/vehicles/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'digital marketing'],
        component: lazy(() => import('views/vehicle/VehicleDetailsView'))
      },
      {
        exact: true,
        path: '/management/status/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/status/StatusDetailsView'))
      },
      {
        exact: true,
        path: '/reports/score-report',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/chartLeads/ScoreReport'))
      },
      {
        exact: true,
        path: '/management/recording-tests/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/recordingTest/RecordingEditView'))
      },
      {
        exact: true,
        path: '/management/users/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/user/UserDetailsView'))
      },
      {
        exact: true,
        path: '/management/substatus/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/substatus/SubstatusEditView'))
      },
      {
        exact: true,
        path: '/management/temperatures/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/temperature/TemperatureEditView'))
      },
      {
        exact: true,
        path: '/management/makes/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/make/MakeEditView'))
      },
      {
        exact: true,
        path: '/management/sources/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/source/SourceEditView'))
      },
      {
        exact: true,
        path: '/management/vehicles/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/vehicle/VehicleEditView'))
      },
      {
        exact: true,
        path: '/management/stores/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/store/StoreEditView'))
      },
      {
        exact: true,
        path: '/management/status/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/status/StatusEditView'))
      },
      {
        exact: true,
        path: '/management/users/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/user/UserEditView'))
      },
      {
        exact: true,
        path: '/tickets',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/ticket/TicketCreateView'))
      },
      {
        exact: true,
        path: '/create/make',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/make/MakeCreateView'))
      },
      {
        exact: true,
        path: '/create/recording-test',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/recordingTest/RecordingCreateView'))
      },
      {
        exact: true,
        path: '/create/wspCampaign',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'marketing'],
        component: lazy(() => import('views/wspCampaign/WspCampaignCreateView'))
      },
      {
        exact: true,
        path: '/create/adCampaign',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/adCampaign/AdCampaignCreateView'))
      },
      {
        exact: true,
        path: '/create/role',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/role/RoleCreateView'))
      },
      {
        exact: true,
        path: '/create/template-wsp',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() =>
          import('views/templateTwilio/TemplateTwilioCreateView')
        )
      },
      {
        exact: true,
        path: '/create/:id/closure',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        component: lazy(() => import('views/closures/ClosureCreateView'))
      },

      {
        exact: true,
        path: '/create/:id/quotation',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        component: lazy(() => import('views/quotations/QuotationCreateView'))
      },
      {
        exact: true,
        path: '/management/quotation/:id',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user'
        ],
        component: lazy(() => import('views/quotations/QuotationDetailView'))
      },
      {
        exact: true,
        path: '/create/status/:id/substatus',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/substatus/SubstatusCreateView'))
      },
      {
        exact: true,
        path: '/create/temperature',
        guard: RolesGuard,
        roles: ['rockstar', 'admin', 'super admin'],
        component: lazy(() => import('views/temperature/TemperatureCreateView'))
      },
      {
        exact: true,
        path: '/create/store',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/store/StoreCreateView'))
      },
      {
        exact: true,
        path: '/create/source',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/source/SourceCreateView'))
      },
      {
        exact: true,
        path: '/create/lead',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general'
        ],
        component: lazy(() => import('views/lead/LeadCreateView'))
      },
      {
        exact: true,
        path: '/create/vehicle',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/vehicle/VehicleCreateView'))
      },
      {
        exact: true,
        path: '/create/status',
        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('views/status/StatusCreateView'))
      },
      {
        exact: true,
        path: '/create/user',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/user/UserCreateView'))
      },
      {
        exact: true,
        path: '/management',
        component: () => <Redirect to="/management/leads" />
      },
      {
        exact: true,
        path: '/reports/dashboard',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh',
          'carone'
        ],
        component: DashboardMain
      },
      {
        exact: true,
        path: '/reports/appointments',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/reports/AppointmentsView'))
      },
      {
        exact: true,
        path: '/reports/investment',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/reports/InvestmentView'))
      },
      {
        exact: true,
        path: '/reports/visits',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'user',
          'general manager',
          'sales manager'
        ],
        component: lazy(() => import('views/reports/VisitsView'))
      },
      {
        exact: true,
        path: '/leadFinder',
        guard: RolesGuard,
        roles: ['rockstar', 'digital marketing'],
        component: lazy(() => import('views/lead/MktLeadDetailsView'))
      },
      {
        exact: true,
        path: '/reports/sales',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'admin'
        ],
        component: lazy(() => import('views/reports/FinancingView'))
      },
      {
        exact: true,
        path: '/drilldown/calls',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownCalls'))
      },
      {
        exact: true,
        path: '/drilldown/recordings',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownRecordings'))
      },
      {
        exact: true,
        path: '/drilldown/leads',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin'],
        component: lazy(() => import('views/chartLeads/DrilldownLeads'))
      },
      {
        exact: true,
        path: '/drilldown/models',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownModels'))
      },
      {
        exact: true,
        path: '/drilldown/visits',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownVisits'))
      },
      {
        exact: true,
        path: '/drilldown/score',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownBureau'))
      },
      {
        exact: true,
        path: '/drilldown/conversations',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownConversations'))
      },
      {
        exact: true,
        path: '/drilldown/appointments',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'admin',
          'super admin',
          'marketing',
          'digital marketing',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownAppointments'))
      },
      {
        exact: true,
        path: '/drilldown/sources',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownSources'))
      },

      {
        exact: true,
        path: '/drilldown/modelsource',
        guard: RolesGuard,
        roles: [
          'rockstar',
          'super admin',
          'general manager',
          'sales manager',
          'rh'
        ],
        component: lazy(() => import('views/chartLeads/DrilldownModelSource'))
      },
      {
        exact: true,
        path: '/reports',
        component: () => <Redirect to="/reports/dashboard" />
      },
      {
        path: '/extra/highcharts/bureau',
        exact: true,
        component: () => <Redirect to="/extra/highcharts/score" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/loadDocumentation',
    routes: [
      {
        exact: true,
        path: '/loadDocumentation/:id',
        component: lazy(() =>
          import('views/loadDocumentation/LoadDocumentationView')
        )
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
