import { GET_CLOSUREINFORMATIONS, CREATE_CLOSUREINFORMATION, GET_CLOSUREINFORMATION, DELETE_CLOSUREINFORMATION, UPDATE_CLOSUREINFORMATION, SET_ERROR, CLEAR_STATE, SET_LOADING } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CLOSUREINFORMATIONS:
      return {
        ...state,
        closureInformations: action.payload,
        loading: false,
        error: null,
        count: action.count,

      };
    case GET_CLOSUREINFORMATION:
      return {
        ...state,
        closureInformation: action.payload,
        loading: false,
        error: null,

      };
    case CREATE_CLOSUREINFORMATION:
      return {
        ...state,
        closureInformation: action.payload,
        loading: false,
        error: null
      };
    case DELETE_CLOSUREINFORMATION:
      state.closureInformations = state.closureInformations.filter( closureInformation => closureInformation._id.toString() !== action.payload.toString())
      return {
        ...state,
        closureInformation: null,
        loading: false,
        error: null
      };
    case UPDATE_CLOSUREINFORMATION:
        return {
          ...state,
          closureInformation: action.payload,
          loading: false,
          error: null
        };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case CLEAR_STATE:
      return {
        closureInformation: {},
        closureInformations: [],
        loading: false,
        error: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
};
