import React from 'react';
import { Typography } from '@material-ui/core';

const Logo = props => {
  const data = JSON.parse(localStorage.getItem('settings'));

  return data &&
    data.theme &&
    [
      'CATS',
      'LIGHT',
      'LIGHT_PINK',
      'NAVIDAD',
      'NAVIDAD 2023',
      'GRINCH',
      'NEW_YEAR',
      'SAN_VALENTIN'
    ].includes(data.theme) ? (
    <Typography variant="h6" color="textPrimary">
      <img src="/static/Logo.png" style={{ width: 120 }} alt="logoMin" />
    </Typography>
  ) : (
    <Typography variant="h6" color="textPrimary">
      <img src="/static/Logo_white.png" style={{ width: 120 }} alt="logoMin" />
    </Typography>
  );
};

export default Logo;
